import React, { FC, useContext } from "react";

import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import googleimg from "../../../Assets/image/googleLogo.jpg";
import notificationIcon from "../../../Assets/image/notificationIcon.png";
import circle from "../../../Assets/image/circle.svg";
import {
  readNotificationStartup,
  readNotificationInvestor,
} from "../../../Api/api";
import {
  getInvestorNotification,
  getStartupNotification,
} from "../../../store/startup/actions";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import "./NotificationList.css";
import { NotificationContext } from "../../MainLayout/index";
import { toast } from "react-hot-toast";

interface TopInvestorsListprops {
  username?: string;
  userid?: string | number;
  // userimg?: string;
  number?: string | number;
  // circleimg?: string;
}
interface TopInvestorsListprops {
  TopInvestorsListprops: any;
}

const NotificationList: FC<TopInvestorsListprops> = ({
  TopInvestorsListprops,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isNotification, setNotification } = useContext(NotificationContext);
  const connectionArray = useSelector((state: any) => {
    return state.startupReducer?.connectionData;
  });


  const connectionSet = new Set();
  const uniqueConnections = connectionArray.filter((data: { uId: any }) => {
    if (connectionSet.has(data.uId)) {
      return false;
    }
    connectionSet.add(data.uId);
    return true;
  });

  const investorData = uniqueConnections.find((element: any) => {
    return element.uId === TopInvestorsListprops.userId;
  });
  const MarkRead = (id: any) => {
    if (
      localStorage.getItem("id") &&
      localStorage.getItem("type") === "investor"
      // ||(sessionStorage.getItem("id") &&
      // sessionStorage.getItem("type") === "investor")
    ) {
      const data = readNotificationInvestor(localStorage.getItem("id"), id);
      data.then((res: any) => {
        if (res.status === "OK") {
          dispatch(getInvestorNotification(localStorage.getItem("id")));
          setTimeout(() => {
            navigate("/chat", { state: TopInvestorsListprops.userId });
          }, 90000);
          // navigate("/chat", { state: TopInvestorsListprops.userId });
          setNotification("delete");
        }
      });
    }
    if (
      localStorage.getItem("id") &&
      localStorage.getItem("type") === "startup"
      // ||(sessionStorage.getItem("id") &&
      // sessionStorage.getItem("type") === "startup")
    ) {
      const data = readNotificationStartup(localStorage.getItem("id"), id);
      data.then((res: any) => {
        if (res.status === "OK") {
          dispatch(getStartupNotification(localStorage.getItem("id")));
          navigate("/chat", { state: TopInvestorsListprops.userId });
          setNotification("delete");
        }
      });
    }
  };

  return (
    <div
      style={{ cursor: "pointer" }}
      onClick={() => {
        MarkRead(TopInvestorsListprops.uId);
      }}
    >
      <Box className="listBox">
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <img src={notificationIcon} alt="img" width={"40px"} />
          </Box>
          <Box className="midBox">
            <Typography className="userid" sx={{ fontSize: "14px" }}>
              {TopInvestorsListprops?.message}
            </Typography>
            {/* <Typography className="userid" sx={{ fontSize: "13px" }}>
              {TopInvestorsListprops.userid}
            </Typography> */}
          </Box>
        </Box>

        <Box>
          {!TopInvestorsListprops.isRead ? (
            <img src={circle} alt="img" width={"10px"} />
          ) : (
            <div style={{ color: "#184658" }}>Read</div>
          )}
          {/* <Box>
            <img className="circularimg" src={circle} />
          </Box> */}
        </Box>
      </Box>
    </div>
  );
};
export default NotificationList;
