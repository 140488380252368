export const GET_LOGIN_FETCH_STARTUP = "GET_LOGIN_FETCH_STARTUP";
export const GET_LOGIN_SUCCESS_STARTUP = "GET_LOGIN_SUCCESS_STARTUP";
export const GET_PEOPLE_SUCCESS = "GET_PEOPLE_SUCCESS";
export const GET_LOGIN_FAIL_STARTUP = "GET_LOGIN_FAIL_STARTUP";
export const LOGOUT_STARTUP = "LOGOUT_STARTUP";
export const LOGOUT_SUCCESS_STARTUP = "LOGOUT_SUCCESS_STARTUP";
export const RECENTLY_VIEWED = "RECENTLY_VIEWED";
export const TOP_RISING = "TOP_RISING";
export const GETSEARCH_S = "GETSEARCH_S";
export const GET_STARTUP_DETAILS = "GET_STARTUP_DETAILS";
export const GET_STARTUP_DETAILS_SUCCESS = "GET_STARTUP_DETAILS_SUCCESS";
export const CHANGE_PASSWORD_STARTUP = "CHANGE_PASSWORD_STARTUP";
export const FORGET_PASSWORD_STARTUP = "FORGET_PASSWORD_STARTUP";
export const GET_PEOPLE_DETAILS_SUCCESS = "GET_PEOPLE_DETAILS_SUCCESS";
export const DELETE_EMPLOYEE = "DELETE_EMPLOYEE";
export const EMP_DELETE_SUCCESS = "EMP_DELETE_SUCCESS";
export const UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE";
export const UPDATE_EMPLOYEE_SUCCESS = "UPDATE_EMPLOYEE_SUCCESS";
export const ALL_STARTUP_CONNECTION = "ALL_STARTUP_CONNECTION";
export const SEND_REQUEST_TO_INVESTOR = "SEND_REQUEST_TO_INVESTOR";
export const ACCEPT_REQUEST_OF_INVESTOR = "ACCEPT_REQUEST_OF_INVESTOR";
export const GET_INVESTOR_NOTIFICATION = "GET_INVESTOR_NOTIFICATION";
export const GET_STARTUP_NOTIFICATION = "GET_STARTUP_NOTIFICATION";


export const getLoginFetchStartup = (values: any) => ({
  type: GET_LOGIN_FETCH_STARTUP,
  payload: values,
});

export const logoutStartup = () => ({
  type: LOGOUT_STARTUP,
});

export const getStartupDetails = (id: any) => {
  return {
    type: "GET_STARTUP_DETAILS",
    payload: id,
  };
};

export const getInvestorProfile = () => {
  return {
    type: "GETINVESTOR",
  };
};
export const getProfileInv = (id: any) => {
  return {
    type: "GETPROFILEINV",
    payload: id,
  };
};

export const sendRequestToInvestor = (values: any) => {
  return {
    type: SEND_REQUEST_TO_INVESTOR,
    payload: values,
  };
};

export const acceptRequestOfInvestor = (values: any) => {
  return {
    type: ACCEPT_REQUEST_OF_INVESTOR,
    payload: values,
  };
};

export const topRisingStartupS = () => {
  return {
    type: "TOPRISINGS",
  };
};

export const saveRecentlyViewed = (data: any) => {
  return {
    type: RECENTLY_VIEWED,
    payload: data,
  };
};

export const getSearchResultS = (values: any) => {
  return {
    type: GETSEARCH_S,
    payload: values,
  };
};

export const changePasswordStartup = (data: any, id: any) => {
  return {
    type: "CHANGE_PASSWORD_STARTUP",
    payload: { data, id },
  };
};
export const forgetPasswordStartup = (data: any) => {
  return {
    type: FORGET_PASSWORD_STARTUP,
    payload: data,
  };
};

export const updateStartupProfile = (data: any, id: any) => {
  return {
    type: "UPDATE_STARTUP_PROFILE",
    payload: { data, id },
  };
};

////////////////---------------
export const addStartupEmployee = (values: any) => {
  return {
    type: "ADD_STARTUP_EMPLOYEE",
    payload: values,
    // payload: { data, id },
  };
};

// export const addStartupEmployee = () => {
//   return {
//     type: "ADD_STARTUP_EMPLOYEE",
//   };
// };

export const deleteEmployee = (id: any) => {
  return {
    type: DELETE_EMPLOYEE,
    payload: id,
  };
};
export const updateEmployee = (data: any) => {

  return {
    type: UPDATE_EMPLOYEE,
    payload: data,
  };
};

//INVESTOR Notification
export const getInvestorNotification = (id: any) => {
  return {
    type: GET_INVESTOR_NOTIFICATION,
    payload: id,
  };
};
//STARTUP Notification
export const getStartupNotification = (id: any) => {
  return {
    type: GET_STARTUP_NOTIFICATION,
    payload: id,
  };
};


