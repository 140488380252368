import { Box, Button, Grid, OutlinedInput, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import "./EditProfileCard.css";
import EditUserimg from "../../../../Assets/image/EditUserimg.png";
import addnewimg from "../../../../Assets/image/addnewimg.svg";
import editimg from "../../../../Assets/image/editimg.png";
import teamPH from "./image/teamPH.png";
import { deleteEmployee } from "../../../../store/startup/actions";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

interface EditProfileCardProps {
  userimg?: string;
  userposition?: string;
  username?: string;
  EditProfileCardProp: any;
  onHandleClick: any;
}
interface State {
  userId: any;
}

const EditProfileCard: FC<EditProfileCardProps> = ({
  EditProfileCardProp,
  onHandleClick,
}) => {
  let navigate = useNavigate();
  const startUpData: any = useSelector<any>(
    (state) => state.startupReducer.people[0]
  );

  const startupDataId: any = useSelector<any>(
    (state) => state.startupReducer?.login[0]?.userId
  );
  const [values, setValues] = useState<State>({
    userId: startupDataId.userId,
  });
  const startupDataEmpId: any = useSelector<any>(
    (state) => state.startupReducer?.people[0]
  );

  const deletePeopleToast = (toastHandler = toast) => {
    toastHandler.success("Employee Deleted Successfully!", {
      style: {
        background: "#04111d",
        color: "#fff",
      },
    });
  };
  const dispatch = useDispatch();
  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />
      <Box>
        <Box className="editprofilecard">
          <Box>
            <img width={"200px"} src={teamPH} alt="EditUserimg" />
          </Box>
          <Typography className="textBold" sx={{ fontSize: "28px" }}>
            {EditProfileCardProp.name}
          </Typography>
          <Typography sx={{ fontSize: "16px", color: "#0101014D" }}>
            {EditProfileCardProp.position}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Button
              sx={{
                color: "#138707 !important",
                border: " 1px solid #138707 !important",
                margin: "5px",
                padding: "5px 20px",
                textTransform: "none",
              }}
              onClick={() => {
                dispatch(deleteEmployee(EditProfileCardProp.userId));
                navigate("/startup-dashboard");
                deletePeopleToast();
              }}
            >
              Delete
            </Button>

            <Button
              onClick={() => onHandleClick(EditProfileCardProp)}
              sx={{
                backgroundColor: "#138707 !important",
                color: "#ffffff !important",
                margin: "5px",
                padding: "5px 30px",
                textTransform: "none",
              }}
            >
              Edit
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  );
};
export default EditProfileCard;
