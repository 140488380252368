import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import uploadBgImg from "../../../../Assets/image/uploadBgImg.svg";
export default function UploadFileComponent(props: any) {
  const [selectedImage, setSelectedImage] = useState();

  const imageChange = (e: any) => {
    setSelectedImage(e.target.files[0]);
  };
  props.receiveData(selectedImage);

  return (
    <div>
      <Grid className="upload_Grid" component="label">
        <input accept="*" type="file" onChange={imageChange} hidden />
        {selectedImage ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img
              src={URL.createObjectURL(selectedImage)}
              alt="Thumb"
              style={{ maxHeight: "29rem" }}
            />
          </Box>
        ) : (
          <Box sx={{ m: 3 }}>
            <Typography
              sx={{
                fontSize: { sm: "18px", xs: "16px" },
                fontWeight: "600",
                mb: 2,
              }}
            >
              Upload Pitch File
            </Typography>
            <img src={uploadBgImg} style={{ maxWidth: "100%" }} alt="pdf/ppt" />
          </Box>
        )}
        {/* <Box sx={{ display: "flex", justifyContent: "center" }}> */}
        {/* <Box>
            <Typography
              sx={{
                fontSize: { sm: "18px", xs: "16px" },
                fontWeight: "600",
                mb: 2,
              }}
            >
              Upload Pitch File
            </Typography>
            <img src={uploadBgImg} style={{ maxWidth: "100%" }} />
          </Box> */}
        {/* </Box> */}
      </Grid>
    </div>
  );
}
