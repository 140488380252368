import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./ProfileCard.css";
import {
  getProfileInv,
  getInvestorProfile,
  saveRecentlyViewed,
} from "../../../store/startup/actions";
import { getInvProfile } from "../../../Api/api";
import { Link, Navigate, useNavigate } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ApartmentIcon from "@mui/icons-material/Apartment";

// interface props {
//   cardData: any;
//   element?: any;
// }
const ProfileCard = (props: any, { senderId }: any) => {
  const isLoggedIn = useSelector<any>(
    (state) => state.startupReducer.isLoggedIn
  );

  let navigate = useNavigate();

  let { element, cardData } = props;

  const dispatch = useDispatch();

  const handelRoute = (e: any) => {
    e.preventDefault();
    dispatch(getProfileInv(element));
    dispatch(saveRecentlyViewed(element));
    navigate("/user-profile", { state: false });
  };

  const handelRouteButton = (e: any) => {
    e.preventDefault();
    dispatch(getProfileInv(element));
    dispatch(saveRecentlyViewed(element));
    navigate("/user-profile", { state: true });
  };

  const [connectText, setConnectText] = React.useState("Connect");
  const startupConnectionArray: any = useSelector<any>(
    (state) => state.startupReducer?.login[0]?.connections
  );
  useEffect(() => {
    if (startupConnectionArray?.includes(element?.uId)) {
      setConnectText("Connected");
    }
  }, [element]);

  return (
    <Grid
      sx={{
        display: "flex",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <Box
        sx={{
          border: { sm: "1.5px solid #cccccc", xs: "none" },
          p: { md: 3, sm: 5, xs: 3 },
          width: { md: "95%" },
          position: "relative",
        }}
      >
        <div onClick={handelRoute} style={{ cursor: "pointer" }}>
          {element.subscription && (
            <Typography
              className="memberShipStartup"
              sx={{
                position: "absolute",
                // backgroundColor: "#101010",
                backgroundColor: element?.subscription === "premium" ? "#FFD700" : "#101010",
                color: "#FFF",
                borderRadius: "30px 0px 0px 30px",
                width: "fit-content",
              }}
            >
              {element.subscription[0].toUpperCase() +
                element.subscription.substring(1)}
            </Typography>
          )}
          <Grid
            container
            sx={{
              position: "relative",
              // display: "flex",
              // justifyContent: "center",
            }}
          >
            <Grid item xs={4}>
              <Avatar
                src={element?.profilePicture ? element?.profilePicture : ""}
                className="profileImg"
              />
            </Grid>
            <Grid item xs={8}>
              <Typography
                sx={{
                  ml: 1,
                  fontSize: "28px",
                  fontWeight: "600",
                  // textAlign: "center",
                  color: "#010101",
                  mt: 2,
                  textTransform: "capitalize",
                }}
              >
                {element.name.toLowerCase()}
              </Typography>
              <Typography sx={{ ml: 1, color: "#010101" }}>
                {element?.occupation ? (
                  element?.occupation.toUpperCase()
                ) : (
                  <span style={{ color: "#ccc" }}>OCCUPATION</span>
                )}
              </Typography>
            </Grid>
            <Box sx={{ mt: 2, width: "100%" }}>
              <Typography sx={{ color: "#010101" }}>
                <span
                  style={{
                    backgroundColor: "#F5F8F8",
                    minWidth: "70px",
                    marginLeft: "40px",
                    float: "left",
                    border: "2px solid green",
                    padding: "4px",
                  }}
                >
                  <ApartmentIcon
                    sx={{
                      fontSize: "20px",
                      position: "relative",
                      top: 4,
                      color: "green",
                      mr: 0.5,
                    }}
                  />
                  {element?.industry ? (
                    element?.industry.substr(0, 11).toUpperCase()
                  ) : (
                    <span style={{ color: "#ccc" }}>INDUSTRY </span>
                  )}
                </span>
                <span
                  style={{
                    backgroundColor: "#F5F8F8",
                    marginRight: "40px",
                    float: "right",
                    border: "2px solid green",
                    padding: "4px",
                  }}
                >
                  {" "}
                  <LocationOnIcon
                    sx={{
                      fontSize: "20px",
                      position: "relative",
                      top: 4,
                      color: "green",
                      mr: 0.5,
                    }}
                  />
                  {element?.location
                    ? element?.location.substr(0, 11).toUpperCase()
                    : ""}
                </span>
              </Typography>
            </Box>
          </Grid>
          <Box sx={{ mt: 3, height: "8vh" }}>
            <Typography>
              {element?.description ? (
                element?.description.length > 45 ? (
                  element?.description.substr(0, 30) + "..."
                ) : (
                  element?.description.substr(0, 30)
                )
              ) : (
                <Typography sx={{ color: "#ccc" }}>
                  No Information Available
                </Typography>
              )}
            </Typography>
          </Box>
        </div>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <Button
            disabled={connectText === "Connected"}
            onClick={handelRouteButton}
            sx={{ textTransform: "none" }}
            className={
              connectText === "Connected" ? "connectedBtn" : "connectBtn"
            }
          >
            {connectText}
          </Button>
        </Box>
      </Box>
    </Grid>
  );
};
export default ProfileCard;
