import React, { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useSelector } from "react-redux";
import {
  getProfileStartup,
  getStartupDetailsFromInvestor,
} from "../../store/login/actions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Spinner3Dots } from "../../Extras/Spinner";
import profileLogo from "./img/profile.jpeg";
import companyLogo from "./img/company.webp";

export const ChatProfile = ({ senderId, time }: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (
      sessionStorage.getItem("type") === "investor" ||
      localStorage.getItem("type") === "investor"
    ) {
      dispatch(getStartupDetailsFromInvestor(senderId));
    }
    if (
      sessionStorage.getItem("type") === "startup" ||
      localStorage.getItem("type") === "startup"
    ) {
      dispatch(getProfileStartup(senderId));
    }
  }, []);

  const profileArray: any = useSelector<any>(
    (state) => state.loginReducerHandler?.recentChatProfile
  );


  // const profileArray2: any = useSelector<any>(
  //   (state) => state.loginReducerHandler
  // );



  let name = "";
  let profilePicture = "";
  let placeholderLogo = "";
  if (
    sessionStorage.getItem("type") === "startup" ||
    localStorage.getItem("type") === "startup"
  ) {
    const profileSet = new Set();
    const uniqueProfile = profileArray.filter((data: { uId: any }) => {
      if (profileSet.has(data.uId)) {
        return false;
      }
      profileSet.add(data.uId);
      return true;
    });

    const profileData = uniqueProfile.find(
      (element: any) => element.uId === senderId
    );
    name = profileData?.name;
    profilePicture = profileData?.profilePicture;
    placeholderLogo = profileLogo;
  }
  if (
    sessionStorage.getItem("type") === "investor" ||
    localStorage.getItem("type") === "investor"
  ) {
    const profileSet = new Set();
    const uniqueProfile = profileArray.filter((data: { userId: any }) => {
      if (profileSet.has(data?.userId)) {
        return false;
      }
      profileSet.add(data?.userId);
      return true;
    });

    const profileData = uniqueProfile.find(
      (element: any) => element?.userId === senderId 
      // && element?.companyName !== null
    );
    name = profileData?.companyName;
    profilePicture = profileData?.profilePicture;
    placeholderLogo = companyLogo;
  }

  const handleImgError = (e: any) => {
    if (localStorage.getItem("type") === "investor") e.target.src = companyLogo;
    if (localStorage.getItem("type") === "startup") e.target.src = profileLogo;
  };

  const handleRedirect = (e: any) => {
    e.preventDefault();
    navigate("/chat", { state: senderId });
  };

  useEffect(() => {
    if (name === undefined) {
      setIsLoading(true);
      // return;
    } else {
      setIsLoading(false);
    }
  }, [name]);

  if (isLoading) {
    return (
      <div style={{ width: "100vw" }}>
        <Spinner3Dots message="" />
      </div>
    );
  }

  return (
    <Button
      onClick={handleRedirect}
      className="btn-ChatSection"
      endIcon={<ArrowForwardIosIcon />}
    >
  
      <Grid item width="25%" mr={2} display={{ xs: "none", sm: "block" }}>
        <Typography>{time?.slice(0, 10)}</Typography>
      </Grid>
      
      <Grid item xs={4}>
        <img
          src={profilePicture ? profilePicture : companyLogo}
          onError={handleImgError}
          // src={
          //   startupData?.profilePicture ? startupData?.profilePicture : "TEST"
          // }
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            objectFit: "contain",
            backgroundColor: "white",
          }}
          width="20%"
          alt="profileImage"
          className="main-comp-logo-nav"
        />
      </Grid>
   
      <Grid item xs={8}>
        <Typography>{name}</Typography>
      </Grid>
   


      {/* <Grid item>
                <ArrowForwardIosIcon />
              </Grid> */}


    </Button>
  );
};
