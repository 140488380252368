import React, { useEffect, useState, useRef } from "react";
import {
  createTheme,
  colors,
  Button,
  Typography,
  Container,
  Grid,
  FormControl,
  OutlinedInput,
  Paper,
  List,
} from "@mui/material";
import "./Chat.css";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SearchIcon from "@mui/icons-material/Search";
import picture from "./img/picture.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SendIcon from "@mui/icons-material/Send";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  fetchMessages,
  sendMessages,
  getStartupDetailsFromInvestor,
  fetchRecentChats,
} from "../../store/login/actions";
import { getProfileInv } from "../../store/startup/actions";
import { ChatProfile } from "./ChatProfile";
import { reverse } from "lodash";
import { BorderLeft, Verified } from "@mui/icons-material";
import chatImg from "./img/chat.png";
import Spinner, { Spinner3Dots, SpinnerHearts } from "../../Extras/Spinner";
import profileLogo from "./img/profile.jpeg";
import companyLogo from "./img/company.webp";
// import { ChatProfile } from "./ChatProfile";
import companyBydefault from "./img/profile.jpeg";
import { toast } from "react-hot-toast";
import { investorPremiumFeatures } from "../../FeaturesJSON/features";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface State {
  to: any;
  from: any;
  msg: any;
}
function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
  event.preventDefault();
}

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#DEE2E2",
  //   marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  //   [theme.breakpoints.up("sm")]: {
  //     marginLeft: theme.spacing(3),
  //     width: "auto",
  //   },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "orange",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
export const Chat = () => {
  document.title = "Make new India | Messaging";
  const locationRoute = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  // const { state } = locationRoute;
  const [state, setState] = useState<any>(locationRoute.state);
  const [senderId, setSenderId] = useState<any>(state);

  useEffect(() => {
    setState(locationRoute.state);
  }, [locationRoute.state]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let chatsId: any = [];

  const [messages, setMessages] = useState<any>();
  const recentChatsList: any = useSelector<any>(
    (state) => state.loginReducerHandler?.recentChats
  );



  const startupInformation: any = useSelector<any>(
    (state) => state.loginReducerHandler?.startupDetails
  );
  // currentID

  const checkUserId = startupInformation?.map((item: any) => item?.userId);

  // MY WORK
  useEffect(() => {
    // if ( currentID !== checkUserId) {
    //   // navigate("/pricing-investor");
    // }
    // if(chatsId.length < 3){
    //   return;
    // }

    const currentID = recentChatsList?.map((item: any) => item?.to);

    if (profile === "premium") {
      return;
    } else if (localStorage.getItem("type") === "startup") {
      return;
    } else if (
      (localStorage.getItem("type") === "investor" &&
        profile === "basic" &&
        currentID.includes(state)) ||
      // recentChatsList.map((item: any) => item?.to.includes(state))) ||
      state === null
    ) {
      return;
    } else if (chatsId.length < investorPremiumFeatures.chatLimit) {
      return;
    } else {
      navigate("/pricing-investor");
      toast("Please buy premium to chat with more people");
    }
  }, []);

  let id: any = "";
  if (localStorage.getItem("id")) id = localStorage.getItem("id");
  // if (sessionStorage.getItem("id")) id = sessionStorage.getItem("id");

  // let senderId: any = "";

  useEffect(() => {
    if (state !== null) {
      setSenderId(state);
    } else {
      if (
        localStorage.getItem("type") === "investor" ||
        sessionStorage.getItem("type") === "investor"
      )
        // senderId = "fdeb097e-1497-4166-be71-db2f75bfe981";
        setSenderId("fdeb097e-1497-4166-be71-db2f75bfe981");
      if (
        localStorage.getItem("type") === "startup" ||
        sessionStorage.getItem("type") === "startup"
      )
        // senderId = "884b7448-5789-47ae-996b-cfaa237ae333";
        setSenderId("884b7448-5789-47ae-996b-cfaa237ae333");
    }
  }, [state]);

  const [values, setValues] = useState<State>({
    from: id,
    to: senderId,
    msg: "",
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setValues((prevState) => ({ ...prevState, to: senderId }));

      dispatch(fetchRecentChats(id));
      dispatch(fetchMessages(values));
    }, 1000);
    if (
      localStorage.getItem("type") === "investor" ||
      sessionStorage.getItem("type") === "investor"
    ) {
      if (senderId !== null) dispatch(getStartupDetailsFromInvestor(senderId));
    }
    if (
      localStorage.getItem("type") === "startup" ||
      sessionStorage.getItem("type") === "startup"
    ) {
      if (senderId !== null) dispatch(getProfileInv(senderId));
    }

    return () => clearInterval(interval);
  }, [senderId, values]);

  const profile = useSelector<any>(
    (state) => state.loginReducerHandler?.profile?.subscription
  );
  const startupProfile = useSelector<any>(
    (state) => state.startupReducer?.login[0]?.subscription
  );

  // useEffect(() => {
  //   if (profile === "Basic" && recentChatsList.length >= 1) {
  //     toast("Please buy a premium membership to chat with more startups");
  //     navigate("/pricing-investor");
  //   }
  // });

  const handleBuyPremium = () => {
    navigate("/pricing-investor");
    // toast("Please buy premium to chat more");
  };

  recentChatsList?.map((chats: any) => {
    if (chats?.from === id) {
      chatsId?.push({ senderId: chats?.to, updatedTime: chats?.updatedAt });
    }
    if (chats.to === id) {
      chatsId?.push({ senderId: chats?.from, updatedTime: chats?.updatedAt });
    }
  });
  chatsId.reverse();

  const investorProfile: any = useSelector<any>(
    (state) => state.startupReducer.investorData
  );

  // const verify = chatsId.map((item: any) =>
  //   item.senderID.map((element:any) => (element))
  // );

  let name = "user";
  let companyName = "company";
  let location = "location";
  let pictureLogo = "";
  let placeholderLogo = "";
  if (localStorage.getItem("type") === "investor") {
    name = startupInformation[0]?.companyName;
    companyName = startupInformation[0]?.companyName;
    location = startupInformation[0]?.location;
    pictureLogo = startupInformation[0]?.profilePicture;
    placeholderLogo = companyLogo;
  }
  if (localStorage.getItem("type") === "startup") {
    if (investorProfile) {
      name = investorProfile[0]?.name;
      companyName = investorProfile[0]?.name;
      location = investorProfile[0]?.location;
      pictureLogo = investorProfile[0]?.profilePicture;
      placeholderLogo = profileLogo;
    }
  }

  const messagesArray: any = useSelector<any>(
    (state) => state.loginReducerHandler.messages
  );

  const scrollRef: any = useRef<null | HTMLDivElement>(null);
  const inputRef: any = useRef<null | HTMLDivElement>(null);
  useEffect(() => {
    setMessages(messagesArray[0]);
  }, [messagesArray]);
  // useEffect(() => {
  //   if (scrollRef.current) {
  //     scrollRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, [messages]);

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  let instantArray = [
    {
      content: "",
      date: "xxxx",
      recieverInfo: senderId,
      senderInfo: id,
    },
  ];
  const handleSubmit = (e: any) => {
    inputRef.current.focus();
    if (values?.msg !== "") {
      instantArray[0].content = values?.msg;
      dispatch(sendMessages(values));
      setValues({ ...values, msg: "" });
      if (messages?.length !== 0 && messages !== undefined)
        setMessages((prevState: any) => [...prevState, instantArray[0]]);
    }
  };

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="/"
      onClick={handleClick}
    >
      Home
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href="/material-ui/getting-started/installation/"
      onClick={handleClick}
    ></Link>,
  ];

  const senderChatStyle = {
    margin: 10,
    padding: 10,
    textAlign: "right",
  };
  const receiverChatStyle = {
    padding: 10,
    margin: 10,
  };
  const senderBorderStyle = {
    borderRadius: 20,
    padding: 10,
    backgroundColor: "green",
    border: "2px solid green",
    color: "white",
  };
  const receiverBorderStyle = {
    borderRadius: 20,
    padding: 10,
    border: "2px solid #EEEEF0",
    backgroundColor: "#EEEEF0",
    color: "black",
  };

  useEffect(() => {
    if (recentChatsList === undefined) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [recentChatsList]);

  if (isLoading) {
    return (
      <div>
        <Spinner message="" />
      </div>
    );
  }
  const handleImgError = (e: any) => {
    e.target.src = companyBydefault;
  };

  // const verifyIds = recentChatsList.map((item: any) => item.to === checkUserId);
  // // const verifyIds = recentChatsList.filter((item: any) => item.to === checkUserId)

  // const finalCheck = verifyIds.filter((i: any) => i === false);

  return (
    <>
      {recentChatsList?.map((item: any) => item.to !== checkUserId) ? (
        <Container
          className="chat-container"
          style={{ height: "85vh", borderRadius: 5 }}
          sx={{ boxShadow: 1 }}
        >
          <Grid container>
            <Grid item xs={12} md={6} lg={6}>
              <Stack spacing={2}>
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  {breadcrumbs}
                </Breadcrumbs>
              </Stack>
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <Typography fontSize="45px">Messaging</Typography>
                </Grid>
                <Grid item>
                  <Typography fontSize="18px">
                    ({chatsId.length} chats)
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={11}>
                <Search>
                  {/* <SearchIconWrapper><SearchIcon /></SearchIconWrapper> */}
                  <StyledInputBase
                    disabled
                    placeholder="Recent Chats"
                    inputProps={{ "aria-label": "search" }}
                  />
                </Search>
              </Grid>
              <Box
                style={{
                  height: "auto",
                  maxHeight: "62vh",
                  overflow: "auto",
                  marginTop: 10,
                  paddingLeft: 20,
                  paddingRight: 20,
                  backgroundColor: "#eff3f3",
                }}
              >
                <Grid
                  onClick={() => {
                    // setValues((prevValue) => ({ ...prevValue, to: state }));
                  }}
                  container
                  // p={2}
                  alignItems="center"
                >
                  {chatsId?.map((chats: any, index: any) => (
                    <ChatProfile
                      senderId={chats?.senderId}
                      time={chats?.updatedTime}
                    />
                  ))}
                </Grid>
              </Box>
            </Grid>
            {/* start */}
            {state === null ? (
              <div
                className="customChatComponent"
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  // height: "83vh",

                  // height: "auto",
                  // borderLeft: "1px solid #dedcdc",
                  margin: "auto",
                }}
              >
                <img width="80px" src={chatImg} alt="send icon" />
                <Typography
                  style={{ textAlign: "center", marginTop: "20px" }}
                  variant="h4"
                >
                  Your Messages
                </Typography>
                <Typography style={{ textAlign: "center", color: "gray" }}>
                  Send messages to your connections.
                </Typography>
              </div>
            ) : (
              <>
                {/* {localStorage.getItem("type") === "investor" &&
              profile === "basic" &&
              recentChatsList.length > investorPremiumFeatures.chatLimit ? (
                // !chatsId.map((item: any) => item.senderId.includes(checkUserId))
                // recentChatsList.map((item: any) => item.to !== checkUserId)
                handleBuyPremium
              ) : ( */}
                <Grid item xs={12} md={6} lg={6}>
                  <Box
                    className="bg-white"
                    height="83vh"
                    sx={{
                      position: "relative",
                      top: -3,
                      left: 0,
                      borderRadius: 2,
                      boxShadow: 2,
                    }}
                  >
                    <Grid container className="container-chat" p={2}>
                      <Grid item>
                        <img
                          onError={handleImgError}
                          src={pictureLogo ? pictureLogo : placeholderLogo}
                          width="80%"
                          alt="Test"
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "50%",
                            objectFit: "contain",
                            backgroundColor: "white",
                            // marginLeft: 20,
                            marginRight: 20,
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography
                          sx={{
                            textTransform: "capitalize",
                            fontWeight: 500,
                          }}
                        >
                          {name}
                        </Typography>
                        <Typography sx={{ color: "#7C7C7C", fontSize: "14px" }}>
                          {companyName} . {location}
                        </Typography>
                      </Grid>
                    </Grid>
                    <div>
                      <Box>
                        <Paper
                          elevation={0}
                          style={{
                            height: "auto",
                            maxHeight: "58vh",
                            overflow: "auto",
                            paddingLeft: 20,
                            paddingRight: 20,
                          }}
                        >
                          <List>
                            {messages === undefined ? (
                              <SpinnerHearts message="Say Hello 👋🏻" />
                            ) : (
                              <Box className="scrollingContainer">
                                {messages?.map((msg: any) => (
                                  <Box
                                    ref={scrollRef}
                                    style={
                                      msg.senderInfo === id
                                        ? senderChatStyle
                                        : receiverChatStyle
                                    }
                                  >
                                    <span
                                      style={
                                        msg.senderInfo === id
                                          ? senderBorderStyle
                                          : receiverBorderStyle
                                      }
                                    >
                                      {msg.content}
                                    </span>
                                  </Box>
                                ))}
                              </Box>
                            )}
                          </List>
                        </Paper>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          position: "absolute",
                          bottom: "10px",
                          left: "10px",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <FormControl
                          sx={{ width: "88%", backgroundColor: "#E4E4E4" }}
                        >
                          <OutlinedInput
                            id="inputBox"
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleSubmit(e);
                              }
                            }}
                            sx={{ border: "1px solid #333333" }}
                            placeholder="Type Something..."
                            onChange={handleChange("msg")}
                            value={values?.msg}
                            autoFocus
                            inputRef={inputRef}
                          />
                        </FormControl>
                        <SendIcon
                          onClick={handleSubmit}
                          sx={{ ml: 2 }}
                          fontSize="large"
                          color="warning"
                          className="btn"
                        />
                      </Box>
                    </div>
                  </Box>
                </Grid>
                {/* )} */}
              </>
            )}
            {/* end */}
          </Grid>
        </Container>
      ) : (
        handleBuyPremium
      )}
    </>
  );
};
