import { Avatar, Badge, Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./UnlockSection.css";
import bgGif from "../../Assets/image/bggif.svg";
import step1 from "../../Assets/image/step1_R.svg";
import step2 from "../../Assets/image/step2_C.svg";
import step3 from "../../Assets/image/step3_I.svg";
import connect from "../../Assets/image/connect.gif";
import register from "../../Assets/image/register.gif";
import intract from "../../Assets/image/Interact.gif";
import interactt from '../../Assets/image/interactnew.svg';
import registerr from '../../Assets/image/registerr.svg';
import connectt from '../../Assets/image/connect.svg';

const UnlockSection = () => {
  const [currentGif, setCurrentGif] = useState(0);

  useEffect(() => {
    const gifSequence = [0, 1, 2];
    const interval = setInterval(() => {
      setCurrentGif((currentGif) => (currentGif + 1) % gifSequence.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  return (
    <Box sx={{ backgroundColor: "#EFF3F3", py: "4rem" }}>
      <Typography className="heading-head" sx={{ textAlign: "center" }}>
        Unlock the&nbsp;
        <span
          style={{
            textDecoration: "underline #ff9a33 3px",
            textUnderlineOffset: "22px",
          }}
        >
          Startup Business&nbsp;
        </span>
        Opportunities
      </Typography>

      <Grid
        pt={5}
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        sx={{ maxWidth: "1400px", margin: "auto" }}
      >
        <Grid item sx={{ textAlign: "center" }} mt={3}>
          <Box style={{ position: "relative" }}>
            {currentGif === 0 ? (
              <img src={register} alt="register" />
            ) : (
              <img src={registerr} alt="register" />
            )}
            <img
              src={step1}
              alt="step1"
              style={{
                position: "absolute",
                bottom: "-22px",
                left: "0",
                right: "0",
                margin: "auto",
              }}
            />
          </Box>
          <Box>
            <Typography pt={5} className="step_heading">
              Register
            </Typography>
            <Typography className="step_subtext">
              Start for free & Create your <br /> profile.
            </Typography>
          </Box>
        </Grid>
        {/*  */}
        <Grid item sx={{ textAlign: "center" }} mt={3}>
          <Box style={{ position: "relative" }}>
            {currentGif === 1 ? (
              <img src={connect} alt="connect" />
            ) : (
              <img src={connectt} alt="connect" />
            )}
            <img
              src={step2}
              alt="step1"
              style={{
                position: "absolute",
                bottom: "-22px",
                left: "0",
                right: "0",
                margin: "auto",
              }}
            />
          </Box>
          <Box>
            <Typography pt={5} className="step_heading">
              Connect
            </Typography>
            <Typography className="step_subtext">
              Discover, Explore and Shortlist <br /> Opportunities.
            </Typography>
          </Box>
        </Grid>
        {/*  */}
        <Grid item sx={{ textAlign: "center" }} mt={3}>
          <Box style={{ position: "relative" }}>
            {currentGif === 2 ? (
              <img src={intract} alt="interact" />
            ) : (
              <img src={interactt} alt="connect" />
            )}
            <img
              src={step3}
              alt="step1"
              style={{
                position: "absolute",
                bottom: "-22px",
                left: "0",
                right: "0",
                margin: "auto",
              }}
            />
          </Box>
          <Box>
            <Typography pt={5} className="step_heading">
              Interact
            </Typography>
            <Typography className="step_subtext">
              Upgrade to Premium to Start a <br /> Conversation
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UnlockSection;
