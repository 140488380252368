import { Typography, Box, Container, Grid } from "@mui/material";
import manimg from "./img/manimg.png";
import "./discoverbanner.css";

const DiscoverBanner = () => {
  return (
    <Container className="max-width" sx={{ p: { xs: "0px", sm: "0px" } }}>
      <Box className="discover-main-container">
        <Box>
          <Typography variant="h1" className="main-heading">
          Discover Top Performing Startups {" "}
            <u id="india-text">to Invest in India</u>
           
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default DiscoverBanner;
