import React, { useEffect, useState } from "react";
import {
  Avatar,
  Badge,
  Box,
  Breadcrumbs,
  Button,
  FormHelperText,
  Grid,
  InputAdornment,
  InputBase,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import "./editprofile.css";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import user from "../img/user.png";
import FormButton from "../../utils/buttons/SuccessButton";
import ClearIcon from "@mui/icons-material/Clear";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import {
  updateInvestorProfile,
  getProfile,
} from "../../../store/login/actions";
import axios from "axios";
interface prop {
  key?: any;
}

interface State {
  description: any;
  name: any;
  email: any;
  companyName: any;
  occupation: any;
  industry: any;
  location: any;
  // areaOfExpertise: any;
  // companiesBacked: any;
  // profilePicture: any;
}

interface StateData {
  areaOfExpertise: any;
}
interface StateData2 {
  companiesBacked: any;
}

const EditProfile = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("id"))
      dispatch(getProfile(localStorage.getItem("id")));
    // if (sessionStorage.getItem("id"))
    //   dispatch(getProfile(sessionStorage.getItem("id")));
  }, []);

  const investorData: any = useSelector<any>(
    (state) => state.loginReducerHandler?.login[0]
  );

  const [values, setValues] = useState<State>({
    description: investorData?.description,
    name: investorData?.name,
    email: investorData?.email,
    companyName: investorData?.companyName,
    occupation: investorData?.occupation,
    industry: investorData?.industry,
    location: investorData?.location,
    // areaOfExpertise: investorData?.areaOfExpertise,
    // companiesBacked: investorData?.companiesBacked,
    // description: "test",
    // name: "Test",
    // email: "test@gmail.com",
    // companyName: "xyz",
    // occupation: "test",
    // industry: "Test",
    // location: "Test",
    // areaOfExpertise: "Test",
    // companiesBacked: "Test",
    // profilePicture: investorData?.profilePicture,
  });

  const [data, setData] = useState({
    areaOfExpertise: investorData?.areaOfExpertise,
  })
  const [data2, setData2] = useState({
    companiesBacked: investorData?.companiesBacked,
  })

  let navigate = useNavigate();

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="#010101"
      fontSize="14px"
      href="/dashboard"
      sx={{ opacity: "66%" }}
    >
      HOME
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="#010101"
      fontSize="14px"
      href="/login"
      sx={{ opacity: "66%" }}
    >
      LOGIN
    </Link>,
  ];

  const id = useSelector((state: any) => {
    return state.loginReducerHandler.login[0]?.uId;
  });
  const [selectedImage, setSelectedImage] = useState();
  const imageChange = (e: any) => {
    setSelectedImage(e.target.files[0]);
  };

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      // if (prop === "areaOfExpertise" || prop === "companiesBacked")
      //   setValues({ ...values, [prop]: event.target.value.split(",") });
      // else 
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleChangeData =
    (prop: keyof StateData) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (prop === "areaOfExpertise")
        setData({ ...data, [prop]: event.target.value.split(",") });
    };
  const handleChangeData2 =
    (prop: keyof StateData2) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if ( prop === "companiesBacked")
        setData2({ ...data2, [prop]: event.target.value.split(",") });
    };

  function isValidEmail(email: any) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const [imgState, setImgState] = useState("");

  // const url = "https://makenewindia.com/api";

  // // imgState = {
  // //   selectedFile: null,
  // // };
  // const handleSubmit = () => {
  //   const formData: any = new FormData();
  //   let key: keyof typeof values;
  //   for (key in values) {
  //     formData.append(key, values[key]);
  //   }
  //   formData.append("profilePicture", imgState);

  //   axios
  //     .put(`${url}/investor/update/${id}`, formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //         "Content-Length": "<calculated when request is sent>",
  //       },
  //     })
  //     .then((res) => {
  //       return res.data;
  //     })
  //     .catch((err: any) => {
  //       return err;
  //     });
  // };

  const url = "https://makenewindia.com/api";

  const handleSubmit = () => {
    const formData: any = new FormData();
    let key: keyof typeof values;
    for (key in values) {
      formData.append(key, values[key]);
    }
    if (selectedImage === undefined)
      formData.append("profilePicture", investorData?.profilePicture);
    else formData.append("profilePicture", selectedImage);

    axios
      .put(`${url}/investor/update/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          // "Content-Length": "<calculated when request is sent>",
        },
      })
      .then((res) => {
        return res?.data;
      })
      .catch((err: any) => {
        return err;
      });
    setTimeout(() => {
      profileUpdated();
      navigate("/dashboard", { replace: true });
    }, 500);
  };

  const handleSubmitData = () => {
    const formData: any = new FormData();
    let key: keyof typeof data;
    for (key in data) {
      formData.append(key, data[key]);
    }
    axios
      .put(`${url}/investor/update/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          // "Content-Length": "<calculated when request is sent>",
        },
      })
      .then((res) => {
        return res?.data;
      })
      .catch((err: any) => {
        return err;
      });
    setTimeout(() => {
      profileUpdated();
      navigate("/dashboard", { replace: true });
    }, 500);
  };

  const handleSubmitData2 = () => {
    const formData: any = new FormData();
    let key: keyof typeof data2;
    for (key in data2) {
      formData.append(key, data2[key]);
    }
    axios
      .put(`${url}/investor/update/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          // "Content-Length": "<calculated when request is sent>",
        },
      })
      .then((res) => {
        return res?.data;
      })
      .catch((err: any) => {
        return err;
      });
    setTimeout(() => {
      profileUpdated();
      navigate("/dashboard", { replace: true });
    }, 500);
  };

  let fileInput: any;

  const fileSelectedHandler = (event: any) => {
    setImgState(event.target.files[0]);
    //first element when we upload a file
  };

  const profileUpdated = (toastHandler = toast) => {
    toastHandler.success("Profile Updated Successfully!", {
      style: {
        background: "#04111d",
        color: "#fff",
      },
    });
  };

  return (
    <Box className="main-container bg-grey mx">
      <Toaster position="top-center" reverseOrder={false} />
      <Grid container rowSpacing={2} spacing={3} rowGap={1}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Stack spacing={2}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </Stack>
          <Typography className="size-sm light">Edit Profile</Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          className="save-profile-btn-container"
          onClick={handleSubmit}
        >
          <FormButton
            label="Save"
            className="btn save-profile-btn"
            to="/dashboard"
          />
        </Grid>
        <Grid item xs={12} className="align avatar-position">
          <Box>
            <Avatar
              alt="user"
              // src={
              // investorData?.profilePicture
              //   ? investorData?.profilePicture
              //   : "/broken-image.jpg"
              // }
              src={
                selectedImage
                  ? URL.createObjectURL(selectedImage)
                  : investorData?.profilePicture
                  ? investorData?.profilePicture
                  : "/broken-image.jpg"
              }
              style={{ width: 112, height: 112 }}
            />
            <Stack spacing={2}>
              <Button
                component="label"
                sx={{ vertical: "bottom", horizontal: "right" }}
              >
                {/* Upload */}
                <AddCircleIcon className="green " />
                <input
                  onChange={imageChange}
                  hidden
                  id="photo-upload"
                  accept="image/*"
                  type="file"
                />
              </Button>
            </Stack>
            {/* </Badge> */}
          </Box>
        </Grid>
        <Grid item xs={12} className="align text-area-container">
          <InputBase
            multiline
            rows={6}
            className="bg-white text-field book text-area"
            placeholder="About yourself"
            onChange={handleChange("description")}
            value={values?.description}
          />
        </Grid>
      </Grid>
      <Grid
        container
        mt={1}
        rowSpacing={2}
        columnSpacing={6}
        rowGap={2}
        sx={{ p: { xs: 0, sm: 0, md: 3 }, mb: { xs: "80px", sm: 0 } }}
        className="edit-profile-fields-container"
      >
        <Grid item xs={12} sm={12} md={6} lg={6} wrap="nowrap">
          <InputBase
            className="bg-white text-field book field-height"
            fullWidth
            placeholder="Name"
            value={values?.name}
            onChange={handleChange("name")}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} wrap="nowrap">
          <InputBase
            disabled
            className="bg-white text-field book field-height"
            fullWidth
            placeholder="Email"
            value={values?.email}
            onChange={handleChange("email")}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} wrap="nowrap">
          <InputBase
            className="bg-white text-field book field-height"
            fullWidth
            placeholder="Company Name"
            value={values?.companyName}
            onChange={handleChange("companyName")}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} wrap="nowrap">
          <InputBase
            className="bg-white text-field book field-height"
            fullWidth
            placeholder="Occupation"
            value={values?.occupation}
            onChange={handleChange("occupation")}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} wrap="nowrap">
          <InputBase
            className="bg-white text-field book field-height"
            fullWidth
            placeholder="Industry"
            value={values?.industry}
            onChange={handleChange("industry")}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} wrap="nowrap">
          <InputBase
            className="bg-white text-field book field-height"
            fullWidth
            placeholder="Location"
            value={values?.location}
            onChange={handleChange("location")}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} wrap="nowrap">
          <InputBase
            className="bg-white text-field book field-height"
            fullWidth
            placeholder="Area of Expertise"
            endAdornment={
              <InputAdornment position="start">
                <Button className="bg-green white add-btn " onClick={handleSubmitData}>Add</Button>
              </InputAdornment>
            }
            value={data?.areaOfExpertise}
            onChange={handleChangeData("areaOfExpertise")}
          />
          {/* <Box className="tags-container">
            {Array.from(Array(10)).map((_, index) => (
              <Box sx={{ display: "flex" }}>
                <FormHelperText className="grey">Google</FormHelperText>
                <ClearIcon className="green" fontSize="small" />
              </Box>
            ))}
          </Box> */}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} wrap="nowrap">
          <InputBase
            className="bg-white text-field book field-height"
            fullWidth
            placeholder="Companies Backed"
            value={data2?.companiesBacked}
            onChange={handleChangeData2("companiesBacked")}
            endAdornment={
              <InputAdornment position="start">
                <Button className="bg-green white add-btn " onClick={handleSubmitData2} >Add</Button>
              </InputAdornment>
            }
          />
          {/* <Box className="tags-container">
            {Array.from(Array(10)).map((_, index) => (
              <Box sx={{ display: "flex" }}>
                <FormHelperText className="grey">Google</FormHelperText>
                <ClearIcon className="green" fontSize="small" />
              </Box>
            ))}
          </Box> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditProfile;
