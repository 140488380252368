import React from "react";
import {
  Button,
  Card,
  CardMedia,
  Toolbar,
  Typography,
  Popover,
  Avatar,
} from "@mui/material";
import { Box, Grid } from "@mui/material";
import img from "./img/company.png";
import logo from "../../../../Assets/image/googleLogo.jpg";
import { Link, useNavigate } from "react-router-dom";
// import process from './img/Process.png'
import "./companydescription.css";
import { LocationOn } from "@mui/icons-material";
import ChatBtn from "../../../utils/buttons/ChatBtn";
import ShareBtn from "../../../utils/buttons/ShareBtn";
import SideBar from "../../../Profile/UI/SideBar";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { Data } from "../../../StartupComponents/UserProfilePage/User/Data";
import LockIcon from "@mui/icons-material/Lock";

// declare function useNavigate(): NavigateFunction;

interface NavigateFunction {
  (to: any, options?: { replace?: boolean; state?: any }): void;
  (delta: number): void;
}

const CompanyDescription = (props: any) => {
  let { data, userSubscription } = props;

  const navigate = useNavigate();

  const [anchorElShare, setAnchorElShare] =
    React.useState<HTMLButtonElement | null>(null);

  const handleClickShare = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElShare(event.currentTarget);
  };
  const handleCloseShare = () => {
    setAnchorElShare(null);
  };
  const copyLink = (index: any) => {
    if (index === 4) {
      navigator.clipboard.writeText("makenewindia.com");
    }
  };
  const openShare = Boolean(anchorElShare);
  const idShare = openShare ? "simple-popover-Share" : undefined;

  const handleRedirect = (e: any) => {
    e.preventDefault();
    navigate("/chat", { state: data.userId });
  };
  const handleImgError = (e: any) => {
    e.target.src = img;
  };

  return (
    <>
      <Box sx={{ px: { md: "20px" } }}>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item lg={8} xs={12} md={8}>
            <Card className="company-det-card">
              <CardMedia
                component="img"
                alt="img"
                onError={handleImgError}
                image={data?.thumbnail ? data?.thumbnail : img}
                className="main-comp-img"
              />
              <Avatar
                src={data?.profilePicture ? data?.profilePicture : logo}
                alt="test"
                className="main-comp-logo"
              />

              <Box className="company-name">
                <Typography variant="h4" className="bold">
                  {data?.companyName ? data.companyName : "..."}
                </Typography>
                <Typography
                  variant="subtitle1"
                  className="company-det-industry"
                >
                  {data?.industry ? data.industry : "Technology"}
                </Typography>
              </Box>
              <Typography variant="subtitle1" className="company-det-descrip">
                {data?.companyInfo ? data.companyInfo : "..."}
              </Typography>
              <Grid
                container
                rowSpacing={3}
                columnSpacing={{ xs: 1, sm: 2, md: 6 }}
                // sx={{ p: " 10px 55px" }}
                sx={{ p: { sm: " 10px 55px", xs: "20px" } }}
                style={{ justifyContent: "space-between" }}
              >
                <Grid item lg={4} xs={12} md={4} sm={4}>
                  <Typography
                    variant="h3"
                    className="answer"
                    sx={{ fontSize: { xs: "30px", lg: "38px", md: "38px" } }}
                  >
                    {data?.equityOffer ? data.equityOffer : "..."}%
                  </Typography>
                  <Typography variant="h6" className="question">
                    EQUITY OFFER
                  </Typography>
                </Grid>
                <Grid item lg={4} xs={12} md={4} sm={4}>
                  <Typography
                    variant="h3"
                    className="answer"
                    // style={{width:"260px"}}
                    sx={{
                      fontSize: { xs: "30px", lg: "38px", md: "38px" },
                      width: { lg: "270px" },
                      marginRight: 0,
                    }}
                  >
                    ₹
                    {data?.askPrice >= 10000000
                      ? (data?.askPrice / 10000000).toFixed(2) + " Cr"
                      : data?.askPrice >= 100000
                      ? (data?.askPrice / 100000).toFixed(2) + " L"
                      : data?.askPrice}{" "}
                  </Typography>
                  <Typography variant="h6" className="question">
                    ASK PRICE
                  </Typography>
                </Grid>
                <Grid item lg={4} xs={12} md={4} sm={4}>
                  <Typography
                    variant="h3"
                    className="answer"
                    style={{ padding: 0 }}
                    sx={{
                      fontSize: { xs: "30px", lg: "38px", md: "38px" },
                      width: { lg: "230px" },
                      padding: { lg: "0px" },
                    }}
                  >
                    {data?.founded ? `${data.founded.slice(0, 10)}` : "..."}
                  </Typography>
                  <Typography variant="h6" className="question">
                    FOUNDED
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box className="location-btn align">
                    <LocationOnOutlinedIcon className="green" />
                    <Typography className="book">
                      {data?.location ? data.location : "..."}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={8} className="align">
                  <Button
                    onClick={handleRedirect}
                    className="btn bg-green white chat btn-height"
                  >
                    Chat
                  </Button>
                  <Button
                    onClick={handleClickShare}
                    className="btn green bg-white share btn-height"
                  >
                    Contact Details
                  </Button>
                  <Popover
                    id={idShare}
                    open={openShare}
                    anchorEl={anchorElShare}
                    onClose={handleCloseShare}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                  >
                    <Box
                      textAlign="center"
                      sx={{ px: 2, py: 2, lineHeight: "normal", m: 1 }}
                      className="popover-connect"
                    >
                      {/* {Data.map((ele, index) => {
                        return (
                          <img
                            onClick={() => {
                              {
                                copyLink(index);
                              }
                            }}
                            style={{ cursor: index == 4 ? "pointer" : "" }}
                            src={ele.image}
                            alt=""
                          />
                        );
                      })} */}
                      {userSubscription === "basic" ? (
                        <Box
                          display="flex"
                          // gap={1}
                          justifyContent={"center"}
                          alignItems={"center"}
                          borderRadius={"40px"}
                        >
                          <LockIcon fontSize="medium" />
                          <Link
                            to={"/pricing-investor"}
                            style={{ textDecoration: "none" }}
                          >
                            <Typography variant="h6" color={"black"}>
                              Unlock Premium To See
                            </Typography>
                          </Link>
                        </Box>
                      ) : (
                        <>
                          <Box display="flex" flexDirection={"column"} textAlign={"left"}>
                            <Box>
                              <h4>
                                Email :
                                <span>
                                  {" "}
                                  <a href={`mailto:/${data?.email}`}>
                                    {data?.email}
                                  </a>
                                </span>
                              </h4>

                              <h4>
                                Conact Number :
                                <span>
                                  {" "}
                                  {data?.contactNumber === ""
                                    ? "Not Available"
                                    : data?.contactNumber}
                                </span>
                              </h4>
                            </Box>
                          </Box>
                        </>
                      )}
                    </Box>
                  </Popover>
                </Grid>
              </Grid>

              {/* <Toolbar className="location-company">
                <LocationOn color="success" className="location-on" />
                <Typography variant="subtitle1">Banglore, Karnataka</Typography>
              </Toolbar>
              <Toolbar className="Button-company">
                <ChatBtn label="CHAT" />
                <ShareBtn label="Share" />
              </Toolbar> */}
            </Card>
          </Grid>

          <Grid item lg={4} xs={0} md={4} className="comp-sidebar">
            <SideBar className="max-height-sidebar" />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default CompanyDescription;
