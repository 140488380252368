import axios from "axios";
import { put, takeEvery, call, takeLatest, take } from "redux-saga/effects";
import {
  GET_LOGIN_FETCH,
  GET_LOGIN_SUCCESS,
  GET_LOGIN_FAIL,
  getProfile,
  LOGOUT,
  LOGOUT_SUCCESS,
  SUBMIT_SUPPORT_FORM,
  GETSEARCH_I,
  CHANGE_PASSWORD_INVESTOR,
  FORGET_PASSWORD_INVESTOR,
  SEND_MESSAGES,
  STORE_MESSAGES,
  FETCH_MESSAGES,
  GET_STARTUP_DETAILS_FROM_INVESTOR,
  GET_STARTUP_DETAILS_SUCCESS_FROM_INVESTOR,
  ACCEPT_REQUEST_OF_STARTUP,
} from "../store/login/actions";
import {
  GET_LOGIN_FETCH_STARTUP,
  GET_LOGIN_SUCCESS_STARTUP,
  GET_LOGIN_FAIL_STARTUP,
  LOGOUT_STARTUP,
  LOGOUT_SUCCESS_STARTUP,
  GETSEARCH_S,
  CHANGE_PASSWORD_STARTUP,
  DELETE_EMPLOYEE,
  UPDATE_EMPLOYEE,
  SEND_REQUEST_TO_INVESTOR,
  ACCEPT_REQUEST_OF_INVESTOR,
  GET_INVESTOR_NOTIFICATION,
  GET_STARTUP_NOTIFICATION,
  FORGET_PASSWORD_STARTUP,
} from "../store/startup/actions";
import {
  GET_REGISTER_FETCH_STARTUP,
  GET_REGISTER_FAIL_STARTUP,
} from "../store/startupRegister/actions";
import {
  GET_REGISTER_FETCH,
  GET_REGISTER_FAIL,
} from "../store/register/actions";
// import { store } from "react-notifications-component";
import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { cardContentClasses } from "@mui/material";
import {
  getBlog,
  getRecomendedStartup,
  getRisingStartup,
  getInvestorProfileApi,
  getUserProfile,
  getInvProfile,
  InvestorUpdate,
  startupUpdate,
  getInvestorSearchData,
  getRisingStartupS,
  getInvestorSearchDataS,
  fetchStartupDetailsAPI,
  getInvestorSearchDataI,
  changePasswordInvestorAPI,
  changePasswordStartupAPI,
  addEmployee,
  startupEmployee,
  removeEmployee,
  updateEmployeeStartup,
  sendRequestToInvestorApi,
  acceptRequestOfInvestorApi,
  acceptRequestOfStartupApi,
  fetchRecentChatsAPI,
  getInvestorNotification,
  getStartupNotification,
  forgotPasswordStartupAPI,
  forgotPasswordInvestorAPI,
  getAllStartup,
  getInvestorSearchDataNav,
  getStartupSearchDataNav,
} from "../Api/api";
import { toast } from "react-hot-toast";

export interface ResponseGenerator {
  value?: any;
  status?: string;
  tokenData?: any;
  data?: any;
  savedData?: any;
  type?: any;
  id?: any;
  val?: any;
  profileData?: Array<any>;
  MessageResponse?: any;
  message?: string;
  code?: any;
}

let url = "https://makenewindia.com/api";

function loginApi(value: any) {
  return fetch("https://makenewindia.com/api/login?key=investor", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
}
function sendMessagesApi(value: any) {
  return fetch("https://makenewindia.com/api/chat", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
}
function fetchMessagesApi(value: any) {
  return fetch(
    `https://makenewindia.com/api/getChats/${value.from}/${value.to}`,
    {
      method: "GET",
    }
  )
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
}
function loginApiStartup(value: any) {
  return fetch(`${url}/login?key=startup`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
}

function* workGetLoginFetch(action: any) {
  const value = action.payload;
  const login: ResponseGenerator = yield call(loginApi, value);
  if (login.status === "OK") {
    if (value.isChecked === true) {
      sessionStorage.setItem("profile", login?.tokenData);
      sessionStorage.setItem("type", login?.type);
      sessionStorage.setItem("id", login?.data.uId);
      localStorage.setItem("profile", login?.tokenData);
      localStorage.setItem("type", login?.type);
      localStorage.setItem("id", login?.data.uId);
    } else {
      sessionStorage.setItem("profile", login?.tokenData);
      sessionStorage.setItem("type", login?.type);
      sessionStorage.setItem("id", login?.data.uId);
      localStorage.setItem("profile", login?.tokenData);
      localStorage.setItem("type", login?.type);
      localStorage.setItem("id", login?.data.uId);
    }
    yield put({ type: GET_LOGIN_SUCCESS, payload: login?.data });
  } else yield put({ type: GET_LOGIN_FAIL, payload: login });
}
function* workSendMessages(action: any) {
  const value = action.payload;
  const MessageResponse: ResponseGenerator = yield call(sendMessagesApi, value);
}
function* workFetchMessages(action: any) {
  const value = action.payload;

  const MessageResponse: ResponseGenerator = yield call(
    fetchMessagesApi,
    value
  );

  if (MessageResponse.status === "OK") {
    yield put({ type: STORE_MESSAGES, payload: MessageResponse?.data.msg });
  }
}

function* submitSupportForm(action: any) {
  const value = action.payload;
  const feedback: ResponseGenerator = yield call(submitSupportFormAPI, value);
}
function* changePasswordInvestor(val: any) {
  const feedback: ResponseGenerator = yield call(
    changePasswordInvestorAPI,
    val
  );
}
function* changePasswordstartup(val: any) {
  const feedback: ResponseGenerator = yield call(changePasswordStartupAPI, val);
}
function* forgetPasswordstartup(val: any) {
  const feedback: ResponseGenerator = yield call(forgotPasswordStartupAPI, val);

  if (feedback?.message === "Request failed with status code 404") {
    yield put({ type: FORGET_PASSWORD_STARTUP, payload: feedback });
  }
}
function* forgetPasswordInvestor(val: any) {
  const feedback: ResponseGenerator = yield call(
    forgotPasswordInvestorAPI,
    val
  );

  if (feedback?.message === "Request failed with status code 404") {
    yield put({ type: FORGET_PASSWORD_INVESTOR, payload: feedback });
  }
}

function* workGetLoginFetchStartup(action: any) {
  const value = action.payload;
  const login: ResponseGenerator = yield call(loginApiStartup, value);
  if (login.status === "OK") {
    if (value.isChecked === true) {
      localStorage.setItem("profile", login?.tokenData);
      localStorage.setItem("type", login?.type);
      localStorage.setItem("id", login?.data.userId);
      sessionStorage.setItem("profile", login?.tokenData);
      sessionStorage.setItem("type", login?.type);
      sessionStorage.setItem("id", login?.data.userId);
    } else {
      localStorage.setItem("profile", login?.tokenData);
      localStorage.setItem("type", login?.type);
      localStorage.setItem("id", login?.data.userId);
      sessionStorage.setItem("profile", login?.tokenData);
      sessionStorage.setItem("type", login?.type);
      sessionStorage.setItem("id", login?.data.userId);
    }
    yield put({ type: GET_LOGIN_SUCCESS_STARTUP, payload: login?.data });
  } else yield put({ type: GET_LOGIN_FAIL_STARTUP, payload: login });
}

function* logoutHandler() {
  yield put({ type: LOGOUT_SUCCESS });
  localStorage.removeItem("profile");
  localStorage.removeItem("type");
  localStorage.removeItem("id");
  localStorage.removeItem("hasShownModal")
  sessionStorage.removeItem("profile");
  sessionStorage.removeItem("type");
  sessionStorage.removeItem("id");
}

function* logoutHandlerStartup() {
  yield put({ type: LOGOUT_SUCCESS_STARTUP });
  localStorage.removeItem("profile");
  localStorage.removeItem("type");
  localStorage.removeItem("id");
  localStorage.removeItem("hasShownModal")
  sessionStorage.removeItem("profile");
  sessionStorage.removeItem("type");
  sessionStorage.removeItem("id");
}
function registerApi(value: any) {
  return fetch("https://makenewindia.com/api/register?key=investor", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => {
      return error;
    });
}
function registerApiStartup(value: any) {
  return fetch(`${url}/register?key=startup`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
}

function* workGetRegisterFetch(action: any) {
  const value = action.payload;
  const register: ResponseGenerator = yield call(registerApi, value);
  if (register.status === "OK") {
    sessionStorage.setItem("profile", register?.tokenData);
    sessionStorage.setItem("type", register?.type.key);
    sessionStorage.setItem("id", register?.savedData.uId);
    localStorage.setItem("profile", register?.tokenData);
    localStorage.setItem("type", register?.type.key);
    localStorage.setItem("id", register?.savedData.uId);
    yield put({ type: GET_LOGIN_SUCCESS, payload: register?.savedData });
  } else yield put({ type: GET_REGISTER_FAIL, payload: register });
}
function* workGetRegisterFetchStartup(action: any) {
  const value = action.payload;
  const register: ResponseGenerator = yield call(registerApiStartup, value);

  if (register.status === "OK") {
    sessionStorage.setItem("profile", register?.tokenData);
    sessionStorage.setItem("type", register?.type);

    sessionStorage.setItem("id", register?.data.userId);
    localStorage.setItem("profile", register?.tokenData);
    localStorage.setItem("type", register?.type);

    localStorage.setItem("id", register?.data.userId);
    yield put({ type: GET_LOGIN_SUCCESS_STARTUP, payload: register?.data });
  } else yield put({ type: GET_REGISTER_FAIL_STARTUP, payload: register });
}

function* fetchProfile(val: any) {
  let data: ResponseGenerator = yield call(getUserProfile, val);
  let profileData = data?.data?.data;
  data = data?.data?.data;
  yield put({ type: GET_LOGIN_SUCCESS, payload: profileData[0] });
  yield put({ type: "GOTUSERPROFILE", data });
  yield put({ type: "ALL_STARTUP_CONNECTION", data });
}
function* fetchProfileRequestList(val: any) {
  let data: ResponseGenerator = yield call(getUserProfile, val);
  let profileData = data?.data?.data;
  data = data.data.data;
  yield put({ type: "ALL_STARTUP_REQUESTS", data });
}

function* workSendRequestToInvestor(val: any) {
  let feedback: ResponseGenerator = yield call(sendRequestToInvestorApi, val);
}
function* workAcceptRequestOfInvestor(val: any) {
  let feedback: ResponseGenerator = yield call(acceptRequestOfInvestorApi, val);
}
function* workAcceptRequestOfStartup(val: any) {
  let feedback: ResponseGenerator = yield call(acceptRequestOfStartupApi, val);
}
function* fetchProfileStartup(val: any) {
  let data: ResponseGenerator = yield call(getUserProfile, val);
  data = data.data.data;
  yield put({ type: "GOTSTARTUPPROFILE", data });
  yield put({ type: "RECENT_CHAT_INFO", data });
}
function* fetchStartupDetails(val: any) {
  let data: ResponseGenerator = yield call(fetchStartupDetailsAPI, val);

  if (data.code !== "ERR_BAD_REQUEST") {
    let profileData = data?.data?.data;
    yield put({ type: "GET_STARTUP_DETAILS_SUCCESS", payload: profileData[0] });
    yield put({
      type: "GET_PEOPLE_DETAILS_SUCCESS",
      payload: data?.data.data.peoples,
    });
    yield put({ type: "ALL_INVESTOR_CONNECTION", payload: profileData[0] });
  }
}
function* workGetPeople(val: any) {
  let data: ResponseGenerator = yield call(fetchStartupDetailsAPI, val);

  if (data.code !== "ERR_BAD_REQUEST") {
    const peopleData = data?.data?.data?.peoples;
    yield put({ type: "STORE_PEOPLE", payload: peopleData });
  }
}

function* fetchStartupRequestList(val: any) {
  let data: ResponseGenerator = yield call(fetchStartupDetailsAPI, val);
  let profileData = data.data.data;
  data = data?.data.data;
  yield put({ type: "ALL_INVESTOR_REQUESTS", payload: profileData[0] });
}
function* workFetchRecentChats(val: any) {
  let data: ResponseGenerator = yield call(fetchRecentChatsAPI, val);

  yield put({ type: "ALL_RECENT_CHATS", payload: data?.data });
}

function* fetchStartupDetailsFromInvestor(val: any) {
  let data: ResponseGenerator = yield call(fetchStartupDetailsAPI, val);
  if (data.code !== "ERR_BAD_REQUEST") {
    let profileData = data?.data?.data;
    yield put({ type: "RECENT_CHAT_INFO", data: profileData });

    yield put({
      type: "GET_STARTUP_DETAILS_SUCCESS_FROM_INVESTOR",
      payload: profileData[0],
    });
  }

  // yield put({ type: "RECENT_CHAT_INFO", profileData });
}

function* fetchRisingStartup() {
  let data: ResponseGenerator = yield call(getRisingStartup);
  yield put({ type: "TOP_RISING", data });
}
function* fetchRisingStartupS() {
  let data: ResponseGenerator = yield call(getRisingStartupS);
  yield put({ type: "TOP_RISINGS", data });
}
function* fetchGoogleHomepage() {
  let data: ResponseGenerator = yield call(getRisingStartup);
  yield put({ type: "GOOGLE_HOMEPAGE", data });
}

function* fetchBlog() {
  let data: ResponseGenerator = yield call(getBlog);
  yield put({ type: "GETBLOGS", data });
}

function* fetchrecomenderStartups() {
  let data: ResponseGenerator = yield call(getRecomendedStartup);

  yield put({ type: "GET_REC_STARTUP", data });
}
function* fetchAllStartups() {
  let data: ResponseGenerator = yield call(getAllStartup);

  yield put({ type: "GET_ALL_STARTUPS", data });
}

//startup -->
function* fetchInvestorStartup() {
  let data: ResponseGenerator = yield call(getInvestorProfileApi); //////
  yield put({ type: "GET_INVESTOR", data });
}
function* fetchProfileInv(val: any) {
  let data: ResponseGenerator = yield call(getInvProfile, val);
  data = data?.data?.data;
  yield put({ type: "GOTINVESTORPROFILE", data });
  yield put({ type: "INVESTOR_DATA_FROM_STARTUP", data });
}

// Main Root function

function* update_Profile(val: any) {
  let data: ResponseGenerator = yield call(InvestorUpdate, val);

  yield put({ type: "GET_LOGIN_SUCCESS", payload: data });
}
function* update_Startup_Profile(val: any) {
  let data: ResponseGenerator = yield call(startupUpdate, val);
  data = data?.data.data;
  yield put({ type: "GET_LOGIN_SUCCESS_STARTUP", payload: data });
}
//////---------------------//////////--------------------
function* update_Startup_Employee(val: any) {
  let data: ResponseGenerator = yield call(startupEmployee, val);
  data = data;
  yield put({ type: "GET_PEOPLE_SUCCESS", payload: data });
}
function* add_Startup_Employee(val: any) {
  let data: ResponseGenerator = yield call(addEmployee, val.payload);
  // data = data.data;

  yield put({ type: "GET_PEOPLE_SUCCESS", payload: data });
}
function* deleteStartupEmployee(val: any) {
  let data: ResponseGenerator = yield call(removeEmployee, val.payload);

  yield put({ type: "EMP_DELETE_SUCCESS", payload: data });
}

function* update_Employee(val: any) {

  let data: ResponseGenerator = yield call(updateEmployeeStartup, val);
  data = data.data;
  yield put({ type: "UPDATE_EMPLOYEE_SUCCESS", payload: data });
}
///-----------------------------------------------------
//Investor dashboard search
function* InvestorSearch(val: any) {
  let data: ResponseGenerator = yield call(getInvestorSearchData, val);
  // data = data.data;
  yield put({ type: "GET_SEARCH", data });
}

function* InvestorSearchS(val: any) {
  let data: ResponseGenerator = yield call(getStartupSearchDataNav, val);
  // data = data.data;

  yield put({ type: "GET_SEARCH_S", data });
}

function* InvestorSearchI(val: any) {
  let data: ResponseGenerator = yield call(getInvestorSearchDataNav, val);
  // data = data.data;
  yield put({ type: "GET_SEARCH_I", data });
}

function submitSupportFormAPI(value: any) {
  return fetch("https://makenewindia.com/api/addSupportForm", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
}
function* investorNotification(val: any) {
  let data: ResponseGenerator = yield call(getInvestorNotification, val);

  yield put({ type: "GET_INVESTOR_NOTIFICATION_R", data });
}
function* startUpNotification(val: any) {
  let data: ResponseGenerator = yield call(getStartupNotification, val);

  yield put({ type: "GET_STARTUP_NOTIFICATION_R", data });
}



export function* rootSaga() {
  yield takeLatest("UPDATE_PROFILE", update_Profile);
  yield takeLatest("UPDATE_STARTUP_PROFILE", update_Startup_Profile);
  yield takeLatest("ADD_EMPLOYEE", update_Startup_Employee);
  yield takeLatest("ADD_STARTUP_EMPLOYEE", add_Startup_Employee);
  yield takeEvery(GET_LOGIN_FETCH, workGetLoginFetch);
  yield takeEvery(SEND_MESSAGES, workSendMessages);
  yield takeEvery(FETCH_MESSAGES, workFetchMessages);
  yield takeEvery(GET_LOGIN_FETCH_STARTUP, workGetLoginFetchStartup);
  yield takeEvery(GET_REGISTER_FETCH, workGetRegisterFetch);
  yield takeEvery(GET_REGISTER_FETCH_STARTUP, workGetRegisterFetchStartup);
  yield takeEvery(LOGOUT_STARTUP, logoutHandlerStartup);
  yield takeEvery(LOGOUT, logoutHandler);
  yield takeLatest("UPDATE_PROFILE", update_Profile);
  yield takeEvery("GETRECOMENDEDSTARTUPS", fetchrecomenderStartups);
  yield takeEvery("GETALLSTARTUPS", fetchAllStartups);
  yield takeEvery("GETBLOG", fetchBlog);
  yield takeEvery("GOOGLEHOMEPAGEAPI", fetchGoogleHomepage);
  yield takeEvery("TOPRISING", fetchRisingStartup);
  yield takeEvery("TOPRISINGS", fetchRisingStartupS);
  yield takeEvery("GETPROFILE", fetchProfile);
  yield takeEvery("GET_PROFILE_REQUEST_LIST", fetchProfileRequestList);
  yield takeEvery("GET_STARTUP_REQUEST_LIST", fetchStartupRequestList);
  yield takeEvery("GETPROFILESTARTUP", fetchProfileStartup);
  yield takeEvery("GET_STARTUP_DETAILS", fetchStartupDetails);
  yield takeEvery(GET_INVESTOR_NOTIFICATION, investorNotification);
  yield takeEvery(GET_STARTUP_NOTIFICATION, startUpNotification);
  yield takeEvery(
    "GET_STARTUP_DETAILS_FROM_INVESTOR",
    fetchStartupDetailsFromInvestor
  );
  yield takeEvery("GETINVESTOR", fetchInvestorStartup);
  yield takeEvery("GETPROFILEINV", fetchProfileInv);
  yield takeEvery("GETSEARCH", InvestorSearch);
  yield takeEvery(SUBMIT_SUPPORT_FORM, submitSupportForm);
  yield takeEvery(CHANGE_PASSWORD_INVESTOR, changePasswordInvestor);
  yield takeEvery(CHANGE_PASSWORD_STARTUP, changePasswordstartup);
  yield takeEvery(FORGET_PASSWORD_STARTUP, forgetPasswordstartup);
  yield takeEvery(FORGET_PASSWORD_INVESTOR, forgetPasswordInvestor);
  yield takeEvery(GETSEARCH_S, InvestorSearchS);
  yield takeEvery(GETSEARCH_I, InvestorSearchI);
  yield takeEvery(DELETE_EMPLOYEE, deleteStartupEmployee);
  yield takeEvery(UPDATE_EMPLOYEE, update_Employee);
  yield takeEvery(SEND_REQUEST_TO_INVESTOR, workSendRequestToInvestor);
  yield takeEvery(ACCEPT_REQUEST_OF_INVESTOR, workAcceptRequestOfInvestor);
  yield takeEvery(ACCEPT_REQUEST_OF_STARTUP, workAcceptRequestOfStartup);
  yield takeEvery("FETCH_RECENT_CHATS", workFetchRecentChats);
  yield takeEvery("GET_PEOPLE", workGetPeople);
  // yield takeEvery(UPDATE_NETWORK_REDIRECT, updateNetwork);
}
