import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { apiClient } from "../../Api/api";
import PricingCard from "./PricingCard";

const PricingInvUpdated = () => {
  const [investorPrice, setInvestorPrice] = useState<any>();
  const details = useSelector((state: any) => {
    return state.loginReducerHandler?.login[0];
  });


  useEffect(() => {
    axios.get(`${apiClient}/investorplan`).then((res: any) => {
      // setData(res?.data.data);

      setInvestorPrice(res?.data?.data[0].price);
    });
  }, []);

  return (
    <Box display={"flex"} justifyContent={"center"} gap={10} flexDirection={{xs:"column", sm:"row"}}>

      <PricingCard
        planName={"basic"}
        btnName = {details?.subscription === "basic" ? "Current Plan" : "Downgrade"}
        price={"FREE"}
        headline={"All Basic Features"}
        email = {details?.email}
      />
      <PricingCard
        planName={"premium"}
        // price={`INR 1222/month`}
        btnName = {details?.subscription === "premium" ? "Current Plan" : "Upgrade"}
        price={`INR ${investorPrice === undefined ? "Loading.." : investorPrice}/month`}
        headline={"All Premium Features"}
        email = {details?.email}
      />
    </Box>
  );
};

export default PricingInvUpdated;
