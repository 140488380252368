import React from "react";
import "./Slider.css";
import { Typography } from "@mui/material";
// import GoogleIcon from "@mui/icons-material/Google";
import { Box, Container } from "@mui/system";
import googleLogo from "./img/googleLogo.jpg";
import { useSelector } from "react-redux";

interface cardData {
  cardData?: any;
  ele?: any;
  data?: any;
}

function numDifferentiation(val: any) {
  if (val >= 10000000) val = (val / 10000000).toFixed(2) + " Cr";
  else if (val >= 100000) val = (val / 100000).toFixed(2) + " Lac";
  else if (val >= 1000) val = (val / 1000).toFixed(2) + " K";
  return val;
}

export const SliderContent = (props: cardData) => {
  let { ele } = props;

  const handleImgError = (e: any) => {
    e.target.src = googleLogo;
  };
  return (
    <Container
      className="sliderDiv"
      sx={{ backgroundColor: "#EFF3F3", padding: "10%" }}
    >
      <Box
        className="google-slider-box"
        sx={{ borderRight: "1px solid #010101A8" }}
      >
        <Typography
          variant="h3"
          className="googleName"
          sx={{
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
          }}
        >
          <Box
            component="img"
            alt=""
            className="googleIcon"
            onError={handleImgError}
            src={ele?.profilePicture ? ele?.profilePicture : googleLogo}
            sx={{
              width: "25px",
              height: "25px",
              marginRight: "15px",
              borderRadius: "50%",
            }}
          />
          {ele?.companyName?.substr(0, 10)}
        </Typography>

        <Box display="flex" gap="5%" sx={{ mb: 2 }}>
          <Typography
            variant="body2"
            className="googleInner"
            sx={{
              backgroundColor: "#fff",
              borderRadius: "5px",
              opacity: "60%",
            }}
          >
            {ele?.industry?.substr(0, 10)}
          </Typography>
          <Typography
            variant="body2"
            className="googleInner"
            sx={{
              fontSize: "14px",
              backgroundColor: "#fff",
              padding: "5px",
              borderRadius: "5px",
              opacity: "60%",
            }}
          >
            {ele?.location?.substr(0, 8)}
          </Typography>
        </Box>

        <Box display="flex" gap="17%">
          <Typography
            variant="body2"
            sx={{
              opacity: "60%",
              fontSize: "12px",
            }}
          >
            Equity Offer
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontSize: "14px",
              color: "#138707",
              fontWeight: "bold",
            }}
          >
            {ele?.equityOffer}%
          </Typography>
        </Box>
        <Box display="flex" gap="20%">
          <Typography
            variant="body2"
            sx={{
              opacity: "60%",
              fontSize: "12px",
            }}
          >
            Ask Price
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontSize: "14px",
              color: "#138707",
              fontWeight: "bold",
            }}
          >
            {/* {ele?.askPrice
              ?.toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                style: "currency",
                currency: "INR",
              })
              .substr(0, 12)} */}
            {ele?.askPrice >= 10000000
              ? "₹" + (ele?.askPrice / 10000000).toFixed(2) + " Cr"
              : ele?.askPrice >= 100000
              ? "₹" + (ele?.askPrice / 100000).toFixed(2) + " L"
              : "₹" + ele?.askPrice}
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};
