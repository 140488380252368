import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import SideBar from "../../../SideBar";
import CompanyDescription from "../CompanyDescription";
import picture from "./img/teamPH.png";
import "./people.css";

const People = () => {
  // const startUpData: any = useSelector<any>(
  //   (state) => state.startupReducer?.login[0]
  // );
  const startUpData: any = useSelector<any>(
    (state) => state.startupReducer?.peopleArray
  );

  return (
    <>
      <Grid container pb={"2rem"} className="people-main-container" rowGap={{ xs: 5 }}>
        {startUpData?.length === 0 ? (
          <Box pb={"10rem"}>

            <h1>No Employee Found</h1>
          </Box>
        ) : (
          startUpData?.map((data: any, index: any) => (
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              className="people-box bg-grey"
              key={index}
            >
              <Box className="align">
                <img src={picture} className="people-width" />
              </Box>
              <Box className="people-text">
                <Typography variant="caption">{data?.position}</Typography>
                <Typography variant="h5" className="bold" gutterBottom>
                  {data?.name}
                </Typography>
              </Box>
            </Grid>
          ))
        )}
      </Grid>
    </>
  );
};

export default People;
