import {
  GET_LOGIN_SUCCESS_STARTUP,
  GET_LOGIN_FAIL_STARTUP,
  LOGOUT_SUCCESS_STARTUP,
  RECENTLY_VIEWED,
  GET_STARTUP_DETAILS_SUCCESS,
  GET_PEOPLE_SUCCESS,
  GET_PEOPLE_DETAILS_SUCCESS,
  EMP_DELETE_SUCCESS,
  UPDATE_EMPLOYEE_SUCCESS,
  FORGET_PASSWORD_STARTUP,
  ALL_STARTUP_CONNECTION,
} from "./actions";

const initialState: any = {
  login: [],
  people: [],
  peopleArray: [],
  error: null,
  isLoggedIn: false,
  token: localStorage.getItem("token"), // not in use
  type: "",
  investorProfile: [],
  inProfile: {},
  recentlyViewed: [],
  topRisingDataS: [],
  startupNotification: [],
  investorNotification: [],
  search: [],
  investorData: [],
  connectionData: [],
  requests: [],
  errorEmail: {},

};

const startupReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_LOGIN_SUCCESS_STARTUP:
      return {
        ...state,
        login: [action.payload],
        isLoggedIn: true,
        error: null,
        type: "startup",
      };
    case GET_PEOPLE_SUCCESS:
      const newObj = {
        name: action?.payload?.data?.name,
        position: action?.payload?.data?.position,
      };
      return {
        ...state,
        peopleArray: [...state.peopleArray, newObj],
        isLoggedIn: true,
        error: null,
        type: "startup",
      };
    case GET_STARTUP_DETAILS_SUCCESS:
      return {
        ...state,
        login: [action.payload],
        isLoggedIn: true,
        error: null,
        type: "startup",
      };
    case UPDATE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        people: [action.payload],
        isLoggedIn: true,
        error: null,
        type: "startup",
      };
    case GET_PEOPLE_DETAILS_SUCCESS:
      return {
        ...state,
        peopleArray: [...action.payload],
      };
    case EMP_DELETE_SUCCESS:
      return {
        ...state,
        people: [action.payload],
      };
    case GET_LOGIN_FAIL_STARTUP:
      return {
        ...state,
        isLoggedIn: false,
        error: action.payload.message.toUpperCase(),
        type: "",
      };
    case LOGOUT_SUCCESS_STARTUP:
      localStorage.removeItem("profile");
      localStorage.removeItem("type");
      localStorage.removeItem("id");
      sessionStorage.removeItem("profile");
      sessionStorage.removeItem("type");
      sessionStorage.removeItem("id");
      return {
        ...state,
        login: [],
        isLoggedIn: false,
        type: "",
      };
    case "GET_INVESTOR": {
      return { ...state, investorProfile: action?.data?.data.data };
    }
    case "GOTINVESTORPROFILE": {
      return { ...state, inProfile: action?.data?.data };
    }
    case "INVESTOR_DATA_FROM_STARTUP": {
      return { ...state, investorData: action?.data };
    }
    case "ALL_STARTUP_CONNECTION": {
      return {
        ...state,
        connectionData: [...state.connectionData, ...action?.data],
      };
    }
    case "ALL_STARTUP_REQUESTS": {
      return {
        ...state,
        requests: [...state.requests, ...action?.data],
      };
    }
    case "GETPROFILEINV": {
      return { ...state, getProfileData: action.payload };
    }
    case "TOP_RISINGS": {
      return { ...state, topRisingDataS: action?.data };
    }
    case "GET_SEARCH_S": {
      return { ...state, search: action?.data };
    }
    case "GET_INVESTOR_NOTIFICATION_R": {
      return { ...state, investorNotification: action?.data?.data };
    }
    case "GET_STARTUP_NOTIFICATION_R": {
      return { ...state, startupNotification: action?.data?.data };
    }
    case FORGET_PASSWORD_STARTUP: {
      return { ...state, errorEmail: action.payload };
    }

    case RECENTLY_VIEWED: {
      let flag: any = false;
      if (state.recentlyViewed.length == 0) {
        return {
          ...state,
          recentlyViewed: [action.payload, ...state.recentlyViewed],
        };
      } else {
        for (let i: any = 0; i < state.recentlyViewed.length; i++) {
          if (state.recentlyViewed[i]._id == action.payload._id) {
            break;
          } else if (
            state.recentlyViewed[i]._id != action.payload._id &&
            i == state.recentlyViewed.length - 1
          ) {
            flag = true;
          }
        }
        if (flag) {
          return {
            ...state,
            recentlyViewed: [action.payload, ...state.recentlyViewed],
          };
        } else {
          return { ...state, recentlyViewed: state.recentlyViewed };
        }
      }
    }
    default:
      return state;
  }
};

export default startupReducer;
