import Routes from "../Routes/Routes";
import React, { useEffect, useState, createContext } from "react";
import Footer from "../Footer";
import { Navbar } from "../Navbar-Homepage/Navbar";
import { Navbar as Navbar_PostLogin } from "../Navbar-PostLogin/Navbar";
import InvestorRegister from "../InvestorRegister/InvestorRegister";
import TopStartUpPage from "../TopStartUpPage/TopStartUpPage";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import ChatButton from "../ChatComponent/ChatButton";
import toast, { Toaster } from "react-hot-toast";
// import { NotificationContextState } from "./types";
import {
  getInvestorNotification,
  getStartupNotification,
} from "../../store/startup/actions";
import { useDispatch } from "react-redux";
type UserContextType = {
  isNotification: string | null;
  setNotification: React.Dispatch<React.SetStateAction<string | null>>;
};

const iUserContextState = {
  isNotification: null,
  setNotification: () => {},
};

export const NotificationContext =
  createContext<UserContextType>(iUserContextState);

const MainLayout = () => {
  const dispatch = useDispatch();
  let location = useLocation();
  let isInvestorLoggedIn = useSelector<any>(
    (state) => state.loginReducerHandler.isLoggedIn
  );
  let isStartupLoggedIn = useSelector<any>(
    (state) => state.startupReducer.isLoggedIn
  );

  const [finalInvestorLogin, setFinalInvestorLogin] = useState(false);
  const [finalStartupLogin, setFinalStartupLogin] = useState(false);
  const [isNotification, setNotification] = useState<string | null>(null);

  let navigate = useNavigate();

  useEffect(() => {
    if (
      localStorage.getItem("profile") &&
      localStorage.getItem("type") === "investor"
      // || (sessionStorage.getItem("profile") &&
      // sessionStorage.getItem("type") === "investor")
    ) {
      setFinalInvestorLogin(true);
      navigate("/dashboard", { replace: true });
      // welcomeUser("Investor");
    } else {
      setFinalInvestorLogin(false);
    }
    if (
      localStorage.getItem("profile") &&
      localStorage.getItem("type") === "startup"
      // || (sessionStorage.getItem("profile") &&
      // sessionStorage.getItem("type") === "startup")
    ) {
      setFinalStartupLogin(true);
      navigate("/startup-dashboard", { replace: true });
      // welcomeUser("Startup");
    } else {
      setFinalStartupLogin(false);
    }
  }, []);
  useEffect(() => {
    if (
      localStorage.getItem("id") &&
      localStorage.getItem("type") === "investor"
    ) {
      dispatch(getInvestorNotification(localStorage.getItem("id")));
    }
    if (
      localStorage.getItem("id") &&
      localStorage.getItem("type") === "startup"
    ) {
      dispatch(getStartupNotification(localStorage.getItem("id")));
    }
  }, [isNotification]);

  const welcomeUser = (userName: any, toastHandler = toast) => {
    toastHandler.success(`Welcome ${userName}!`, {
      style: {
        background: "#04111d",
        color: "#fff",
      },
    });
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <NotificationContext.Provider value={{ isNotification, setNotification }}>
        {finalInvestorLogin ||
        finalStartupLogin ||
        isInvestorLoggedIn ||
        isStartupLoggedIn ? (
          <>
            <Navbar_PostLogin />
          </>
        ) : (
          <Navbar />
        )}

        <Box sx={{ mt: "100px" }} />
        <Routes />
        {(finalInvestorLogin ||
          finalStartupLogin ||
          isInvestorLoggedIn ||
          isStartupLoggedIn) &&
        location.pathname !== "/chat" &&
        location.pathname !== "/pricing-startup" &&
        location.pathname !== "/pricing-investor" ? (
          <>
            <ChatButton />
          </>
        ) : null}
        {/* <TopStartUpPage/> */}
      </NotificationContext.Provider>
    </>
  );
};

export default MainLayout;
