import { Box, Button, Container, Grid, Typography } from "@mui/material";
import Reac,{useState} from "react";
import bannerimg1 from "../../Assets/image/bannerimg1.svg";
import bannerimg2 from "../../Assets/image/bannerimg2.svg";
import bannerimg3 from "../../Assets/image/bannerimg3.svg";
import bannerimg4 from "../../Assets/image/bannerimg4.svg";
import greencircle from "../../Assets/image/greencircle.svg";

import { makeStyles } from "@material-ui/core/styles";
import "./featuresBanner.css";
import PremiumPop from "../PremiumPopUp/PremiumPop";

const useStyles = makeStyles((theme) => ({
  containerWithCustomPadding: {
    paddingTop: "40px",
    paddingBottom: "50px", 
    paddingLeft: "50px", 
    paddingRight: "30px",
    marginTop: "50px",
  },
}));
const FeatureBanner = () => {

  
  const classes = useStyles();
  return (
    <Container className="max-width" sx={{ p: { xs: "0px", sm: "0px" } }}>
  
      <Box sx={{ backgroundColor: "#EFF3F3" }}>
        <Grid container>
          <Grid lg={5} md={6} sm={12}>
            <Box className={classes.containerWithCustomPadding}>
              <Typography className="heading2 underline-text">How does it work?</Typography>
              <Box className="para">
                <Typography variant="body1" component="p">
                  MakeNewIndia oﬀers a unique platform which is intended to
                  serve as a medium to promote business opportunity for our
                  users (Startups and Investors) by enabling you and millions of
                  other business minds to meet, exchange ideas, learn, and find
                  investment or expansion opportunities, and make decisions in a
                  network of trusted relationships
                </Typography>
              </Box>

              <Box sx={{ marginTop: "50px" }}>
                <Box className="listSection">
                  <Box sx={{ display: "inline-grid" }}>
                    <img src={greencircle} width="30px" alt="greencircle" />
                    <Box className="greenLine"></Box>
                  </Box>
                  <Typography
                    sx={{ marginLeft: "10px" }}
                    fontWeight="bold"
                    variant="body1"
                    component="p"
                  >
                    Create a free account.
                  </Typography>
                </Box>
                <Box className="listSection">
                  <Box sx={{ display: "inline-grid" }}>
                    <img src={greencircle} width="30px" alt="greencircle" />
                    <Box className="greenLine"></Box>
                  </Box>
                  <Typography
                    sx={{ marginLeft: "10px" }}
                    fontWeight="bold"
                    variant="body1"
                    component="p"
                  >
                    Post your profile.
                  </Typography>
                </Box>
                <Box className="listSection">
                  <Box sx={{ display: "inline-grid" }}>
                    <img src={greencircle} width="30px" alt="greencircle" />
                    <Box className="greenLine"></Box>
                  </Box>
                  <Typography
                    sx={{ marginLeft: "10px" }}
                    fontWeight="bold"
                    variant="body1"
                    component="p"
                  >
                    Explore business opportunities.
                  </Typography>
                </Box>
                <Box className="listSection">
                  <Box sx={{ display: "inline-grid" }}>
                    <img src={greencircle} width="30px" alt="greencircle" />
                  </Box>
                  <Typography
                    sx={{ marginLeft: "10px" }}
                    fontWeight="bold"
                    variant="body1"
                    component="p"
                  >
                    Connect with like-minded people.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid lg={7} md={6} sm={12}>
            <Box className="d-flex justify-content-center imgalign">
              <img src={bannerimg1} alt="bannerimg1" width="100%" />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ backgroundColor: "#ffffff" }}>
        <Grid container>
          <Grid lg={7} md={6} sm={12}>
            <Box className="d-flex justify-content-center imgalign">
              <img src={bannerimg2} alt="bannerimg2" width="100%" />
            </Box>
          </Grid>
          <Grid lg={5} md={6} sm={12}>
            <Box className={classes.containerWithCustomPadding}>
              <Typography className="heading2">
                Startup Networking
                <br /> <span className="underline-text">Features</span>
              </Typography>

              <Box sx={{ marginTop: "50px" }}>
                <Box className="listSection">
                  <Box sx={{ display: "inline-grid" }}>
                    <img src={greencircle} width="30px" alt="greencircle" />
                    <Box className="greenLine"></Box>
                  </Box>
                  <Typography
                    sx={{ marginLeft: "10px" }}
                    fontWeight="bold"
                    variant="body1"
                    component="p"
                  >
                    Explore available Investors.
                  </Typography>
                </Box>
                <Box className="listSection">
                  <Box sx={{ display: "inline-grid" }}>
                    <img src={greencircle} width="30px" alt="greencircle" />
                    <Box className="greenLine"></Box>
                  </Box>
                  <Typography
                    sx={{ marginLeft: "10px" }}
                    fontWeight="bold"
                    variant="body1"
                    component="p"
                  >
                    Short-list by Industry and geographic location.
                  </Typography>
                </Box>
                <Box className="listSection">
                  <Box sx={{ display: "inline-grid" }}>
                    <img src={greencircle} width="30px" alt="greencircle" />
                    <Box className="greenLine"></Box>
                  </Box>
                  <Typography
                    sx={{ marginLeft: "10px" }}
                    fontWeight="bold"
                    variant="body1"
                    component="p"
                  >
                    Connect with motivated Investors.
                  </Typography>
                </Box>
                <Box className="listSection">
                  <Box sx={{ display: "inline-grid" }}>
                    <img src={greencircle} width="30px" alt="greencircle" />
                  </Box>
                  <Typography
                    sx={{ marginLeft: "10px" }}
                    fontWeight="bold"
                    variant="body1"
                    component="p"
                  >
                    Increase your chances of being funded.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ backgroundColor: "#EFF3F3" }}>
        <Grid container>
          <Grid lg={5} md={6} sm={12}>
            <Box className={classes.containerWithCustomPadding}>
              <Typography className="heading2">
                Investor Networking <br /><span className="underline-text">Features</span>
              </Typography>

              <Box sx={{ marginTop: "50px" }}>
                <Box className="listSection">
                  <Box sx={{ display: "inline-grid" }}>
                    <img src={greencircle} width="30px" alt="greencircle" />
                    <Box className="greenLine"></Box>
                  </Box>
                  <Typography
                    sx={{ marginLeft: "10px" }}
                    fontWeight="bold"
                    variant="body1"
                    component="p"
                  >
                    Discover Start-up Investment opportunities.
                  </Typography>
                </Box>
                <Box className="listSection">
                  <Box sx={{ display: "inline-grid" }}>
                    <img src={greencircle} width="30px" alt="greencircle" />
                    <Box className="greenLine"></Box>
                  </Box>
                  <Typography
                    sx={{ marginLeft: "10px" }}
                    fontWeight="bold"
                    variant="body1"
                    component="p"
                  >
                    Shortlist by industry and geographic location.
                  </Typography>
                </Box>
                <Box className="listSection">
                  <Box sx={{ display: "inline-grid" }}>
                    <img src={greencircle} width="30px" alt="greencircle" />
                    <Box className="greenLine"></Box>
                  </Box>
                  <Typography
                    sx={{ marginLeft: "10px" }}
                    fontWeight="bold"
                    variant="body1"
                    component="p"
                  >
                    Connect with talented founders.
                  </Typography>
                </Box>
                <Box className="listSection">
                  <Box sx={{ display: "inline-grid" }}>
                    <img src={greencircle} width="30px" alt="greencircle" />
                  </Box>
                  <Typography
                    sx={{ marginLeft: "10px" }}
                    fontWeight="bold"
                    variant="body1"
                    component="p"
                  >
                    Fund the next Unicorn.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid lg={7} md={6} sm={12}>
            <Box className="d-flex justify-content-center imgalign">
              <img src={bannerimg3} alt="bannerimg1" width="100%" />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ backgroundColor: "#ffffff" }}>
        <Grid container>
          <Grid lg={7} md={6} sm={12}>
            <Box className="d-flex justify-content-center imgalign">
              <img src={bannerimg4} alt="bannerimg4" width="100%" />
            </Box>
          </Grid>
          <Grid lg={5} md={6} sm={12}>
            <Box className={classes.containerWithCustomPadding}>
              <Typography className="heading2 underline-text">Protection Practices</Typography>

              <Box sx={{ marginTop: "50px" }}>
                <Box className="listSection">
                  <Box sx={{ display: "inline-grid" }}>
                    <img src={greencircle} width="30px" alt="greencircle" />
                    <Box className="greenLine"></Box>
                  </Box>
                  <Typography
                    sx={{ marginLeft: "10px" }}
                    fontWeight="bold"
                    variant="body1"
                    component="p"
                  >
                    Aggressive anti-fraud &amp; anti-bot policies
                  </Typography>
                </Box>
                <Box className="listSection">
                  <Box sx={{ display: "inline-grid" }}>
                    <img src={greencircle} width="30px" alt="greencircle" />
                    <Box className="greenLine"></Box>
                  </Box>
                  <Typography
                    sx={{ marginLeft: "10px" }}
                    fontWeight="bold"
                    variant="body1"
                    component="p"
                  >
                    Verification required on new profiles within 24 hours.
                  </Typography>
                </Box>
                <Box className="listSection">
                  <Box sx={{ display: "inline-grid" }}>
                    <img src={greencircle} width="30px" alt="greencircle" />
                    <Box className="greenLine"></Box>
                  </Box>
                  <Typography
                    sx={{ marginLeft: "10px" }}
                    fontWeight="bold"
                    variant="body1"
                    component="p"
                  >
                    A “request to connect” process maintains Investor privacy.
                  </Typography>
                </Box>
                <Box className="listSection">
                  <Box sx={{ display: "inline-grid" }}>
                    <img src={greencircle} width="30px" alt="greencircle" />
                  </Box>
                  <Typography
                    sx={{ marginLeft: "10px" }}
                    fontWeight="bold"
                    variant="body1"
                    component="p"
                  >
                    Strict non-disclosure policies protect Start-up data.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default FeatureBanner;
