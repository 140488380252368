import React, { useEffect, useState } from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  Button,
  Box,
  CardActions,
  Avatar,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

// import Carousel from 'react-elastic-carousel';

import "./StartupCard.css";
import { Data } from "./CardDataArray";

// import Carousel from "react-multi-carousel";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import {
  saveChoosenData,
  saveRecentlyViewed,
} from "../../../../../store/login/actions";
import Spinner from "../../../../../Extras/Spinner";
import companyBydefault from "../../../../../Assets/image/companyBydefault.webp";
import ChatButton from "../../../../ChatComponent/ChatButton";
import { toast } from "react-hot-toast";

const responsive = {
  desktopXxl: {
    breakpoint: { max: 2600, min: 2000 },
    items: 5,
  },
  desktopXl: {
    breakpoint: { max: 2000, min: 1536 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1536, min: 900 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1200, min: 900 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 900, min: 600 },
    items: 1,
  },
  mobilenew: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
  },
};

interface cardData {
  cardData?: any;
  ele?: any;
}

const Recomended = () => {
  const [isLoading, setIsLoading] = useState(false);
  const compdata = useSelector((state: any) => {
    return state.loginReducerHandler.recomendedStartups;
  });



  useEffect(() => {
    if (compdata === undefined) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [compdata]);

  if (isLoading) {
    return (
      <div style={{ height: "70vh" }}>
        <Spinner message="Fetching the Latest & Updated data for you, please wait..." />
      </div>
    );
  }
  return (
    <>
      <Carousel
        swipeable={false}
        draggable={false}
        showDots={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlaySpeed={1000}
        keyBoardControl={true}
        arrows={true}
        containerClass="carousel-container"
        itemClass="carousel-item-padding-40-px"
      >
        {compdata.map((ele: any) => (
          <Box className="card-container">
            <StartupCard1 ele={ele} key={ele.id} />
          </Box>
        ))}
      </Carousel>
    </>
  );
};
export const StartupCard1 = (props: cardData) => {
  let { ele } = props;

  // const [active, setActive] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  // const [count, setCount] = useState<any>();

  const handelRoute = () => {
    dispatch(saveRecentlyViewed(ele));
    dispatch(saveChoosenData(ele));
    navigate("/company-profile");
  };
  const handleImgError = (e: any) => {
    e.target.src = companyBydefault;
  };

  useEffect(() => {
    if (ele === undefined) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [ele]);

  if (isLoading) {
    return (
      <div style={{ height: "70vh" }}>
        <Spinner message="Fetching the Latest & Updated data for you, please wait..." />
      </div>
    );
  }
  const handleRedirect = (e: any) => {
    // alert("clicked")
    e.preventDefault();
    navigate("/chat", { state: ele.userId });
  };

  return (
    <Grid
      sx={{ display: "flex", justifyContent: "center", width: "100%" }}
      className="cardOuterGrid"
    >
      <Card
        id="startup-card"
        sx={{
          marginBottom: "10px",
          position: "relative",
        }}
        style={{ cursor: "pointer" }}
        // onClick={() => {
        //   handelRoute();
        // }}
      >
        <Box onClick={() => handelRoute()}>
          <Box>
            <img
              onError={handleImgError}
              src={ele?.thumbnail ? ele?.thumbnail : companyBydefault}
              alt="img"
              className="companyCardImg"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              columnGap: "10px",
              mt: 2,
              ml: 2,
            }}
          >
            <img
              onError={handleImgError}
              src={ele?.profilePicture ? ele?.profilePicture : Data[0].logoImg}
              alt="test"
              className="logo"
            ></img>
            {/* {props.cardData.membership && (
            <Typography
            className="memberShip"
              sx={{
                position: "absolute",
                backgroundColor: "#101010",
                color: "#FFF",
                borderRadius: "30px 0px 0px 30px",
                width: "fit-content",
              }}
            >
              {Data[0].membership}
            </Typography>
          )} */}

            <Typography  variant="h4" id="company-name">
              {ele?.companyName?.substr(0,15)}
            </Typography>
          </Box>
          {ele.subscription && (
            <Typography
              className="memberShipInvestor"
              sx={{
                position: "absolute",
                backgroundColor: "#101010",
                color: "#FFF",
                borderRadius: "30px 0px 0px 30px",
                width: "fit-content",
              }}
            >
              {ele.subscription}
            </Typography>
          )}
        </Box>
        <CardContent  onClick={() => handelRoute()}  sx={{ display: "flex" }} className="grey-btn">
          <Typography id="industry">{ele.industry?.substr(0,15)}</Typography>
          <Typography id="industry">{ele.location?.substr(0,15)}</Typography>
        </CardContent>
        <CardContent onClick={() => handelRoute()}>
          <Typography
            // sx={{ ml: "16px", pt: "-20px", mb: "-10px" }}
            id="industry-content"
            
          >
            {ele?.companyInfo?.substr(0, 40) + " ..."
              ? ele.companyInfo?.substr(0, 40) + " ..."
              : "..."}
          </Typography>
        </CardContent>
        <CardContent >
          <Grid id="" onClick={() => handelRoute()} container sx={{ ml: { sm: 2 }, mb: "2px" }}>
            <Grid item xs={4}>
              <Typography className="ques">Equity Offer</Typography>
              <Typography className="ans">{ele.equityOffer}%</Typography>
            </Grid>
            <Grid item xs={4} id="">
              <Typography className="ques">Ask Price</Typography>
              <Typography className="ans">
                {/* {ele?.askPrice?.toLocaleString("en-IN", {
                  maximumFractionDigits: 2,
                  style: "currency",
                  currency: "INR",
                })} */}
                {ele?.askPrice >= 10000000
                  ? "₹" + (ele?.askPrice / 10000000).toFixed(2) + " Cr"
                  : ele?.askPrice >= 100000
                  ? "₹" + (ele?.askPrice / 100000).toFixed(2) + " L"
                  : "₹" + ele?.askPrice}
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              id=""
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              onClick={() => handelRoute()}
            >
              <Typography className="ques">FOUNDED</Typography>
              <Typography sx={{ fontSize: "24px" }} className="ans">
                {ele.founded}
              </Typography>
            </Grid>
          </Grid>
          <CardActions>
          <Button
            onClick={handleRedirect}
            variant="contained"
            id="chat-btn"
            color="success"
            
          >
            Chat
          </Button>
        </CardActions>
        </CardContent>
        {/* </Box> */}
        
      </Card>
    </Grid>
  );
};
export default Recomended;
