import React, { useContext } from "react";
import { Box, Button, Grid, Modal, Popper, Typography } from "@mui/material";
import googleround from "./img/googleround.png";
import "./requestcard.css";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import NoteCard from "../NoteCard";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  acceptRequestOfStartup,
  getStartupForRequestList,
} from "../../../../store/login/actions";
import { getInvestorNotification } from "../../../../store/startup/actions";
import { readNotificationInvestor } from "../../../../Api/api";
import { NotificationContext } from "../../../MainLayout/index";
import companyBydefault from "../../../../Assets/image/companyBydefault.webp";
import { investorPremiumFeatures } from "../../../../FeaturesJSON/features";
import { toast } from "react-hot-toast";
const style = {
  position: "absolute" as "absolute",
  top: "70%",
  left: "30%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "1px solid #7070702E",
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
};
const RequestCard = ({ startupId, note, userNetworkList, investorSubscription }: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getStartupForRequestList(startupId));
  }, []);

  const requestInformation = useSelector((state: any) => {
    return state.loginReducerHandler?.requests;
  });

  const requestSet = new Set();
  const uniqueRequests = requestInformation.filter((data: { userId: any }) => {
    if (requestSet.has(data.userId)) {
      return false;
    }
    requestSet.add(data.userId);
    return true;
  });

  const investorData = uniqueRequests.find(
    (element: any) => element.userId === startupId
  );

  const InvestorNotification = useSelector((state: any) => {
    return state?.startupReducer.investorNotification;
  });
  const InvestorMsgId = InvestorNotification.find((element: any) => {
    return element.userId === startupId;
  });

  // const requestNote = uniqueRequests.find(
  //   (element: any) => element.userId === startupId
  // )
  const [requestStatusA, setRequestStatusA] = useState("Accept");
  const [requestStatusR, setRequestStatusR] = useState("Reject");
  const [changeColor, setChangeColor] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { isNotification, setNotification } = useContext(NotificationContext);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const investorId = useSelector((state: any) => {
    return state.loginReducerHandler?.login[0]?.uId;
  });
 

  const handleBuyPremium = () => {
    navigate("/pricing-investor");
    toast("Please buy premium to connect with more investors");
  };
  const accpetOnClick = () => {
    acceptHandler();
    setNotification("requestCard");
  };

  const acceptHandler = () => {
    let obj = {
      startupId,
      investorId,
      status: "accept",
    };
    dispatch(acceptRequestOfStartup(obj));
    setRequestStatusA("Accepted");
    setRequestStatusR("");
    setChangeColor(true);
    if (
      localStorage.getItem("id") &&
      localStorage.getItem("type") === "investor"
      // || (sessionStorage.getItem("id") &&
      // sessionStorage.getItem("type") === "investor")
    ) {
      const data = readNotificationInvestor(
        localStorage.getItem("id"),
        InvestorMsgId.uId
      );
      data.then((res: any) => {
        if (res.status === "OK") {
          dispatch(getInvestorNotification(localStorage.getItem("id")));
          navigate("/chat", { state: InvestorMsgId.userId });
          setNotification("delete");
        }
      });
    }
  };
  const rejectHandler = () => {
    let obj = {
      startupId,
      investorId,
      status: "reject",
    };

    dispatch(acceptRequestOfStartup(obj)); // same is also used for rejecting with status = reject
    setRequestStatusA("");
    setRequestStatusR("Rejected");
    setChangeColor(true);
  };
  const handleImgError = (e: any) => {
    e.target.src = companyBydefault;
  };

  return (
    <Grid
      container
      mt={2}
      p={2}
      className="bg-white"
      sx={{ boxShadow: 3, borderRadius: 5 }}
    >
      <Grid item xs={2} sm={2} md={2} xl={2} lg={2}>
        <Box
          sx={{ height: "80px", width: "80px", objectFit: "cover" }}
          component="img"
          onError={handleImgError}
          src={
            investorData?.profilePicture
              ? investorData?.profilePicture
              : googleround
          }
          className="max-width img-box"
        />
      </Grid>
      <Grid item xs={6} className="company-desc-container">
        <Box sx={{ p: "10px" }}>
          <Typography className="size-s bold request-company-name" gutterBottom>
            {investorData?.companyName}
          </Typography>
          <Typography
            variant="body1"
            className="size-xs book grey request-company-location"
            gutterBottom
          >
            {investorData?.location}
          </Typography>

          <Typography
            variant="body1"
            className="size-xs bold  green open-note-align"
            component="div"
            onClick={handleClick}
          >
            Open Note <KeyboardArrowRightIcon />
          </Typography>
          <Popper
            id={anchorEl ? "simple-popper" : undefined}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            placement="bottom-end"
          >
            <Box sx={style}>
              <Typography
                variant="h6"
                component="div"
                className="note-card-container"
              >
                <ClearIcon
                  onClick={handleClose}
                  fontSize="large"
                  className="green"
                />{" "}
                <b>Note</b>
              </Typography>
              <Typography variant="body1" className="book" sx={{ mt: 2 }}>
                {note}
              </Typography>
            </Box>
          </Popper>
        </Box>
      </Grid>
      <Grid
        item
        xs={4}
        display={{ xs: "block", md: "flex" }}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Button
          disabled={changeColor}
          onClick={rejectHandler}
          className={
            changeColor
              ? "accept-btn btn  red bg-white"
              : " accept-btn btn  green bg-white"
          }
          fullWidth
        >
          {requestStatusR}
        </Button>

        <Button
          disabled={changeColor}
          onClick={
            investorSubscription === "basic" &&
            userNetworkList.length == investorPremiumFeatures.connectLimit
              ? handleBuyPremium
              : accpetOnClick
          }
          className={
            changeColor
              ? "accept-btn btn bg-white green"
              : "accept-btn btn bg-green white"
          }
          fullWidth
        >
          {requestStatusA}
        </Button>
      </Grid>
    </Grid>
  );
};

export default RequestCard;
