import React from "react";
import { Hearts, Rings, ThreeDots } from "react-loader-spinner";
import { Watch } from "react-loader-spinner";
import "./Spinner.css";

const Spinner = ({ message }) => {
  return (
    <div className="main">
      <Rings ariaLabel="loading-indicator" />
      <p className="text">{message}</p>
    </div>
  );
};

export const Spinner3Dots = ({ message }) => {
  return (
    <div className="main">
      <ThreeDots
        height="80"
        width="80"
        radius="9"
        color="#4fa94d"
        ariaLabel="three-dots-loading"
        visible={true}
      />
      <p className="text">{message}</p>
    </div>
  );
};
export const SpinnerHearts = ({ message }) => {
  return (
    <div className="main">
      <ThreeDots
        height="80"
        width="80"
        color="#ccc"
        ariaLabel="hearts-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
      <p style={{ color: "#ccc" }} className="text2">
        {message}
      </p>
    </div>
  );
};

export default Spinner;
