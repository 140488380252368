import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import process from "./img/Process.png";
import SeekPng from "./img/SeekPng.com_uci-logo-png_3703355.png";
import { Box } from "@mui/system";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
// import { Document } from "react-pdf";
import { Document, Page, pdfjs } from "react-pdf";
// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";

const ProcessImg = () => {
  const details = useSelector((state: any) => {
    return state.loginReducerHandler.choosenData;
  });

  // const docs = [
  //   { uri: details?.pitch },
  //   // { uri: require("./example-files/pdf.pdf") }, // Local File
  // ];

  const navigate = useNavigate();

  // useEffect(() => {
  //   pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  // }, [])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className="align">
        {details?.pitch?.slice(
          details?.pitch?.length - 3,
          details?.pitch?.length
        ) === "pdf" ? (
          // <>
          //   <Typography
          //     variant="h6"
          //     sx={{
          //       // display: "flex",
          //       // textAlign: "center",
          //       justifyContent: "center",
          //       color: "#ccc",
          //       mt: 7,
          //     }}
          //   >
          //     YOUR MEDIA FILE WILL BE DOWNLOADED
          //   </Typography>
          //   <iframe
          //     src={details?.pitch ? details?.pitch : SeekPng}
          //     // src="https://docs.google.com/gview?url=https://path.com/to/your/pdf.pdf&embedded=true"
          //     // style={{ width: "600px ", height: "500px" }}
          //     frameBorder="0"
          //   ></iframe>
          // </>
          <>
            <Box
              style={{
                border: "1px solid rgba(0, 0, 0, 0.3)",
                height: "750px",
                width: "80%",
              }}
            >
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.min.js">
                <Viewer fileUrl={details?.pitch} />
              </Worker>
            </Box>
          </>
        ) : details?.pitch?.slice(
            details?.pitch?.length - 4,
            details?.pitch?.length
          ) === "pptx" ? (
          <>
          <iframe
              src={`https://view.officeapps.live.com/op/embed.aspx?src=${details?.pitch}`}
              width="70%"
              height="600px"
              frameBorder="0"
              style={{ paddingBottom: "2rem" }}
  
            ></iframe>
          
            
          </>
        ) : (
          <img
            onClick={() => {
              navigate("/edit-profile-startup");
            }}
            src={details?.pitch ? details?.pitch : SeekPng}
            className="max-width"
            alt="pitch"
            style={{ paddingBottom: "2rem" }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default ProcessImg;
