import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import SideBar from "../ProfilePage/SideBar";
import User from "../UserProfilePage/User";
import { useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

const Profile = () => {
  document.title = "Make new India | User Profile";
  const subscription = useSelector(
    (state: any) => state.startupReducer?.login[0]
  );
  const userSubscription = subscription?.subscription;

  const locationRoute = useLocation();
  let navigate = useNavigate();
  const isAlreadyOpen = locationRoute.state;

  useEffect(() => {
    const body = document.querySelector<any>("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
  }, []);

  return (
    <>
      <Grid
        container
        sx={{ m: { xs: 0, sm: 0, md: 2, lg: 2 } }}
        columnSpacing={2}
      >
        <Grid item xs={12} md={8} sm={12} className="padding align">
          <User userSubscription={userSubscription} isAlreadyOpen={isAlreadyOpen} />
        </Grid>
        <Grid item xs={12} md={4} sm={12} className="align">
          <SideBar />
        </Grid>
      </Grid>
    </>
  );
};

export default Profile;
