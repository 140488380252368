import { Avatar, Divider, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import "./details.css";
import media from "./img/media.svg";

const Details = () => {
  const startUpData: any = useSelector<any>(
    (state) => state.startupReducer?.login[0]
  );


  const LinkBox = (
    <Box className="link-box ">
      <Typography className="orange link-text">
        {/* https://www.google.com/ */}
        {startUpData?.websiteUrl ? startUpData?.websiteUrl : "Not Available"}
      </Typography>
    </Box>
  );

  const SocialMedia = (
    <Box className="media-box ">
      <img src={media} className="media" />
    </Box>
  );
  return (
    <>
      <Box className="details-container ">
        <Grid container className="border" mb={7}>
          {[
            {
              title: "Phase",
              amt:
                startUpData?.phase === ""
                  ? "Not Available"
                  : startUpData?.phase,
            },
            { title: "Equity Offer", amt: startUpData?.equityOffer + "%" },
            {
              title: "Ask Price",
              amt:
                startUpData?.askPrice >= 10000000
                  ? "₹" + (startUpData.askPrice / 10000000).toFixed(2) + " Cr"
                  : startUpData.askPrice >= 100000
                  ? "₹" + (startUpData.askPrice / 100000).toFixed(2) + " L"
                  : startUpData?.askPrice.toLocaleString("en-IN", {
                      maximumFractionDigits: 2,
                      style: "currency",
                      currency: "INR",
                    }),
            },
          ].map((item, index) => (
            <>
              <Grid item xs={12} className="details-items" key={index}>
                <Typography variant="body1" className="bold grey">
                  {item?.title}
                </Typography>
                <Typography
                  variant="h3"
                  className="bold details-text-size"
                  gutterBottom
                >
                  {item?.amt}
                  {/* {startUpData.equityOffer} */}
                </Typography>
                {!index ? <Divider className="divider md " /> : null}
              </Grid>
            </>
          ))}
        </Grid>

        <Typography
          variant="h3"
          className="bold details-text-size"
          gutterBottom
        >
          {"About " + startUpData?.companyName}
        </Typography>
        <Grid container mt={6} className="border">
          {[
            { title: "Legal Name", amt: startUpData?.companyName },
            { title: "Founded", amt: startUpData?.founded },
            { title: "Type Of Industry", amt: startUpData?.industry },
            {
              title: "Company Valuation",
              amt:
                startUpData?.companyValuation === "undefined" ||
                startUpData?.companyValuation === undefined
                  ? "Not Available"
                  : startUpData?.companyValuation >= 10000000
                  ? "₹ " +
                    (startUpData?.companyValuation / 10000000).toFixed(2) +
                    " Cr"
                  : startUpData?.companyValuation >= 100000
                  ? "₹ " +
                    (startUpData?.companyValuation / 100000).toFixed(2) +
                    " L"
                  : startUpData?.companyValuation,
            },

            // { title: "Employees", amt: "30" },
            {
              title: "Employees",
              amt:
                startUpData?.employeeCount === "undefined" ||
                startUpData?.employeeCount === undefined
                  ? "Not Available"
                  : startUpData?.employeeCount,
            },
            {
              title: "Website",
              amt: LinkBox,
            },
            { title: "Social Media", amt: SocialMedia },
            { title: "Headquaters", amt: startUpData?.location },
          ].map((item, index, array) => (
            <>
              <Grid item xs={12} className="details-items" key={index}>
                <Typography variant="body1" className="bold grey ">
                  {item.title}
                </Typography>
                {typeof item.amt === "string" ? (
                  <Typography
                    variant="h4"
                    className="bold details-text-size"
                    gutterBottom
                  >
                    {item?.amt}
                  </Typography>
                ) : (
                  item.amt
                )}

                {index !== array.length - 1 ? (
                  <Divider className="divider md" />
                ) : null}
              </Grid>
            </>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default Details;
