import { Divider, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import "./swot.css";
import LockIcon from "@mui/icons-material/Lock";
import { Link } from "react-router-dom";

const Swot = (props: any) => {
  const details = useSelector((state: any) => {
    return state.loginReducerHandler?.choosenData;
  });

  return (
    <>
      {props.userSubscription === "basic" ? (
        <>
          <Box
            display="flex"
            gap={2}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ p: "4rem 0" }}
          >
            <LockIcon fontSize="large" />
            <Link to={"/pricing-investor"} style={{ textDecoration: "none" }}>
              <Typography variant="h4" color={"black"}>
                Unlock Premium To See
              </Typography>
            </Link>
          </Box>
        </>
      ) : (
        <Box className="border swot-container">
          <Grid container p={5}>
            {[
              {
                title: "Strengths",
                amt:
                  details?.strength == null
                    ? "Not available"
                    : details?.strength,
              },
              {
                title: "Weakness",
                amt:
                  details?.weakness == null
                    ? "Not available"
                    : details?.weakness,
              },
              {
                title: "Opportunities",
                amt:
                  details?.opportunities == null
                    ? "Not available"
                    : details?.opportunities,
              },
              {
                title: "Threats",
                amt:
                  details?.threats == null
                    ? "Not available"
                    : details?.opportunities,
              },
            ].map((item, index) => (
              <>
                <Grid item xs={12} className="details-items" key={index}>
                  <Typography variant="body1" className="bold grey">
                    {item?.title}
                  </Typography>
                  <Typography
                    // variant="h3"
                    className="bold details-text-size"
                    gutterBottom
                  >
                    {item?.amt}
                    {/* {startUpData.equityOffer} */}
                  </Typography>
                  {index !== 3 ? <Divider className="divider mt" /> : null}
                </Grid>
              </>
            ))}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default Swot;
