import React, { useEffect, useState, useRef } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import googleround from "./img/googleround.png";
import "./networkcard.css";
import { useDispatch, useSelector } from "react-redux";
import { getStartupDetails } from "../../../../store/startup/actions";
import { useNavigate } from "react-router-dom";
import Spinner, { Spinner3Dots } from "../../../../Extras/Spinner";
import companyBydefault from "../../../../Assets/image/companyBydefault.webp";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
const NetWorkCard = ({ userId }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getStartupDetails(userId));
  }, [userId]);

  const connectionArray = useSelector((state: any) => {
    return state.loginReducerHandler?.connectionData;
  });
  connectionArray.reverse();

  const investorId = useSelector((state: any) => {
    return state.loginReducerHandler?.login[0]?.uId;
  });

  // const startupId = useSelector((state: any) => {
  //   return state.loginReducerHandler?.connectionData?.[0].userId;
  // });

  const connectionSet = new Set();
  const uniqueConnections = connectionArray.filter((data: { userId: any }) => {
    if (connectionSet.has(data.userId)) {
      return false;
    }
    connectionSet.add(data.userId);
    return true;
  });

  const startupData = uniqueConnections.find(
    (element: any) => element.userId === userId
  );

  const handleRedirect = (e: any) => {
    e.preventDefault();
    navigate("/chat", { state: startupData.userId });
  };

  useEffect(() => {
    if (startupData === undefined) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [startupData]);

  if (isLoading) {
    return (
      <div>
        <Spinner3Dots message="" />
      </div>
    );
  }
  const handleImgError = (e: any) => {
    e.target.src = companyBydefault;
  };

  const connectionDeleted = (toastHandler = toast) => {
    toastHandler.success("Connection removed Successfully!", {
      style: {
        background: "#04111d",
        color: "#fff",
      },
    });
  };

  const url = "https://makenewindia.com/api";
  const handleDelete = () => {
    axios
      .put(
        `${url}/removeConnection/${investorId}/${startupData?.userId}?key=investor`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err: any) => {
        return err;
      });
    connectionDeleted();
  };

  return (
    <Grid
      container
      mt={2}
      p={2}
      className="bg-white"
      sx={{ boxShadow: 3, borderRadius: 5 }}
    >
      <Toaster position="top-center" reverseOrder={false} />
      <Grid item xs={2} sm={2} md={2} xl={2} lg={2}>
        <Box
          onError={handleImgError}
          sx={{ height: "80px", width: "80px", objectFit: "cover" }}
          component="img"
          src={
            startupData?.profilePicture
              ? startupData?.profilePicture
              : googleround
          }
          className="max-width img-box"
        />
      </Grid>
      <Grid
        item
        xs={7}
        sm={8}
        md={8}
        xl={8}
        lg={8}
        className="company-desc-container"
      >
        <Box sx={{ p: "10px" }}>
          <Typography className="size-s bold network-company-text">
            {startupData?.companyName}
          </Typography>
          <Typography
            variant="body1"
            className="size-xs book network-industry-text"
          >
            {startupData?.location}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={3} sm={2}>
        <Box sx={{ display: "block", position: "relative", top: 9 }}>
          <Button
            onClick={handleRedirect}
            className="btn white bg-orange"
            fullWidth
          >
            Chat
          </Button>
          <Button
            onClick={handleDelete}
            className=".rmv-btn btn orange"
            fullWidth
          >
            Remove
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default NetWorkCard;
