import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  investorBasic,
  investorPremium,
  startupBasic,
  startupPremium,
} from "./pricingArr";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { Modal } from "@mui/material";
import { apiClient } from "../../Api/api"

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function PricingCardStartup(props: any) {
  const token = localStorage.getItem("id");
  const userType = localStorage.getItem("type");
  const navigate = useNavigate();
  const details = useSelector((state: any) => {
    return state.startupReducer?.login[0];
  });
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDowngradePlanStartup = async () => {
    axios
      .put(`${apiClient}/downgrade/${props?.email}?key=startup`)
      .then((res: any) => {
        toast.success("Downgraded successful");
        navigate("/startup-dashboard");
        return res;
      })
      .catch((error: any) => {
        return error;
      });
  };

  const handleTransfer = () => {
    if (token) {
      window.open("https://rzp.io/l/CI63OwBj");
    } else {
      navigate("/login");
    }
  };
  const handleTransferPremium = () => {
    if (props.planName === details?.subscription) {
      toast("This is your current plan");
    } else if (token && props.planName === "premium") {
      window.open("https://rzp.io/l/yUfBIRU");
    } else {
      navigate("/login");
    }
  };

  // const getPrice = async () => {
  //   try {
  //     const result = await axios.get("http://localhost:5000/api/startupplan");
  // 
  //     // setPremiumPrice(result?.data)
  //   } catch (error) {
  //    
  //   }
  // };

  // useEffect(() => {
  //   getPrice();
  // },[])

  return (
    <>
      <Card
        sx={{
          maxWidth: 400,
          height: 630,
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            pb: "0",
          }}
        >
          <Typography sx={{ fontSize: 18 }} color="text.primary" gutterBottom>
            Startup {props.planName}
          </Typography>
          <Typography variant="h4" component="div">
            {props.price ? props.price : "INR 1499/month"}
            <Typography>
              {" "}
              {props.planName === "premium" ? "Free 7 days trial" : null}
            </Typography>
          </Typography>
        </CardContent>
        <CardContent sx={{ pl: "1.3rem" }}>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            {props.headline}
          </Typography>
          {props.planName === "basic"
            ? startupBasic.map((item, index) => (
                <Box display={"flex"} alignItems="center" pb={"1rem"}>
                  <Box pr={item.active === "1" ? "1rem" : "1.6rem"}>
                    {item.image}
                  </Box>
                  <Box>{item.name}</Box>
                </Box>
              ))
            : startupPremium.map((item, index) => (
                <Box display={"flex"} alignItems="center" pb={"1rem"}>
                  <Box pr={item.active === "1" ? "1rem" : "1.6rem"}>
                    {item.image}
                  </Box>
                  <Box>{item.name}</Box>
                </Box>
              ))}
        </CardContent>
        <CardActions>
          <Button
            // size="small"
            sx={{
              backgroundColor: "green",
              color: "white",
              width: "100%",
              borderRadius:'50px',
              '&:hover': {
                backgroundColor: 'green',
              },
            }}
            // disabled= {props.planName === "Basic"}
            // onClick={handleTransferPremium}
            onClick={
              userType === "startup" && props.btnName === "Downgrade"
                ? handleOpen
                : handleTransferPremium
            }
          >
            {token ? props.btnName : "Get Now"}
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Box>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Are you sure want to downgrade?
                </Typography>
              </Box>
              <Box pt={"2rem"}>
                <Button onClick={handleDowngradePlanStartup}>Yes</Button>
                <Button onClick={handleClose}>No</Button>
              </Box>
            </Box>
          </Modal>
        </CardActions>
      </Card>
    </>
  );
}
