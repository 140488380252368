import { Avatar, Divider, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import "./details.css";
import media from "./img/media.svg";
import LockIcon from "@mui/icons-material/Lock";
import { Link } from "react-router-dom";

const Details = (props: any) => {
  const startUpData: any = useSelector<any>(
    (state) => state.startupReducer?.login[0]
  );
  const LinkBox = (
    <Box className="link-box ">
      <Typography className="orange link-text">
        https://www.google.com/
      </Typography>
    </Box>
  );

  const SocialMedia = (
    <Box className="media-box ">
      <img src={media} className="media" />
    </Box>
  );

  const details = useSelector((state: any) => {
    return state.loginReducerHandler.choosenData;
  });


  return (
    <>
      {props.userSubscription === "basic" ? (
        <>
          <Box
            display="flex"
            gap={2}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ p: "4rem 0" }}
          >
            <LockIcon fontSize="large" />
            <Link to={"/pricing-investor"} style={{ textDecoration: "none" }}>
              <Typography variant="h4" color={"black"}>
                Unlock Premium To See
              </Typography>
            </Link>
          </Box>
        </>
      ) : (
        <Box className="details-container ">
          <Grid container className="border" mb={7}>
            {[
              {
                title: "Phase",
                amt: details?.phase === "" ? "Not Available" : details?.phase,
              },
              // {
              //   title: "Contact Number",
              //   amt: details?.contactNumber === "" ? "Not Available" : details?.contactNumber,
              // },
              { title: "Equity Offer", amt: details?.equityOffer + "%" },
              // { title: "Ask Price", amt: details.askPrice + " ₹" },
              {
                title: "Ask Price",
                amt:
                  details?.askPrice >= 10000000
                    ? "₹" + (details?.askPrice / 10000000).toFixed(2) + " Cr"
                    : details?.askPrice >= 100000
                    ? "₹" + (details?.askPrice / 100000).toFixed(2) + " L"
                    : "₹" + details?.askPrice,
              },
            ].map((item, index) => (
              <>
                <Grid item xs={12} className="details-items" key={index}>
                  <Typography variant="body1" className="bold grey">
                    {item?.title}
                  </Typography>
                  <Typography
                    variant="h3"
                    className="bold details-text-size"
                    gutterBottom
                  >
                    {item?.amt}
                  </Typography>
                  {!index ? <Divider className="divider md " /> : null}
                </Grid>
              </>
            ))}
          </Grid>

          <Typography
            variant="h3"
            className="bold details-text-size"
            gutterBottom
          >
            About Google
          </Typography>
          <Grid container mt={6} className="border">
            {[
              { title: "Legal Name", amt: details?.companyName },
              { title: "Founded", amt: details?.founded },
              { title: "Type Of Industry", amt: details?.industry },
              {
                title: "Company Valuation",
                amt:
                  details?.companyValuation == null
                    ? "Not available"
                    : details?.companyValuation >= 10000000
                    ? "₹ " +
                      (details?.companyValuation / 10000000).toFixed(2) +
                      " Cr"
                    : details?.companyValuation >= 100000
                    ? "₹ " +
                      (details?.companyValuation / 100000).toFixed(2) +
                      " L"
                    : details?.companyValuation,
              },
              {
                title: "Employees",
                amt:
                  details?.employeeCount === "undefined" ||
                  details?.employeeCount === undefined
                    ? "Not Available"
                    : details?.employeeCount,
              },
              {
                title: "Website",
                amt:
                  details?.websiteUrl == "undefined" ||
                  details?.websiteUrl == null
                    ? "Not available"
                    : details?.websiteUrl,
              },
              { title: "Social Media", amt: SocialMedia },
              { title: "Headquaters", amt: details?.location },
            ].map((item, index, array) => (
              <>
                <Grid item xs={12} className="details-items" key={index}>
                  <Typography variant="body1" className="bold grey ">
                    {item.title}
                  </Typography>
                  {typeof item.amt === "string" ? (
                    <Typography
                      variant="h4"
                      className="bold details-text-size"
                      gutterBottom
                    >
                      {item.amt}
                    </Typography>
                  ) : (
                    item.amt
                  )}

                  {index !== array.length - 1 ? (
                    <Divider className="divider md" />
                  ) : null}
                </Grid>
              </>
            ))}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default Details;
