import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import process from "./img/Process.png";
import uploadBgImg from "../../../../../../Assets/image/uploadBgImg.svg";
import { useNavigate } from "react-router-dom";
import { Viewer, Worker } from "@react-pdf-viewer/core";

const ProcessImg = () => {
  const navigate = useNavigate();
  const startUpData: any = useSelector<any>(
    (state) => state.startupReducer?.login[0]
  );

  return (
    <Grid container>
      <Grid item xs={12} className="align">
        {startUpData?.pitch?.slice(
          startUpData?.pitch?.length - 3,
          startUpData?.pitch?.length
        ) === "pdf" ? (
          // <>
          //   <Typography
          //     variant="h6"
          //     sx={{
          //       // display: "flex",
          //       // textAlign: "center",
          //       justifyContent: "center",
          //       color: "#ccc",
          //       mt: 7,
          //     }}
          //   >
          //     YOUR MEDIA FILE WILL BE DOWNLOADED
          //   </Typography>
          //   <iframe
          //     src={startUpData?.pitch ? startUpData?.pitch : uploadBgImg}
          //     // src="https://docs.google.com/gview?url=https://path.com/to/your/pdf.pdf&embedded=true"
          //     // style={{ width: "600px ", height: "500px" }}
          //     frameBorder="0"
          //   ></iframe>
          // </>
          <>
          <Box
            style={{
              border: "1px solid rgba(0, 0, 0, 0.3)",
              height: "750px",
              width: "80%",
            }}
          >
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.min.js">
              <Viewer fileUrl={startUpData?.pitch} />
            </Worker>
          </Box>
        </>
        ) : startUpData?.pitch?.slice(
          startUpData?.pitch?.length - 4,
          startUpData?.pitch?.length
        ) === "pptx" ? (
          <>
            <iframe
              src={`https://view.officeapps.live.com/op/embed.aspx?src=${startUpData?.pitch}`}
              width="70%"
              height="600px"
              frameBorder="0"
              style={{ paddingBottom: "2rem" }}
  
            ></iframe>
          </>
        ) : (
          <img
            onClick={() => {
              navigate("/edit-profile-startup");
            }}
            src={startUpData?.pitch ? startUpData?.pitch : uploadBgImg}
            className="max-width"
            alt="pitch"
          />
        )}
        {/* <img
          onClick={() => {
            navigate("/edit-profile-startup");
          }}
          src={startUpData?.pitch ? startUpData?.pitch : uploadBgImg}
          className="max-width"
          alt="pitch"
        /> */}
        {/* <iframe
          src={startUpData?.pitch ? startUpData?.pitch : uploadBgImg}
          // src="https://docs.google.com/gview?url=https://path.com/to/your/pdf.pdf&embedded=true"
          style={{ width: "600px ", height: "500px" }}
          frameBorder="0"
        ></iframe> */}
      </Grid>
    </Grid>
  );
};

export default ProcessImg;
