import React, { useState } from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import "./Pricing.css";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import correct from "./img/correct.png";
import wrong from "./img/wrong.png";
import { PricingInvestor } from "./PricingInvestor";
import Modal from "@mui/material/Modal";
import thankyou from "./img/thankyou.png";
import Failure from "./img/failure.png";
import { PricingStartup } from "./PricingStartup";
import { useSelector } from "react-redux";
import PricingCard from "./PricingCard";
import PricingCardStartup from "./PricingCardStartup";
import PricingStartupUpdated from "./PricingStartupUpdated";
import PricingInvUpdated from "./PricingInvUpdated";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#EFF3F3",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(name: any, basic: any, premium: any) {
  return { name, basic, premium };
}

const rows = [
  createData(
    "Limited Search Results (No premium Investors)",
    <img src={correct} alt="test" />,
    <img src={wrong} alt="test" />
  ),
  createData(
    "One way connection (If Investor wants to connect)",
    <img src={correct} alt="test" />,
    <img src={wrong} alt="test" />
  ),
  createData(
    "Zero Commission on deal",
    <img src={correct} alt="test" />,
    <img src={correct} alt="test" />
  ),
  createData(
    "Chat with connected Investors",
    <img src={correct} alt="test" />,
    <img src={wrong} alt="test" />
  ),
  createData(
    "Tagged as Premium",
    <img src={wrong} alt="test" />,
    <img src={correct} alt="test" />
  ),
  createData(
    "Get Access to Premium Investors",
    <img src={wrong} alt="test" />,
    <img src={correct} alt="test" />
  ),
  createData(
    "Unlimited Search Results",
    <img src={wrong} alt="test" />,
    <img src={correct} alt="test" />
  ),
  createData(
    "Connect to unlimited Investors",
    <img src={wrong} alt="test" />,
    <img src={correct} alt="test" />
  ),
  createData(
    "Chat with unlimited Investors",
    <img src={wrong} alt="test" />,
    <img src={correct} alt="test" />
  ),
  createData(
    <b>Price</b>,
    <b>FREE</b>,
    <p>
      <b>5,999 INR/1 mo</b> <br /> (INR 4,999/- when billed for 3 Months)
    </p>
  ),
  createData(
    "",
    <Button className="arrayBtn">Buy Now</Button>,
    <Button className="arrayBtn">Buy Now</Button>
  ),
];

export const Pricing = () => {
  const [active, setActive] = useState(true);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const isLoggedIn = useSelector<any>(
    (state) => state.loginReducerHandler.isLoggedIn
  );

  return (
    <>
      <Box
        sx={{
          marginTop: { xl: "100px", xs: "50px" },
          marginBottom: { lg: "-30px", xs: "0px" },
        }}
        className="pricingBox"
      >
        <Typography
          className="pricingTitle"
          sx={{
            borderBottom: "3px solid orange",
            width: "fit-content",
            ml: { sm: 7 },
            // mr: 48,
          }}
        >
          Pricing
        </Typography>
        <Box
          className="pricingBox-inner"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box display="flex" sx={{ mb: 5 }} className="pricings">
            <Typography
              className="pricingStartup"
              fontWeight="bold"
              id="1"
              onClick={() => setActive(true)}
              sx={{
                // mr: { md: 10, sm: 2 },
                backgroundColor: active === true ? "#FF9A33" : null,
                color: active === true ? "#FFFFFF" : null,
              }}
            >
              Startup
            </Typography>
            <Typography
              className="pricingInvestor"
              fontWeight="bold"
              id="2"
              onClick={() => setActive(false)}
              sx={{
                backgroundColor: active === false ? "#FF9A33" : null,
                color: active === false ? "#FFFFFF" : null,
              }}
            >
              Investor
            </Typography>
          </Box>
        </Box>
      </Box>
      {active ? (
        <>
          {/* <PricingStartup /> */}
          <Box display={"flex"} justifyContent={"center"} gap={10}>
            <PricingStartupUpdated />

          </Box>
        </>
      ) : (
        <>
          {/* <PricingInvestor /> */}
          <Box display={"flex"} justifyContent={"center"} gap={10}>
          <PricingInvUpdated />

          </Box>
        </>
      )}
    </>
  );
};
