import React from "react";
import { Box, Breadcrumbs, Link, Stack, Typography } from "@mui/material";

// function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
//   event.preventDefault();
// }
const PrivacyPolicy = () => {
  document.title = "Make New India | Privacy Policy";
  // const breadcrumbs = [
  //   <Link
  //     underline="hover"
  //     key="1"
  //     color="inherit"
  //     fontSize="14px"
  //     href="/"
  //     onClick={handleClick}
  //     sx={{ opacity: "66%" }}
  //   >
  //     HOME
  //   </Link>,
  //   <Link
  //     underline="hover"
  //     key="2"
  //     color="inherit"
  //     fontSize="14px"
  //     href="/"
  //     onClick={handleClick}
  //     sx={{ opacity: "66%" }}
  //   ></Link>,
  // ];
  return (
    <Box
      className="max-width"
      pt={3}
      sx={{
        mx: { xs: 2, sm: 0, md: 10 },
      }}
    >
      {/* <Box sx={{display:{xs:'none',sm:'none'}}}>
            <Stack spacing={2}>
              <Breadcrumbs
                sx={{ opacity: "66%"  }}
                separator="›"
                aria-label="breadcrumb"
              >
                {breadcrumbs}
              </Breadcrumbs>
            </Stack>
          </Box> */}

      <Box mb={2} className="td-media-query">
        <Typography
          variant="subtitle2"
          color="#01010159"
          component="span"
          pr={1}
        >
          HOME
        </Typography>
        <Typography variant="subtitle2" color="#01010159" component="span">
          &gt;
        </Typography>
      </Box>
      <Typography
        variant="h4"
        sx={{
          fontFamily: "light",
          color: "black",
          fontSize: "45px",
          fontWeight: "600",
          mx: { xs: 0, sm: 5, md: 0 },
        }}
      >
        Privacy Policy
      </Typography>

      <Box
        mt={2}
        sx={{
          mx: { xs: 0, sm: 5, md: 0 },
        }}
      >
        <Typography mb={2.8} className="typography-style-text">
          <Typography variant="h5" className="bold">
            Introduction
          </Typography>
          <br />
          <Typography>
            Makenewindia.com core mission is to connect Indian
            companies/business collectively referred to as “Startups” with
            world’s high net worth individuals referred to as “Investors” to
            promote economic opportunities and allow them to be more productive
            and successful. Startups and Investors as hereinafter collectively
            referred to as “Users”. Our services are designed to promote
            business opportunities for our users by enabling you and millions of
            other business minds to meet, exchange ideas, learn, and find
            investment and expansion opportunities, and make decisions in a
            network of trusted relationships.
          </Typography>
          <Typography>
            We, at makenewindia.com are committed to respecting your online
            privacy and recognize the need for appropriate protection and
            management of any personally identifiable information you share with
            us.
          </Typography>{" "}
          <br />
          <Typography>
            Your privacy is important to us, and so is being transparent about
            how we collect, use, and share information about you.
          </Typography>
          <br />
          <Typography>
            This Privacy Policy has been duly framed in accordance with the
            Information Technology Act, 2000 (“Act”) and the Rules made
            thereunder to ensure maximum protection to the information provided
            by its users (“you”). This Privacy Policy ("Policy") is intended to
            help you understand how we collects, uses, discloses and transfers
            personal information of users through our website (the "Platform")
          </Typography>{" "}
          <br />
          <Typography>
            This policy applies to those who visit the Platform, or whose
            information makenewindia.com otherwise receives in connection with
            its services (such as contact information of individuals associated
            with makenewindia.com including partners) (hereinafter collectively
            referred to as “Users”). For the purposes of the Privacy Policy,
            “You" or “Your” shall mean the person who is accessing the Platform
            and “we” or “us” or “our” means makenewindia.com (the “Platform”).
          </Typography>
          <br />
          <Box ml={4}>
            <Typography variant="h6" className="bold">
              1. What information we collect from you
            </Typography>
            <Typography>
              Makenewindia.com is an advertising platform providing targeted
              advertising services for the purpose of building a connection and
              communication between startup and investors ("Service"). In order
              to do provide the services, we ask for certain personal
              information which is displayed on the site on behalf of you to
              connect with right opportunity. You hereby provide your consent to
              collect, process, and share of your personal information in order
              to provide the service.
            </Typography>{" "}
            <br />
            <Typography variant="h6" className="bold">
              1.1 Data You Provide to Us
            </Typography>
            <Box ml={4}>
              <Typography>
                Makenewindia.com collects "Personal information" (PI) - means
                any information relating to an identified or identifiable
                natural person or information relating to an identified or
                identifiable business/company including common identifiers such
                as a name, trade nameEmail ID, an identification number,gender,
                contact number, date of birth, location data, an online
                identifier (Website, social media accounts/pages etc).We collect
                information about you and/or your usage to provide better
                services and offerings. The Personal Information that we
                collect, and how we collect it, depends upon how you interact
                with us and other users on platform. We collect the following
                categories of Personal Information in the following ways:
                <br />
                <b> • Registration:</b> To create an account we may collect your
                Personal and company Information such as name or trade name,
                email address, password, gender, date of birth, current
                location, city, contact number with country code, an online
                identifier (Website, social media accounts/pages etc.), whenyou
                sign up as a Startup or an Investor.
                <br />
                <b>• Profile:</b>Information that one would usually include in a
                profile, including name, contact details including e-mail
                address and mobile number, date of birth, financialdata relating
                to your company, personal or company online identifiers
                (Website, social media accounts/pages etc.), areas of expertise
                (Skills), previousinvestments in companies, profile pictures,
                business/company presentation videos and files and etc.after you
                register on the Platform.
                <br />
                <b>• Paid Service:</b> When you avail for a paid service, you
                provide us or our payment service provider with the sensitive
                personal data, such as your debit or credit card information or
                UPI.
                <br />
                <b> • Permission:</b> We need SMS permission for authenticating
                transactions via OTP, sent by the Payment Gateway.
                <br />
                <b> • User activity:</b> We collect information about your
                activity on our services, such as date and time you logged in,
                features you've been using, searches, clicks and pages visited
                by you, your interaction with other users including messages
                exchanged. For example, if you get a connection request, we
                track whether you have acted on it and will send you reminders.
                <br />
                To the extent permitted by law, makenewindia.com may record and
                monitor your communications with us to ensure compliance with
                our legal and regulatory obligations and our internal policies.
                This may include the recording of telephone conversations.
              </Typography>
            </Box>
            <br />
            <Box>
              <Typography className="bold">1.2 Data from Others</Typography>
              <Box ml={4}>
                <Typography>
                  <b>• Service Use:</b> information about the services that you
                  use and how you use them, including log information and
                  location information, when you are a user of the services
                  through the Platform.
                  <br />
                  <b> • Partners:</b> when you communicate with makenewindia.com
                  or use the Platform to communicate with other Users (such as
                  partners), we collect information about your communication and
                  any information you choose to provide
                  <br />
                  <b>• News:</b> We also may collect public information about
                  you, such as professional-related news and accomplishments,
                  and make it available as part of our Services, including, as
                  permitted by your settings, in notifications to others of
                  mentions in the news.
                  <br />
                  <b> • System, Device, Location and Cookies:</b> when you visit
                  the Platform, we use cookies to automatically collect, store
                  and use technical information about your system and
                  interaction with our Platform. when you use a device to access
                  the Platform, makenewindia.com may collect technical or other
                  related information from the device as well as the device
                  location. We also collect information from and about the
                  device(s) such as IP address, device ID and type,
                  device-specification and apps settings, app error reports,
                  browser type and version, operating system, identifiers
                  associated with cookies or other technologies that may
                  uniquely identify your device or browser.
                  <br />
                </Typography>
                <Typography>
                  We use your data to provide, support, personalize and develop
                  our Services. We will only use your personal data in a fair
                  and reasonable manner, and where we have a lawful reason to do
                  so. Our use of your personal data depends on depend on which
                  Services you use, how you use those Services the purpose for
                  which you interact with us. We use the data that we have about
                  you to provide and personalize our Services, including with
                  the help of automated systems and inferences we make, so that
                  our Services can be more relevant and useful to you and
                  others. We may process your Personal Information for the
                  following purposes:
                </Typography>
                <br />
                <Typography>
                  • We use data about you (such as your profile information,
                  profiles you have viewed or data provided through address book
                  uploads or partner integrations) to Provide our services and
                  products to you including to send you connection alerts,
                  relevant search results and recommended Potential connections
                  (as the case maybe), help others find your profile, suggest
                  connections for you and others (e.g. users who share similar
                  business experience/skills) and enable you to invite others to
                  become a Member and connect with you. You can also opt-in to
                  allow us to use your precise location or proximity to others
                  for certain tasks (e.g. to suggest other nearby users for you
                  to connect with). It is your choice whether to invite someone
                  to our Services, send a connection request, or allow another
                  Member to become your connection. When you invite someone to
                  connect with you, your invitation will include your basic
                  profile information (e.g., name or trade name, profile photo,
                  location etc.)
                </Typography>
                <br />
                <Typography>
                  • We use data bout you to keep you informed about news, events
                  and ideas regarding financial, business and professional
                  topics you care about, and from sources you respect.
                </Typography>
                <br />
                <Typography>
                  • Protecting our Users and providing you with customer
                  support;
                </Typography>
                <br />
                <Typography>
                  • We use information collected from cookies and other
                  technologies, to improve your user experience and the overall
                  quality of our services (for more information please refer to
                  paragraph 4 below).{" "}
                </Typography>
                <br />
                <Typography>
                  <b>•</b> Improving the Platform and its content to provide
                  better features and services. We use data, including public
                  feedback, to conduct research and development for our Services
                  in order to provide you and others with a better, more
                  intuitive and personalized experience, drive membership growth
                  and engagement on our Services, and help connect professionals
                  to each other and to economic opportunity.
                </Typography>
                <br />
                <Typography>
                  <b> •</b> Conducting market research and surveys with the aim
                  of improving our products and services.
                </Typography>
                <br />
                <Typography>
                  <b> •</b> Sending you information about our products and
                  services for marketing purposes and promotions via email and
                  phone.
                </Typography>
                <br />
                <Typography>
                  <b> •</b> We use your data to produce and share insights that
                  do not identify you. For example, we may use your data to
                  generate statistics about our users.
                </Typography>
                <br />
                <Typography>
                  <b> •</b> Preventing, detecting, investigating and taking
                  action against crimes (including but not limited to fraud and
                  other financial crimes), any other illegal activities,
                  suspected fraud, or violations of makenewindia.com Terms of
                  Use in any jurisdiction
                </Typography>
                <br />
                <Typography>
                  <b> •</b> To the extent required for identity verification,
                  government sanctions screening and due diligence checks.
                </Typography>
                <br />
                <Typography>
                  <b>•</b> Establishing, exercising or defending legal rights in
                  connection with legal proceedings (including any prospective
                  legal proceedings) and seeking professional or legal advice in
                  relation to such legal proceedings.
                </Typography>
                <br />
              </Box>
              <Typography className="bold" variant="h6">
                2. How and with whom we Share Information
              </Typography>
              <br />
              <Box ml={4}>
                <Typography>
                  We restrict access to your Personal Information to employees
                  and service providers who we believe reasonably need to
                  know/or that information to fulfil their jobs to provide,
                  operate, develop, or improve our products or services. We do
                  not sell, rent, share, trade or give away any of your personal
                  information except where you are expressly informed on the
                  site or as described in this privacy policy:
                </Typography>
                <br />
                <Typography>
                  <b> •</b> Our affiliates and group companies to the extent
                  required for our internal business and/or administrative
                  purposes and/or general corporate operations and for provision
                  of services;
                </Typography>
                <br />
                <Typography>
                  <b> •</b> Third parties including enforcement, regulatory and
                  judicial authorities, if we determine that disclosure of your
                  Personal is necessary to protect our rights, other users
                  interest and protection and/or comply with a judicial
                  proceeding, court order, or legal process served on our Web
                  site.; or b) investigate, prevent, or take action regarding
                  illegal activities, suspected fraud, situations involving
                  potential threats to the physical safety of any person,
                  violations of makenewindia.com Terms of Use or as otherwise
                  required by law
                </Typography>
                <br />
                <Typography>
                  <b>•</b> In the event of a merger, acquisition, financing, or
                  sale of assets or any other situation involving the transfer
                  of some or all ofmakenewindia.com business assets we may
                  disclose Personal Information to those involved in the
                  negotiation or transfer.
                </Typography>
                <br />
                <Typography>
                  <b> •</b> We may use Third party service providers and
                  marketing partners that makenewindia.com engages to provide
                  services over the Platform on makenewindia.com behalf, website
                  and application development, hosting, maintain the Platform
                  and mailing lists, backup, storage, payment processing,
                  analysis, communicate with you on makenewindia.com behalf
                  about offers relating to its products and/or services and
                  other services for us, which may require them to access or use
                  information about you. If a service provider needs to access
                  information about you to perform services on our behalf, they
                  do so under close instruction from us, including policies and
                  procedures designed to protect your information.
                  Makenewindia.com will take reasonable steps to ensure that
                  these third-party service providers are obligated to protect
                  your Personal Information and are also subject to appropriate
                  confidentiality / non-disclosure obligations.
                </Typography>
                <br />
                <Typography>
                  <b> •</b> We publish the information shared by you with other
                  users to provide the services. The information so published
                  are provided by you and be cautious as what you share with
                  other users.
                </Typography>
                <br />
                <Typography>
                  Makenewindia.com does not intend to transfer Personal
                  Information without your consent to third parties who are not
                  bound to act on makewnewindia.com behalf unless such transfer
                  is legally required.
                </Typography>
                <br />
                <Typography>
                  If your Personal Information is transferred outside India, we
                  take the necessary steps to protect your Personal Information
                  in accordance with applicable data protection laws.
                </Typography>
                <br />
              </Box>
            </Box>
            <Typography variant="h6" className="bold">
              3. Control and/or access your personal information
            </Typography>{" "}
            <br />
            <Box ml={4}>
              <Typography>
                You have the right to invoke your rights which are available to
                data principals or data subjects (as per applicable laws and
                regulations) in relation to your Personal Information which is
                being processed by makenewindia.com.{" "}
              </Typography>
              <br />
              <Typography>
                Makenewindia.com provides you the ability to keep your Personal
                Information accurate and up to date. You have been provided with
                tools to manage your information. You have the rights to access,
                rectify, update information that you provided to us and that's
                associated with your makenewindia.com account directly by
                logging into your account.
              </Typography>
              <br />
              <Typography>
                If at any time you would like to obtain confirmation on whether
                or not your Personal Information is processed by it, access your
                Personal Information or exercise your right to data portability;
                orexercise your right to restrict the continuing disclosure of
                your Personal Information to any third party by makenewindia.com
                in certain circumstances, you are requested to contact us by
                email support@makenewindia.com. We will require you to provide a
                valid proof of your identity, in order to ensure that your
                rights are respected.
              </Typography>
              <br />
              <Typography>
                For the exercise of certain rights, you may be required to
                approach the relevant authority / designated officer as per the
                provisions of the applicable data protection laws/
                makenwindia.com may in accordance with the provisions of
                applicable data protection laws, charge a fee for fulfilling
                your request, in particular in case of excessive or manifestly
                unfounded request. Further you acknowledge that the
                abovementioned rights are not absolute and are subject to
                limitations as per the applicable data protection laws.
              </Typography>
              <br />
            </Box>
            <Typography variant="h6" className="bold">
              4. Confidentiality and Security
            </Typography>{" "}
            <br />
            <Box ml={4}>
              <Typography>
                The security and confidentiality of your Personal Information is
                important to us and makenewindia.com has invested significant
                resources to protect the safekeeping and confidentiality of your
                personal data. When using external service providers acting as
                processors, we require that they adhere to the same standards as
                makenewindia.com does. Regardless of where your Personal
                Information is transferred or stored, we take all steps
                reasonably necessary to ensure that personal data is kept
                secure.
              </Typography>
              <br />
              <Typography>
                We implement security safeguards designed to protect your data,
                such as HTTPS. We regularly monitor our systems for possible
                vulnerabilities and attacks. However, we cannot warrant the
                security of any information that you send us. There is no
                guarantee that data may not be accessed, disclosed, altered, or
                destroyed by breach of any of our physical, technical, or
                managerial safeguards. When our registration/order process asks
                you to enter sensitive information (such as a credit card
                number), such information is encrypted and is protected with the
                best encryption software in the industry. We follow generally
                accepted industry standards to protect the personal information
                submitted to us. All your information, not just the sensitive
                information mentioned above, is restricted in our offices. Only
                employees who need the information to perform a specific job are
                granted access to personally identifiable information.
              </Typography>
              <br />
              <Typography>
                We seek to ensure compliance with the requirements of the
                Information Technology Act, 2000 and Rules made there under to
                ensure the protection and preservation of your privacy. By
                accepting the terms of this Privacy Policy, you agree that the
                standards and practices being implemented by us, are reasonable
                and sufficient for the protection of your Personal Information.
              </Typography>
              <br />
            </Box>
            <Typography variant="h6" className="bold">
              5. Retention of Personal Information
            </Typography>
            <br />
            <Box ml={4}>
              <Typography>
                We keep your personal information only as long as you use our
                service and also as permitted/required by applicable law. Your
                Personal Information will not be retained by makenewindia.com
                any longer than it is necessary to comply with legal
                obligations, fraud prevention, take actions we deem necessary to
                protect the integrity of our website or our users, to resolve
                disputes, to enforce our agreements, to support business
                operations, and to continue to develop and improve our Services.
                We retain information for better services, and we only use the
                information to analyse about the use of our Services, not to
                specifically analyse personal characteristics about you.
              </Typography>
              <br />
              <Typography>
                At the expiry of such periods, your Personal Information will be
                deleted or archived in compliance with applicable laws.
              </Typography>
              <br />
            </Box>
            <Typography variant="h6" className="bold">
              6. Cookies and Other Tracking Technologies
            </Typography>
            <Box ml={4}>
              <Typography>
                We and third-party partners, such as our advertising and
                analytics partners, collect data through cookies and other
                tracking technologies.{" "}
              </Typography>
              <br />
              <Typography>
                A "cookie" is a small text file that may be used, for example,
                to collect information about web-site activity. Some cookies and
                other technologies may serve to recall Personal Information
                previously indicated by a user. Most browsers allow you to
                control cookies, including whether or not to accept them and how
                to remove them.
              </Typography>
              <br />
              <Typography>
                we use cookies and similar technologies (e.g., pixels and ad
                tags) to collect data (e.g., device IDs) to recognize you and
                your device(s) on, off and across different services and devices
                where you have engaged with our Services.
              </Typography>
              <br />
              <Typography>
                You may set most browsers to notify you if you receive a cookie,
                or you may choose to block cookies with your browser, but please
                note that if you choose to erase or block your cookies, you will
                need to re-enter your original user ID and password to gain
                access to certain parts of the Platform.
              </Typography>
              <br />
              <Typography>
                Tracking technologies may record information such as Internet
                domain and host names; Internet protocol (IP) addresses; browser
                software and operating system types; clickstream patterns; and
                dates and times that our site is accessed. Our use of cookies
                and other tracking technologies allows us to improve our
                Platform and the overall website experience.
              </Typography>
              <br />
            </Box>
            <Typography variant="h6" className="bold">
              7. Other Important Information
            </Typography>{" "}
            <br />
            <Box ml={4}>
              <Typography variant="h6" className="bold">
                7.1 Lawful Bases for Processing
              </Typography>{" "}
              <br />
              <Box ml={4}>
                <Typography>
                  The Basis/ Grounds which we rely on for collection and
                  processing of your Personal Information:
                </Typography>
                <br />
                <Typography>
                  makenewindia.com will only collect and process personal data
                  about you based on the following legal grounds depending upon
                  the nature of Personal Information and the purposes for which
                  it is processed.
                </Typography>
                <br />
                <Typography>
                  <b>•</b> Consent: Where we rely on your consent in order to
                  process your Personal Information in certain situations, you
                  have the right to withdraw or decline your consent at any time
                  and where we rely on legitimate interests, you have the right
                  to object. If makenewindia.com requires your consent to
                  collect and process certain Personal Information, as per the
                  requirements under the applicable data protection laws, your
                  consent is sought at the time of collection of your Personal
                  Information and such processing will only be performed where
                  consent is secured.
                </Typography>
                <br />
                <Typography>
                  <b>•</b> Compliance with a legal obligation:Your Personal
                  Information may be processed by makenewindia.com, to the
                  extent that such processing is necessary to allow
                  makenewindia.com to comply with a legal obligation. An example
                  of this would be if mkenewindia.com is required to disclose
                  your Personal Information to respond to a court order or if
                  makenewindia.com is required to retain specific records for a
                  fixed period to comply with requirements under any applicable
                  law.
                </Typography>
                <br />
              </Box>
              <Typography variant="h6" className="bold">
                7.2 Makenewindia.com Account Closure
              </Typography>{" "}
              <br />
              <Box ml={4}>
                <Typography>
                  If you choose to close your account, your personal data will
                  generally stop being visible to others on our Services within
                  24 hours. We generally delete closed account information
                  within 30 days of account closure, except as noted below.
                </Typography>
                <br />
                <Typography>
                  We retain your personal data even after you have closed your
                  account if reasonably necessary to comply with our legal
                  obligations (including law enforcement requests), meet
                  regulatory requirements, resolve disputes, maintain security,
                  prevent fraud and abuse (e.g., if we have restricted your
                  account for breach of our Professional Community Policies),
                  enforce our User Agreement, or fulfill your request to
                  "unsubscribe" from further messages from us. We will retain
                  de-personalized information after your account has been
                  closed.
                </Typography>
                <br />
                <Typography>
                  Information you have shared with others (e.g., through InMail,
                  updates or group posts) will remain visible after you close
                  your account or delete the information from your own profile
                  or mailbox, and we do not control data that other users have
                  copied out of our Services. Groups content and ratings or
                  review content associated with closed accounts will show an
                  unknown user as the source. Your profile may continue to be
                  displayed in the services of others (e.g., search engine
                  results) until they refresh their cache.
                </Typography>
                <br />
              </Box>
              <Typography variant="h6" className="bold">
                7.3 Links to Third Party Websites
              </Typography>{" "}
              <br />
              <Box ml={4}>
                <Typography>
                  Please be aware that the Platform sometimes contains links to
                  third-party websites that are not governed by this Privacy
                  Policy. Users may be directed to third-party sites for more
                  information, such as advertisers, blogs, content sponsorships,
                  vendor services, social networks, etc. These links are
                  provided solely as convenience to you and the presence of
                  these links shall not under any circumstances be considered as
                  an endorsement of the contents of the same by the Company
                </Typography>
                <br />
                <Typography>
                  Makenewindia.com makes no representations or warranties
                  regarding how your information is stored or used on
                  third-party servers. We recommend that you review the
                  applicable privacy statements and policies of each third-party
                  site linked from the Platform to determine their use of your
                  personal information.
                </Typography>
                <br />
              </Box>
              <Typography variant="h6" className="bold">
                7.4 Changes in Privacy Policy
              </Typography>{" "}
              <br />
              <Box ml={4}>
                <Typography>
                  Maknewindia.com reserves the right to update, change or modify
                  this Privacy Policy at any time. If we make a material change
                  to our privacy policy, we will send an email communication
                  about the update, so you are always aware of what information
                  we collect, how we use it, and under what circumstances, if
                  any, we disclose it. If at any point we decide to use
                  personally identifiable information in a manner materially
                  different from that stated at the time it was collected, we
                  will notify you by way of an email. We will give you a choice
                  as to whether or not we can use information in this different
                  manner and act accordingly. The Privacy Policy shall come to
                  effect from the date of publication of such update, change or
                  modification.
                </Typography>
                <br />
              </Box>
              <Typography variant="h6" className="bold">
                7.5 Credit / Debit Card related information
              </Typography>{" "}
              <br />
              <Box ml={4}>
                <Typography>
                  Makenewindia.com does not store any account related
                  information or any credit / debit card details.
                  Makenewindia.com shall not be liable for any loss or damage
                  sustained by Users as a result of any disclosure (inadvertent
                  or otherwise) of any information concerning the User's
                  account, credit cards or debit cards in the course of any
                  online transactions or payments made for any products and/or
                  services offered through the Platform.
                </Typography>
                <br />
                <Typography>
                  In case any Personal Information is shared by you with
                  makenewindia.com, which is not requested by makenewindia.com
                  during registration, (whether mandatorily or optionally),
                  makenewindia.com will not be liable for any information
                  security breach or disclosure in relation to such information.{" "}
                </Typography>
                <br />
              </Box>
              <Typography variant="h6" className="bold">
                7.6 Grievance/Date protection Officer
              </Typography>{" "}
              <br />
              <Box ml={4}>
                <Typography>
                  In case you have any questions about this privacy statement,
                  the practices of this site, or your dealings with this Web
                  site or you have any complaints and/or grievances and/or
                  discrepancies in relation to the processing of your Personal
                  Information you can send your complaints via e-mail to our
                  grievance/data protection officer:
                  <Link sx={{ cursor: "pointer" }}>
                    support@makenewindia.com
                  </Link>
                </Typography>
                <br />
                <Typography>
                  The Grievance Officer shall redress all the grievances
                  expeditiously but within 1(one) month from the date of receipt
                  of the grievance as provided under the Act.
                </Typography>
                <br />
              </Box>
            </Box>
          </Box>
        </Typography>
      </Box>
    </Box>
  );
};

export default PrivacyPolicy;
