import React, { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useSelector } from "react-redux";
import {
  getProfileStartup,
  getStartupDetailsFromInvestor,
} from "../../store/login/actions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Spinner3Dots } from "../../Extras/Spinner";
import profileLogo from "./img/profile.jpeg";
import companyLogo from "./img/company.webp";
import MessageModal from "./MessageModal";

export const ChatProfileModal = ({
  senderId,
  time,
  passChildData,
  passChildFlag,
}: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showMessages, setShowMessages] = useState(false);

  useEffect(() => {
    if (
      sessionStorage.getItem("type") === "investor" ||
      localStorage.getItem("type") === "investor"
    ) {
      dispatch(getStartupDetailsFromInvestor(senderId));
    }
    if (
      sessionStorage.getItem("type") === "startup" ||
      localStorage.getItem("type") === "startup"
    ) {
      dispatch(getProfileStartup(senderId));
    }
  }, []);

  const profileArray: any = useSelector<any>(
    (state) => state.loginReducerHandler?.recentChatProfile
  );

  let name = "";
  let profilePicture = "";
  let placeholderLogo = "";
  if (
    sessionStorage.getItem("type") === "startup" ||
    localStorage.getItem("type") === "startup"
  ) {
    const profileSet = new Set();
    const uniqueProfile = profileArray.filter((data: { uId: any }) => {
      if (profileSet.has(data.uId)) {
        return false;
      }
      profileSet.add(data.uId);
      return true;
    });

    const profileData = uniqueProfile.find(
      (element: any) => element.uId === senderId
    );
    name = profileData?.name;
    profilePicture = profileData?.profilePicture;
    placeholderLogo = profileLogo;
  }
  if (
    sessionStorage.getItem("type") === "investor" ||
    localStorage.getItem("type") === "investor"
  ) {
    const profileSet = new Set();
    const uniqueProfile = profileArray.filter((data: { userId: any }) => {
      if (profileSet.has(data?.userId)) {
        return false;
      }
      profileSet.add(data?.userId);
      return true;
    });

    const profileData = uniqueProfile.find(
      (element: any) => element?.userId === senderId
    );
    name = profileData?.companyName;
    profilePicture = profileData?.profilePicture;
    placeholderLogo = companyLogo;
  }

  const handleImgError = (e: any) => {
    if (localStorage.getItem("type") === "investor") e.target.src = companyLogo;
    if (localStorage.getItem("type") === "startup") e.target.src = profileLogo;
  };

  const handleRedirect = (e: any) => {
    e.preventDefault();

    navigate("/chat", { state: senderId });
  };

  useEffect(() => {
    if (name === undefined) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [name]);

  if (isLoading) {
    return (
      <div style={{ width: "100vw" }}>
        <Spinner3Dots message="" />
      </div>
    );
  }

  return (
    <>
      <Button
        style={{
          borderBottom: "0.1px solid #ccc",
          display: "flex",
          width: "100%",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          color: "white",
        }}
        onClick={() => {
          // setShowMessages(true);
          passChildFlag(true);
          passChildData(senderId);
        }}
        // className="btn-ChatSectionModal"
      >
        <Grid style={{ marginLeft: 2, marginTop: 2 }}>
          <img
            src={profilePicture ? profilePicture : companyLogo}
            onError={handleImgError}
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              objectFit: "cover",
              backgroundColor: "white",
            }}
            alt="profileImage"
            // className="main-comp-logo-nav"
          />
        </Grid>
        <Grid item xs={8}>
          <Typography
            variant="body2"
            sx={{
              color: "white",
              marginLeft: 2,
              marginTop: 1.5,
            }}
          >
            {name}
          </Typography>
        </Grid>
      </Button>
      {/* {showMessages && (
        <MessageModal senderId={senderId} flagFromChild={setShowMessages} />
      )} */}
    </>
  );
};
