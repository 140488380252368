import React from "react";
import correct from "./img/correct.png";
import wrong from "./img/wrong.png";

function createData(image: any, name: string, active: string) {
  return { image, name, active };
}
export const investorBasic = [
  createData(<img src={correct} alt="test" />, "Zero commission on deal", "1"),
  createData(<img src={correct} alt="test" />, "Unlimited search results", "1"),
  createData(
    <img src={correct} alt="test" />,
    "Connect with limited startups",
    "1"
  ),
  createData(
    <img src={correct} alt="test" />,
    "Chat with limited startups",
    "1"
  ),
  createData(
    <img src={wrong} alt="test" />,
    "Connect with unlimited startups",
    "0"
  ),
  createData(
    <img src={wrong} alt="test" />,
    "Chat with unlimited startups",
    "0"
  ),
  createData(
    <img src={wrong} alt="test" />,
    "Contact details of startups",
    "0"
  ),
  createData(
    <img src={wrong} alt="test" />,
    "Business Details of startups",
    "0"
  ),
  createData(<img src={wrong} alt="test" />, "Email notifications", "0"),
  createData(<img src={wrong} alt="test" />, "Tagged as premium", "0"),
];
export const investorPremium = [
  createData(<img src={correct} alt="test" />, "Zero commission on deal", "1"),
  createData(<img src={correct} alt="test" />, "Unlimited search results", "1"),
  createData(
    <img src={correct} alt="test" />,
    "Connect with unlimited startups",
    "1"
  ),
  createData(
    <img src={correct} alt="test" />,
    "Chat with unlimited startups",
    "1"
  ),
  createData(
    <img src={correct} alt="test" />,
    "Contact details of startups",
    "1"
  ),
  createData(
    <img src={correct} alt="test" />,
    "Business Details of startups",
    "1"
  ),
  createData(<img src={correct} alt="test" />, " Email notifications", "1"),
  createData(<img src={correct} alt="test" />, "Tagged as premium", "1"),
  createData(<img src={correct} alt="test" />, " No long term commitment", "1"),
  createData(
    <img src={correct} alt="test" />,
    "Zero cancellation Charges",
    "1"
  ),
];

export const startupBasic = [
  createData(<img src={correct} alt="test" />, "Zero commission on deal", "1"),
  createData(<img src={correct} alt="test" />, "Unlimited search results", "1"),
  createData(
    <img src={correct} alt="test" />,
    "Connect with limited Investors",
    "1"
  ),
  createData(
    <img src={correct} alt="test" />,
    "Chat with limited Investors",
    "1"
  ),
  createData(
    <img src={wrong} alt="test" />,
    "Connect with unlimited Investors",
    "0"
  ),
  createData(
    <img src={wrong} alt="test" />,
    "Chat with unlimited Investors",
    "0"
  ),
  createData(<img src={wrong} alt="test" />, "Email notifications", "0"),
  createData(<img src={wrong} alt="test" />, "Tagged as premium", "0"),
];

export const startupPremium = [
  createData(<img src={correct} alt="test" />, "Zero commission on deal", "1"),
  createData(<img src={correct} alt="test" />, "Unlimited search results", "1"),
  createData(
    <img src={correct} alt="test" />,
    "Connect with unlimited Investors",
    "1"
  ),
  createData(
    <img src={correct} alt="test" />,
    "Chat with unlimited Investors",
    "1"
  ),
  createData(<img src={correct} alt="test" />, "Email notifications", "1"),
  createData(<img src={correct} alt="test" />, "Tagged as premium", "1"),
  createData(<img src={correct} alt="test" />, "No long term commitment", "1"),
  createData(
    <img src={correct} alt="test" />,
    "Zero cancellation Charges",
    "1"
  ),
];
