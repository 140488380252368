// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./img/searchbox.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".path-container {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  margin: 20px 40px;\n}\n.opacity {\n  opacity: 66%;\n}\n.search-main-container {\n  padding: 10px 0px 40px 32px;\n  align-items: center;\n}\n\n.mr {\n  margin-right: 20px !important;\n}\n\n.search {\n  width: 100% !important;\n  text-decoration: none !important;\n}\n\n@media only screen and (max-width: 1200px) {\n  .search {\n    margin-top: 2rem !important;\n    width: 50% !important;\n  }\n}\n\n@media only screen and (max-width: 600px) {\n  .search {\n    margin-top: 2rem !important;\n    width: 100% !important;\n  }\n  .path-container {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    margin: 20px 0px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/elements/DashBoard/UI/SearchBox/searchbox.css"],"names":[],"mappings":"AAAA;EACE,yDAA4C;EAC5C,iBAAiB;AACnB;AACA;EACE,YAAY;AACd;AACA;EACE,2BAA2B;EAC3B,mBAAmB;AACrB;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,sBAAsB;EACtB,gCAAgC;AAClC;;AAEA;EACE;IACE,2BAA2B;IAC3B,qBAAqB;EACvB;AACF;;AAEA;EACE;IACE,2BAA2B;IAC3B,sBAAsB;EACxB;EACA;IACE,yDAA4C;IAC5C,gBAAgB;EAClB;AACF","sourcesContent":[".path-container {\n  background-image: url(\"./img/searchbox.svg\");\n  margin: 20px 40px;\n}\n.opacity {\n  opacity: 66%;\n}\n.search-main-container {\n  padding: 10px 0px 40px 32px;\n  align-items: center;\n}\n\n.mr {\n  margin-right: 20px !important;\n}\n\n.search {\n  width: 100% !important;\n  text-decoration: none !important;\n}\n\n@media only screen and (max-width: 1200px) {\n  .search {\n    margin-top: 2rem !important;\n    width: 50% !important;\n  }\n}\n\n@media only screen and (max-width: 600px) {\n  .search {\n    margin-top: 2rem !important;\n    width: 100% !important;\n  }\n  .path-container {\n    background-image: url(\"./img/searchbox.svg\");\n    margin: 20px 0px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
