import { Divider, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import "./swot.css";

const Swot = () => {
  const startUpData: any = useSelector<any>(
    (state) => state.startupReducer?.login[0]
  );
  return (
    <Box className="border swot-container">
      <Grid container p={5}>
        {[
          { title: "Strengths", amt: startUpData?.strength },
          { title: "Weakness", amt: startUpData?.weakness },
          { title: "Opportunities", amt: startUpData?.opportunities },
          { title: "Threats", amt: startUpData?.threats },
        ].map((item, index) => (
          <>
            <Grid item xs={12} className="details-items" key={index}>
              <Typography variant="body1" className="bold grey">
                {item?.title}
              </Typography>
              <Typography
                // variant="h6"
                className="bold detailsTextSize"
                gutterBottom
              >
                {item?.amt === undefined || item?.amt === "undefined"
                  ? "Not Available"
                  : item?.amt}
              </Typography>
              {index !== 3 ? <Divider className="divider mt" /> : null}
            </Grid>
          </>
        ))}
      </Grid>
    </Box>
  );
};

export default Swot;
