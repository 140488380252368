import React, { useState, useContext } from "react";
import "./Navbar.css";
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Button,
  Toolbar,
  Typography,
  Grid,
  Avatar,
  Badge,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import navLogo from "../Navbar-Homepage/img/navLogo.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SearchIcon from "@mui/icons-material/Search";
import { Link as Links, Navigate, useNavigate } from "react-router-dom";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import MailIcon from "@mui/icons-material/Mail";
import HomeIcon from "@mui/icons-material/Home";
import NotificationsIcon from "@mui/icons-material/Notifications";
import GoogleIcon from "@mui/icons-material/Google";
// import googleLogo from "../img/googleLogo.png";
import googleLogo from "../GoogleSlider/img/googleLogo.png";
import NotificationCard from "../StartupComponents/NotificationList/NotificationCard/NotificationCard";
import UserCard from "../StartupComponents/UserCard/UserCard";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getSearchResultS } from "../../store/startup/actions";
import { getSearchResult, getSearchResultI } from "../../store/login/actions";
import { NotificationContext } from "../MainLayout/index";

interface Props {
  window?: () => Window;
}

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "orange",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "30ch",
    },
  },
}));

interface State {
  name: any;
  location: any;
}

export const Navbar = (props: Props) => {
  const userName: any = useSelector<any>(
    (state) => state.loginReducerHandler?.login[0]?.name
  );
  const profileImage: any = useSelector<any>(
    (state) => state.loginReducerHandler?.login[0]?.profilePicture
  );
  const companyName: any = useSelector<any>(
    (state) => state.startupReducer?.login[0]?.companyName
  );
  const startupData: any = useSelector<any>(
    (state) => state.startupReducer?.login[0]
  );
  const investorData: any = useSelector<any>(
    (state) => state.loginReducerHandler?.login[0]
  );

  let toWhere = "";

  const type: any = useSelector<any>((state) => state.loginReducerHandler.type);
  const type2: any = useSelector<any>((state) => state.startupReducer.type);

  let name = "";
  if (
    localStorage.getItem("type") === "investor"
    // || sessionStorage.getItem("type") === "investor"
  ) {
    name = userName;
    toWhere = "/dashboard";
  }
  if (
    localStorage.getItem("type") === "startup"
    // || sessionStorage.getItem("type") === "startup"
  ) {
    name = companyName;
    toWhere = "/startup-dashboard";
  }

  const [userShow, setUserShow] = useState(false);
  const [notification, setUpadteNotification] = useState(false);
  const { isNotification, setNotification } = useContext(NotificationContext);

  // const [visible, setVisible] = useState(false);
  const [values, setValues] = React.useState<State>({
    name: "",
    location: "",
  });
  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const handleNavigate = (e: any) => {
    if (values?.name === "") {
      return null;
    } else if (e.key === "Enter" && type2 === "startup") {
      dispatch(getSearchResultS(values));
      navigate("/search-result-startup");
    } else if (e.key === "Enter" && type === "investor") {
      dispatch(getSearchResultI(values));
      navigate("/search-result");
    }
  };

  let notificationArray = [];
  let notificationCount = 0;

  if (localStorage.getItem("type") === "investor")
    notificationArray = investorData?.notification;
  if (localStorage.getItem("type") === "startup")
    notificationArray = startupData?.notification;

  notificationArray?.map((notification: any) => {
    if (notification?.isRead === false) notificationCount++;
  });

  const handleChangeImage: any = {};
  return (
    <>
      <AppBar component="nav" className="app-bar">
        <Toolbar sx={{ backgroundColor: "#fff", borderBottom: "none" }}>
          <Box
            className="navbar-home"
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              alt="Test"
              className="logo-navbar"
              src={navLogo}
            />

            <Box sx={{ display: { sm: "flex" } }}>
              <Search
                onChange={handleChange("name")}
                onKeyDown={handleNavigate}
              >
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  // disabled={type === "investor" ? true : false}
                  placeholder={
                    type === "investor"
                      ? "Search Startup by Name or Location"
                      : "Search Investor by Name or Location"
                  }
                  inputProps={{ "aria-label": "search" }}
                />
              </Search>
            </Box>

            <Box
              className="grid xs-none iconOuterBox"
              sx={{
                display: "flex",
                justifyContent: "center",
                position: { sm: "unset", xs: "absolute" },
                top: "45px",
              }}
            >
              <Link to={toWhere} style={{ textDecoration: "none" }}>
                <Box>
                  <IconButton
                    size="large"
                    aria-label="show 4 new mails"
                    color="success"
                    className="iconBtnStyle"
                  >
                    <Badge>
                      <Box>
                        <HomeIcon className="iconSize" />
                        <Typography
                          sx={{ color: "#000000" }}
                          className="navbarIcons"
                        >
                          Home
                        </Typography>
                      </Box>
                    </Badge>
                  </IconButton>
                </Box>
              </Link>
              <Box>
                <IconButton
                  onMouseOver={() => {
                    setUpadteNotification(false);
                    setNotification("click");
                  }}
                  size="large"
                  aria-label="show 4 new mails"
                  color="success"
                  className="iconBtnStyle"
                >
                  {" "}
                  <Badge>
                    <Link to="/chat" style={{ textDecoration: "none" }}>
                      <Box>
                        <MailIcon className="iconSize" color="success" />
                        <Typography
                          sx={{ color: "#000000" }}
                          className="navbarIcons"
                        >
                          Message
                        </Typography>
                      </Box>
                    </Link>
                  </Badge>
                </IconButton>
              </Box>
              <Box>
                <IconButton
                  size="large"
                  aria-label="show 17 new notifications"
                  color="success"
                  className="iconBtnStyle"
                  onClick={() => {
                    setUpadteNotification(!notification);
                    setNotification("click");
                  }}
                  onMouseOver={() => {
                    setUpadteNotification(true);
                    setNotification("click");
                  }}
                >
                  <Box>
                    <Badge
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      badgeContent={notificationCount}
                      color="warning"
                      max={99}
                    >
                      <NotificationsIcon className="iconSize" />
                    </Badge>
                    <Typography
                      sx={{ color: "#000000" }}
                      className="navbarIcons"
                    >
                      Notification
                    </Typography>
                  </Box>
                </IconButton>
              </Box>
            </Box>

            <Box>
              <Box className="profile-nav">
                <Button
                  onMouseOver={() => {
                    setUserShow(true);
                    setUpadteNotification(false);
                    setNotification("click");
                  }}
                  onMouseLeave={() => setUserShow(false)}
                  className="startup-btn"
                  // startIcon={<GoogleIcon sx={{ ml: 1 }} />}

                  startIcon={
                    <Avatar
                      src={
                        startupData?.profilePicture
                          ? startupData?.profilePicture
                          : investorData?.profilePicture
                          ? investorData?.profilePicture
                          : "/broken-image.jpg"
                      }
                      // src={() => handleChangeImage}
                      alt="test"
                      className="main-comp-logo-nav"
                    />
                  }
                  endIcon={
                    <ExpandMoreIcon
                      className="green icon-none"
                      sx={{ ml: 4 }}
                    />
                  }
                  sx={{ md: { px: 4, py: 1.3 } }}
                >
                  <Typography className="icon-none" sx={{ color: "black" }}>
                    {name}
                  </Typography>
                  {userShow && <UserCard />}
                </Button>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      {(notification || isNotification === "delete") && (
        <NotificationCard stateChange={setUpadteNotification} />
      )}
    </>
  );
};
