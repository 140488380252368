import { DataArrayRounded } from "@mui/icons-material";

export const GET_LOGIN_FETCH = "GET_LOGIN_FETCH";
export const GET_LOGIN_SUCCESS = "GET_LOGIN_SUCCESS";
export const GET_LOGIN_FAIL = "GET_LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const SUBMIT_SUPPORT_FORM = "SUBMIT_SUPPORT_FORM";
export const GETSEARCH_I = "GETSEARCH_I";
export const CHANGE_PASSWORD_INVESTOR = "CHANGE_PASSWORD_INVESTOR";
export const FORGET_PASSWORD_INVESTOR = "FORGET_PASSWORD_INVESTOR";
export const SEND_MESSAGES = "SEND_MESSAGES";
export const FETCH_MESSAGES = "FETCH_MESSAGES";
export const STORE_MESSAGES = "STORE_MESSAGES";
export const ACCEPT_REQUEST_OF_STARTUP = "ACCEPT_REQUEST_OF_STARTUP";
export const GETALLSTARTUPS = "GETALLSTARTUPS";
export const GET_ALL_STARTUPS = "GET_ALL_STARTUPS";
export const GET_STARTUP_DETAILS_FROM_INVESTOR =
  "GET_STARTUP_DETAILS_FROM_INVESTOR";
export const GET_STARTUP_DETAILS_SUCCESS_FROM_INVESTOR =
  "GET_STARTUP_DETAILS_SUCCESS_FROM_INVESTOR";

export const getLoginFetch = (values: any) => ({
  type: GET_LOGIN_FETCH,
  payload: values,
});
export const sendMessages = (values: any) => ({
  type: SEND_MESSAGES,
  payload: values,
});
export const fetchMessages = (values: any) => ({
  type: FETCH_MESSAGES,
  payload: values,
});
export const getStartupDetailsFromInvestor = (id: any) => {
  return {
    type: "GET_STARTUP_DETAILS_FROM_INVESTOR",
    payload: id,
  };
};

export const logout = () => ({
  type: LOGOUT,
});

export const getProfile = (id: any) => {
  return {
    type: "GETPROFILE",
    payload: id,
  };
};

export const getProfileForRequestList = (id: any) => {
  return {
    type: "GET_PROFILE_REQUEST_LIST",
    payload: id,
  };
};
export const acceptRequestOfStartup = (values: any) => {
  return {
    type: ACCEPT_REQUEST_OF_STARTUP,
    payload: values,
  };
};

export const getStartupForRequestList = (id: any) => {
  return {
    type: "GET_STARTUP_REQUEST_LIST",
    payload: id,
  };
};
export const fetchRecentChats = (id: any) => {
  return {
    type: "FETCH_RECENT_CHATS",
    payload: id,
  };
};
//------------------GET STARTUP PROFILE ------------------------
export const getProfileStartup = (id: any) => {
  return {
    type: "GETPROFILESTARTUP",
    payload: id,
  };
};
//----------------------------------------------------------------------
export const topRisingStartup = () => {
  return {
    type: "TOPRISING",
  };
};

export const getGoogleHomepage = () => {
  return {
    type: "GOOGLEHOMEPAGEAPI",
  };
};
export const getBlogs = () => {
  return {
    type: "GETBLOG",
  };
};

export const getRecomendedStartups = () => {
  return {
    type: "GETRECOMENDEDSTARTUPS",
  };
};
export const getAllStartups = () => {
  return {
    type: GETALLSTARTUPS,
  };
};
export const submitSupportForm = (values: any) => {
  return {
    type: "SUBMIT_SUPPORT_FORM",
    payload: values,
  };
};
export const changePasswordInvestor = (data: any, id: any) => {
  return {
    type: "CHANGE_PASSWORD_INVESTOR",
    payload: { data, id },
  };
};

export const saveChoosenData = (data: any) => {
  return {
    type: "SAVE_CHOOSEN_DATA",
    payload: data,
  };
};
export const getPeopleOfStartup = (id: any) => {
  return {
    type: "GET_PEOPLE",
    payload: id,
  };
};
export const saveChoosenStartupData = (data: any) => {
  return {
    type: "SAVE_CHOOSENSTARTUP_DATA",
    payload: data,
  };
};

export const saveRecentlyViewed = (data: any) => {
  return {
    type: "RECENTLY_VIEWED",
    payload: data,
  };
};

export const updateInvestorProfile = (data: any, id: any, imgState: any) => {
  return {
    type: "UPDATE_PROFILE",
    payload: { data, id, imgState },
  };
};

//In investor flow's search -->>
export const getSearchResult = (values: any) => {
  return {
    type: "GETSEARCH",
    payload: values,
  };
};
export const getSearchResultI = (values: any) => {
  return {
    type: GETSEARCH_I,
    payload: values,
  };
};

export const forgetPasswordInvestor = (data: any) => {
  return {
    type: FORGET_PASSWORD_INVESTOR,
    payload: data,
  };
};
