import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getPeopleOfStartup } from "../../../../store/login/actions";
import SideBar from "../../../Profile/UI/SideBar";
import CompanyDescription from "../CompanyDescription";
import picture from "./img/teamPH.png";
import "./people.css";
import LockIcon from "@mui/icons-material/Lock";
import { Link } from "react-router-dom";

const People = (props: any) => {
  const dispatch = useDispatch();

  const details = useSelector((state: any) => {
    return state.loginReducerHandler.choosenData;
  });

  useEffect(() => {
    dispatch(getPeopleOfStartup(details?.userId));
  }, []);

  const peopleArray = useSelector((state: any) => {
    return state.loginReducerHandler.peoples;
  });

  return (
    <>
      {props.userSubscription === "basic" ? (
        <>
          <Box
            display="flex"
            gap={2}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{p:"4rem 0"}}
          >
            <LockIcon fontSize="large" />
            <Link to={"/pricing-investor"} style={{ textDecoration: "none" }}>
              <Typography variant="h4" color={"black"}>
                Unlock Premium To See
              </Typography>
            </Link>
          </Box>
        </>
      ) : (
        <Grid
          container
          pb={"2rem"}
          className="people-main-container"
          rowGap={{ xs: 5 }}
        >
          {peopleArray?.length === 0 ? (
            <Box pb={"10rem"}>
              <h1>No Data Found</h1>
            </Box>
          ) : (
            peopleArray?.map((item: any, index: any) => (
              <Grid
                item
                xs={12}
                sm={4}
                md={3}
                className="people-box bg-grey"
                key={index}
                pt={"2rem"}
              >
                <Box className="align">
                  <img src={picture} className="people-width" />
                </Box>
                <Box className="people-text">
                  <Typography variant="caption">{item?.position}</Typography>
                  <Typography variant="h5" className="bold" gutterBottom>
                    {item?.name}
                  </Typography>
                </Box>
              </Grid>
            ))
          )}
        </Grid>
      )}
    </>
  );
};

export default People;
