import React, { useState, useEffect } from "react";
import { Box, Grid, Toolbar, Typography } from "@mui/material";
import "../../DashBoard/UI/DashboardMenu/DashboardMenu.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { StartupCardHome } from "../StartupHomePage/StartupHomePage";
import CompanyProfileStartup from "../../StartupComponents/ProfilePage/CompanyProfile";
import NetWorkStartup from "../../StartupComponents/Network-Startup";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import { useDispatch } from "react-redux";
import { getStartupDetails } from "../../../store/startup/actions";
import {
  getInvestorProfile,
  getProfileInv,
} from "../../../store/startup/actions";
import Spinner from "../../../Extras/Spinner";
import PremiumPop from "../../PremiumPopUp/PremiumPop";

const StartupDashboard = () => {
  document.title = "Make new India | Dashboard";
  const dispatch = useDispatch();
  const [active, setActive] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShown, setModalShown] = useState(false);
  const [ isOpen , setOpen] = useState(false)

  const profileSubscription = useSelector<any>(
    (state) => state.startupReducer?.login[0]?.subscription
  );

  useEffect(() => {
    const hasShownModal = localStorage.getItem('hasShownModal');
    if (!hasShownModal)  {

      setOpen(true)
      // setModalShown(true);
      localStorage.setItem('hasShownModal', 'true');
    }
  }, []);

  const onClose = () => {
    setOpen(false)
    
  }
  useEffect(() => {
    if (!localStorage.getItem("profile")) navigate("/", { replace: true });
  }, []);

  useEffect(() => {
    const body = document.querySelector<any>("#root");

    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!localStorage.getItem("profile")) {
        alert("This profile has been logged out, Please Sign in again!");
        window.location.reload();
      }
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (localStorage.getItem("id"))
        dispatch(getStartupDetails(localStorage.getItem("id")));
      // if (sessionStorage.getItem("id"))
      //   dispatch(getStartupDetails(sessionStorage.getItem("id")));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const isLoggedIn = useSelector<any>(
    (state) => state.startupReducer?.isLoggedIn
  );
  let navigate = useNavigate();

  const data = useSelector((state: any) => {
    return state.startupReducer;
  });

  useEffect(() => {
    if (data === undefined) {
      setIsLoading(true);
    } else if (data?.investorProfile?.length === 0) {
      setIsLoading(true);
    } else if (data?.topRisingDataS?.length === 0) {
      setIsLoading(true);
    } else if (data?.login?.length === 0) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [data, data?.investorProfile, data?.topRisingDataS, data?.login]);

  if (isLoading) {
    return (
      <div style={{ height: "70vh" }}>
        <Spinner message="Fetching the Latest & Updated data for you, please wait..." />
      </div>
    );
  }
  return (
    <>
    {isOpen && profileSubscription === "basic" && (
 <PremiumPop isOpen={isOpen} onClose={onClose}  /> 
 )}
      <Grid sx={{ position: "relative" }}>
        <Toolbar
          className="book opacity"
          sx={{ fontSize: { md: "16px", sm: "14px", xs: "12px" }, ml: 2 }}
        >
          <Link
            to={"/"}
            style={{ textDecoration: "none", color: "#010101", opacity: "0.8" }}
          >
            HOME{" "}
          </Link>{" "}
          <ArrowForwardIosIcon sx={{ fontSize: { sm: "14px", xs: "12px" } }} />
          <Link
            to={"/startup-login"}
            style={{ textDecoration: "none", color: "#010101", opacity: "0.8" }}
          >
            LOGIN
          </Link>
        </Toolbar>

        <Typography
          sx={{
            position: { md: "absolute", xs: "unset" },
            fontSize: { sm: "42px", xs: "32px" },
            fontWeight: "600",
            ml: 4,
          }}
        >
          Startup
        </Typography>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item className="dashboardMain">
          {["Dashboard", "Profile", "Network"].map((item, index) => (
            <Grid
              className="dashboard-inner bold"
              key={index}
              onClick={() => setActive(index)}
              sx={{
                backgroundColor: active === index ? "#FF9A33" : null,
                color: active === index ? "#FFFFFF" : null,
              }}
            >
              {item}
            </Grid>
          ))}
        </Grid>
      </Grid>

      {active === 0 ? (
        <StartupCardHome />
      ) : active === 1 ? (
        <CompanyProfileStartup />
      ) : (
        <NetWorkStartup />
      )}
    </>
  );
};

export default StartupDashboard;
