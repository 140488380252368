import { Box, Button, Grid, Modal, Popper, Typography } from "@mui/material";
import googleround from "./img/googleround.png";
import "./requestcard.css";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import NoteCard from "../NoteCard";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getProfileForRequestList } from "../../../../../store/login/actions";
import { acceptRequestOfInvestor } from "../../../../../store/startup/actions";

const style = {
  position: "absolute" as "absolute",
  top: "70%",
  left: "30%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "1px solid #7070702E",
  boxShadow: 24,
  p: 4,
};
const RequestCardStartup = ({ investorId }: any) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProfileForRequestList(investorId));
  }, []);

  const requestInformation = useSelector((state: any) => {
    return state.startupReducer?.requests;
  });

  const requestSet = new Set();
  const uniqueRequests = requestInformation.filter((data: { uId: any }) => {
    if (requestSet.has(data.uId)) {
      return false;
    }
    requestSet.add(data.uId);
    return true;
  });

  const investorData = uniqueRequests.find(
    (element: any) => element.uId === investorId
  );

  const [requestStatusA, setRequestStatusA] = useState("Accept");
  const [requestStatusR, setRequestStatusR] = useState("Reject");
  const [changeColor, setChangeColor] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const startupId = useSelector((state: any) => {
    return state.startupReducer?.login[0]?.userId;
  });

  const acceptHandler = () => {
    let obj = {
      startupId,
      investorId,
      status: "accept",
    };

    dispatch(acceptRequestOfInvestor(obj));
    setRequestStatusA("Accepted");
    setRequestStatusR("");
    setChangeColor(true);
  };
  const rejectHandler = () => {
    let obj = {
      startupId,
      investorId,
      status: "reject",
    };

    dispatch(acceptRequestOfInvestor(obj)); // same is also used for rejecting with status = reject
    setRequestStatusA("");
    setRequestStatusR("Rejected");
    setChangeColor(true);
  };

  return (
    <Grid container mt={2} p={2} className="bg-white">
      <Grid item xs={2} sm={2} md={2} xl={2} lg={2}>
        <Box component="img" src={googleround} className="max-width img-box" />
      </Grid>
      <Grid item xs={6} className="company-desc-container">
        <Box sx={{ p: "10px" }}>
          <Typography className="size-s bold request-company-name" gutterBottom>
            {investorData?.name}
          </Typography>
          <Typography
            variant="body1"
            className="size-xs book grey request-company-location"
            gutterBottom
          >
            {investorData?.location}
          </Typography>

          <Typography
            variant="body1"
            className="size-xs bold  green open-note-align"
            component="div"
            onClick={handleClick}
          >
            Open Note <KeyboardArrowRightIcon />
          </Typography>
          <Popper
            id={anchorEl ? "simple-popper" : undefined}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            placement="bottom-end"
          >
            <Box sx={style}>
              <Typography
                variant="h6"
                component="div"
                className="note-card-container"
              >
                <ClearIcon
                  onClick={handleClose}
                  fontSize="large"
                  className="green"
                />{" "}
                <b>Note</b>
              </Typography>
              <Typography variant="body1" className="book" sx={{ mt: 2 }}>
                simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry's standard dummy text ever
                since the 1500s, when an unknown printer took a galley of type
                and scrambled it to make a type specimen book.
              </Typography>
              <Box className="modal-btn-align">
                <Button
                  onClick={handleClose}
                  className="modal-reject-btn btn green"
                  size="small"
                >
                  Reject
                </Button>
                <Button
                  className="modal-accept-btn btn bg-green white"
                  size="small"
                >
                  Accept
                </Button>
              </Box>
            </Box>
          </Popper>
        </Box>
      </Grid>
      <Grid
        item
        xs={4}
        display={{ xs: "block", md: "flex" }}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Button
          disabled={changeColor}
          onClick={rejectHandler}
          className={
            changeColor
              ? "accept-btn btn  red bg-white"
              : " accept-btn btn  green bg-white"
          }
          fullWidth
        >
          {requestStatusR}
        </Button>
        <Button
          disabled={changeColor}
          onClick={acceptHandler}
          className={
            changeColor
              ? "accept-btn btn bg-white green"
              : "accept-btn btn bg-green white"
          }
          fullWidth
        >
          {requestStatusA}
        </Button>
      </Grid>
    </Grid>
  );
};

export default RequestCardStartup;
