import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import "./UI/DashboardMenu/DashboardMenu.css";
import NetWork from "../NetWork";
import { Navigate, useNavigate } from "react-router-dom";

import DashBoardMain from "./UI/DashboardMenu/DashBoardMain";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfile,
  getRecomendedStartups,
  getLoginFetch,
} from "../../store/login/actions";
import Spinner from "../../Extras/Spinner";
import PremiumPop from "../PremiumPopUp/PremiumPop";

const Dashboard = () => {
  document.title = "Make new India | Dashboard";
  const navigate = useNavigate();
  const [active, setActive] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [display, setDisplay] = useState(false);
  const dispatch = useDispatch();
  const [modalShown, setModalShown] = useState(false);
  const [ isOpen , setOpen] = useState(false)

  const profileSubscription = useSelector<any>(
    (state) => state.loginReducerHandler?.profile?.subscription
  );
 
  useEffect(() => {
 
    const hasShownModal = localStorage.getItem('hasShownModal');
    if (!hasShownModal && !isLoading) {
      setOpen(true)
      // setModalShown(true);
      localStorage.setItem('hasShownModal', 'true');
    }
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("profile")) navigate("/", { replace: true });
  }, []);

  useEffect(() => {
    const body = document.querySelector<any>("#root");

    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
  }, []);
 
  useEffect(() => {
    const interval = setInterval(() => {
      if (!localStorage.getItem("profile")) {
        alert("This profile has been logged out, Please Sign in again!");
        window.location.reload();
      }
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (localStorage.getItem("id"))
        dispatch(getProfile(localStorage.getItem("id")));
      // if (sessionStorage.getItem("id"))
      //   dispatch(getProfile(sessionStorage.getItem("id")));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const data = useSelector((state: any) => {
    return state.loginReducerHandler;
  });

  useEffect(() => {
    if (data === undefined) {
      setIsLoading(true);
    } else if (data?.recomendedStartups?.length === 0) {
      setIsLoading(true);
    } else if (data?.topRisingData?.length === 0) {
      setIsLoading(true);
    } else if (data?.login?.length === 0) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [data, data.recomendedStartups, data.topRisingData, data?.login]);

  const isLoggedIn = useSelector<any>(
    (state) => state.loginReducerHandler.isLoggedIn
  );
  const profile = useSelector<any>((state) => state.loginReducerHandler);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getRecomendedStartups());
      dispatch(getProfile(data?.login[0]?.uId));
    }
  }, [isLoggedIn]);

  // useEffect(() => {
  //   if (!isLoggedIn) {
  //     navigate("/", { replace: true });
  //   }
  // }, [isLoggedIn]);

  // useEffect(() => {
  //   dispatch(getProfile("02021583-57e4-4695-9d98-915cdaa68fb1"));
  //   dispatch(getRecomendedStartups());
  // }, []);

  if (isLoading) {
    return (
      <div style={{ height: "70vh" }}>
        <Spinner message="Fetching the Latest & Updated data for you, please wait..." />
      </div>
    );
  }



const onClose = () => {
  setOpen(false)
  
}


  return (
    <>
    {isOpen && profileSubscription === "basic" && (
 <PremiumPop isOpen={isOpen} onClose={onClose}  /> 
 )}
      {active === 1 ? (
        <Navigate to="/profile" />
      ) : (
        <Grid container justifyContent="center" className="dashboardOuter">
          <Grid item className="dashboardMain">
            {["Dashboard", "Profile", "Network"].map((item, index) => (
              <Grid
                className="dashboard-inner bold"
                key={index}
                onClick={() => setActive(index)}
                sx={{
                  backgroundColor: active === index ? "#FF9A33" : null,
                  color: active === index ? "#FFFFFF" : null,
                }}
              >
                {item}
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
      {active === 0 ? <DashBoardMain /> : active === 2 ? <NetWork /> : <></>}
    </>
  );
};

export default Dashboard;
