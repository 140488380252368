import { Avatar, Divider, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import "./financial.css";

const Financial = () => {
  const startUpData: any = useSelector<any>(
    (state) => state.startupReducer?.login[0]
  );

  return (
    <>
      <Box className="financial-container ">
        <Grid container mt={6} className="border">
          {[
            {
              title: "Yearly Gross Revenue",
              amt:
                // startUpData?.yearlyGrossRevenue
                startUpData?.yearlyGrossRevenue >= 10000000
                  ? (startUpData?.yearlyGrossRevenue / 10000000).toFixed(2) +
                    " Cr"
                  : startUpData?.yearlyGrossRevenue >= 100000
                  ? +(startUpData?.yearlyGrossRevenue / 100000).toFixed(2) +
                    " L"
                  : startUpData?.yearlyGrossRevenue,
            },
            {
              title: "Yearly Net profit",
              amt:
                startUpData?.yearlyNetProfit >= 10000000
                  ? (startUpData?.yearlyNetProfit / 10000000).toFixed(2) + " Cr"
                  : startUpData?.yearlyNetProfit >= 100000
                  ? +(startUpData?.yearlyNetProfit / 100000).toFixed(2) + " L"
                  : startUpData?.yearlyNetProfit,
            },
            {
              title: "Last Quarter Gross Revenue",
              amt:
                startUpData?.lastQuarterGrossRevenue >= 10000000
                  ? (startUpData?.lastQuarterGrossRevenue / 10000000).toFixed(
                      2
                    ) + " Cr"
                  : startUpData?.lastQuarterGrossRevenue >= 100000
                  ? +(startUpData?.lastQuarterGrossRevenue / 100000).toFixed(
                      2
                    ) + " L"
                  : startUpData?.lastQuarterGrossRevenue,
            },
            {
              title: "Last Quarter net profits",
              amt:
                startUpData?.lastQuarterNetProfit >= 10000000
                  ? (startUpData?.lastQuarterNetProfit / 10000000).toFixed(2) +
                    " Cr"
                  : startUpData?.lastQuarterNetProfit >= 100000
                  ? +(startUpData?.lastQuarterNetProfit / 100000).toFixed(2) +
                    " L"
                  : startUpData?.lastQuarterNetProfit,
            },
            {
              title: "Last Month Gross Revenue",
              amt:
                startUpData?.lastMonthGrossRevenue >= 10000000
                  ? (startUpData?.lastMonthGrossRevenue / 10000000).toFixed(2) +
                    " Cr"
                  : startUpData?.lastMonthGrossRevenue >= 100000
                  ? +(startUpData?.lastMonthGrossRevenue / 100000).toFixed(2) +
                    " L"
                  : startUpData?.lastMonthGrossRevenue,
            },
            // { title: "Last Month Net Profit", amt: "90000" },
            {
              title: "Assets Value",
              amt:
                startUpData?.assetsValue >= 10000000
                  ? (startUpData?.assetsValue / 10000000).toFixed(2) + " Cr"
                  : startUpData?.assetsValue >= 100000
                  ? +(startUpData?.assetsValue / 100000).toFixed(2) + " L"
                  : startUpData?.assetsValue,
            },
            {
              title: "Liabilities Value",
              amt:
                startUpData?.liabilitiesValue >= 10000000
                  ? (startUpData?.liabilitiesValue / 10000000).toFixed(2) +
                    " Cr"
                  : startUpData?.liabilitiesValue >= 100000
                  ? +(startUpData?.liabilitiesValue / 100000).toFixed(2) + " L"
                  : startUpData?.liabilitiesValue,
            },
            {
              title: "Loans",
              amt:
                startUpData?.loans >= 10000000
                  ? (startUpData?.loans / 10000000).toFixed(2) + " Cr"
                  : startUpData?.loans >= 100000
                  ? +(startUpData?.loans / 100000).toFixed(2) + " L"
                  : startUpData?.loans,
            },
          ].map((item, index, array) => (
            <>
              <Grid item xs={12} className="details-items" key={index}>
                <Typography variant="body1" className="bold grey">
                  {item.title}
                </Typography>
                <Typography
                  variant="h3"
                  className="bold details-text-size"
                  gutterBottom
                >
                  {item?.amt === undefined || item?.amt === "undefined"
                    ? "Not Available"
                    : "₹" + item?.amt}
                  {/* {startUpData.equityOffer} */}
                </Typography>

                {index !== array.length - 1 ? (
                  <Divider className="divider mt" />
                ) : null}
              </Grid>
            </>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default Financial;
