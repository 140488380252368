import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  OutlinedInput,
  Typography,
} from "@mui/material";
import "./EditProfilePeople.css";
import React, { useEffect, useState } from "react";
import EditProfileCard from "../EditProfileCard/EditProfileCard";
import { data } from "../EditProfileCard/EditProfilecardArray";
import addnewimg from "../../../../Assets/image/addnewimg.svg";
import editimg from "../../../../Assets/image/editimg.png";
import teamPH from "../EditProfileCard/image/teamPH.png";
import { useDispatch } from "react-redux";
import {
  addStartupEmployee,
  // addStartupEmployee,
  updateEmployee,
} from "../../../../store/startup/actions";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
interface State {
  name: any;
  position: any;
  userId: any;
  companyId: any;
}
interface updateEmployee {
  name: any;
  position: any;
  userId: any;
}

export default function EditProfilePeople(props: any) {
  let navigate = useNavigate();
  const startupDataId: any = useSelector<any>(
    (state) => state.startupReducer?.login[0]
  );
  const startupDataEmpId: any = useSelector<any>(
    (state) => state.startupReducer?.people[0]
  );


  const employeeIdS: any = useSelector<any>(
    (state) => state.startupReducer?.people[0]?.userId
  );


  let { startUpData, id } = props;

  const [values, setValues] = useState<State>({
    name: startUpData?.name,
    position: startUpData?.position,
    userId: startUpData?.userId,
    companyId: startupDataId.userId,
  });

  // const [editValues, setEditValues] = useState<updateEmployee>({
  //   name: startUpData?.name,
  //   position: startUpData?.position,
  //   userId: employeeIdS?.userId,
  // });
  const [editValues, setEditValues] = useState<updateEmployee>({
    name: startupDataEmpId?.name,
    position: startupDataEmpId?.position,
    userId: startupDataEmpId?.userId,
  });

  const startUpDataP: any = useSelector<any>(
    (state) => state.startupReducer?.peopleArray
  );

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };
  const handleUpdate =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setVal({ ...val, [prop]: event.target.value });
    };

  props.receiveData(values);
  props.receiveData(editValues);

  const [edit, setEdit] = useState(false);

  const [val, setVal] = useState<any>();
  const [save, setSave] = useState(false);
  const [idEmp, setIdEmp] = useState("");

  const onHandleClick2 = () => {
    setSave(false);
    setEdit(false);

    dispatch(updateEmployee(val));
    updatePeopleToast();
    navigate("/startup-dashboard");
  };

  const onHandleClick = (test: any) => {
    setEdit(true);
    setVal(test);
  };

  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (values.name !== "" && values.position !== "") {
      dispatch(addStartupEmployee(values));
      addPeopleToast();
    }
  };
  const addPeopleToast = (toastHandler = toast) => {
    toastHandler.success("Employee Added Successfully!", {
      style: {
        background: "#04111d",
        color: "#fff",
      },
    });
  };
  const updatePeopleToast = (toastHandler = toast) => {
    toastHandler.success("Employee updated Successfully!", {
      style: {
        background: "#04111d",
        color: "#fff",
      },
    });
  };

  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />
      <Box sx={{ backgroundColor: "#dddddd", padding: "50px 30px" }}>
        <Container>
          <Grid container spacing={2}>
            {startUpDataP?.map((item: any, index: any) => (
              <Grid
                sx={{ display: "flex", justifyContent: "space-around" }}
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
              >
                <EditProfileCard
                  EditProfileCardProp={item}
                  key={index}
                  onHandleClick={onHandleClick}
                />
              </Grid>
            ))}
          </Grid>
          {!edit ? (
            <Box
              sx={{
                border: "1px solid #000000",
                padding: " 20px 30px",
                marginTop: "50px",
              }}
            >
              <Grid sx={{ margin: "10px" }} container>
                <Grid sm={4} md={2} lg={2} component="label">
                  <Typography sx={{ fontSize: "24px" }} className="textbold">
                    Add New
                  </Typography>

                  <input type="file" hidden />
                  <img
                    width={"100px"}
                    src={teamPH}
                    alt="addnew"
                    style={{ borderRadius: "50%" }}
                  />
                </Grid>
                <Grid sm={8} md={8} lg={8}>
                  <Grid sx={{ margin: "10px" }} container>
                    <Grid sm={12} md={8}>
                      <Typography className="textbold">Name</Typography>
                      <Box
                        sx={{ display: "display", justifyContent: "center" }}
                      >
                        <OutlinedInput
                          sx={{ backgroundColor: "#ffffff" }}
                          className="inputfeilds"
                          placeholder="Enter Full Name"
                          value={values?.name}
                          onChange={handleChange("name")}
                        />
                      </Box>
                    </Grid>
                    <Grid sm={12} md={4}>
                      <Box className="textarea">
                        <Typography className="textbold">Position</Typography>
                        <Box sx={{ display: "display", justifyContent: "" }}>
                          <OutlinedInput
                            className="inputfeilds"
                            sx={{ backgroundColor: "#ffffff" }}
                            placeholder="CEO"
                            value={values?.position}
                            onChange={handleChange("position")}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid sm={12} md={2} lg={2}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "37px",
                    }}
                  >
                    <Button
                      className="textbold"
                      sx={{
                        backgroundColor: "#138707 !important",
                        color: "#ffffff !important",
                        padding: "11px 43px",
                      }}
                      onClick={handleSubmit}
                    >
                      Add
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Box
              sx={{
                backgroundColor: "#ffffff",
                padding: " 20px 30px",
                marginTop: "50px",
              }}
            >
              <Grid sx={{ margin: "10px" }} container>
                <Grid sm={4} md={2} lg={2} component="label">
                  <Typography sx={{ fontSize: "24px" }} className="textbold">
                    Edit
                  </Typography>

                  <input type="file" hidden />
                  <img
                    width={"70px"}
                    src={teamPH}
                    alt="addnew"
                    style={{ borderRadius: "50%" }}
                  />
                </Grid>
                <Grid sm={8} md={8} lg={8}>
                  <Grid sx={{ margin: "10px" }} container>
                    <Grid sm={12} md={8}>
                      <Typography className="textbold">Name</Typography>
                      <Box
                        sx={{ display: "display", justifyContent: "center" }}
                      >
                        <OutlinedInput
                          sx={{ backgroundColor: "#ffffff" }}
                          className="inputfeilds"
                          placeholder="Enter Full Name"
                          value={val?.name}
                          // value={val?.name}
                          onChange={handleUpdate("name")}
                        />
                      </Box>
                    </Grid>
                    <Grid sm={12} md={4}>
                      <Box className="textarea">
                        <Typography className="textbold">Position</Typography>
                        <Box sx={{ display: "display", justifyContent: "" }}>
                          <OutlinedInput
                            className="inputfeilds"
                            sx={{ backgroundColor: "#ffffff" }}
                            placeholder="CEO"
                            value={val?.position}
                            onChange={handleUpdate("position")}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid sm={12} md={2} lg={2}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "37px",
                    }}
                  >
                    <Button
                      onClick={onHandleClick2}
                      className="textbold"
                      sx={{
                        backgroundColor: "#138707 !important",
                        color: "#ffffff !important",
                        padding: "11px 43px",
                      }}
                    >
                      Save
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </Container>
      </Box>
    </div>
  );
}
