import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Grid,
  TextField,
  Button,
  FormHelperText,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./StartupRegister.css";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import InvestorSetPassword from "../InvestorSetPassword";
import Select, { SelectChangeEvent } from "@mui/material/Select";

interface State {
  companyName: any;
  email: any;
  location: any;
  industry: any;
  founded: any;
  contactNumber: any;
  equityOffer: any;
  askPrice: any;
  companyInfo: any;
  phase: any;
  password: any;
  confirmPassword: any;
}
interface ErrorState {
  companyName: any;
  email: any;
  location: any;
  industry: any;
  founded: any;
  contactNumber: any;
  equityOffer: any;
  askPrice: any;
  companyInfo: any;
  phase: any;
  password: any;
  confirmPassword: any;
}

const StartupRegister = () => {
  document.title = "Make New India | Startup Register";
  const dispatch = useDispatch();
  const [isFocused, setIsFocused] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [phase, setPhase] = useState("");
  const isLoggedIn = useSelector<any>(
    (state) => state.startupReducer.isLoggedIn
  );
  const errorMsg: any = useSelector<any>((state) => state.startupReducer.error);
  const handleChangePhase = (event: SelectChangeEvent) => {
    setPhase(event.target.value as string);
  };

  const [values, setValues] = React.useState<State>({
    companyName: "",
    email: "",
    industry: "",
    location: "",
    founded: "",
    contactNumber: "",
    equityOffer: "",
    askPrice: "",
    companyInfo: "",
    phase: "",
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = React.useState<ErrorState>({
    companyName: "",
    email: "",
    location: "",
    industry: "",
    founded: "",
    contactNumber: "",
    equityOffer: "",
    askPrice: "",
    companyInfo: "",
    phase: "",
    password: "",
    confirmPassword: "",
  });

  const [showPassword, setshowPassword] = useState(false);
  const [next, setNext] = useState(false);

  useEffect(() => {
    formValidation(values);
  }, [values]);

  const handleChange =
    (prop: keyof any) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleNextSubmit = (e: any) => {
    e.preventDefault();
    setIsFocused(true);
    values.phase = phase;
    formValidation(values);
    if (
      errors?.companyName === "" &&
      errors?.email === "" &&
      errors?.industry === "" &&
      errors?.location === "" &&
      errors?.askPrice === "" &&
      errors?.companyInfo === "" &&
      errors?.founded === "" &&
      errors?.equityOffer === "" &&
      errors?.contactNumber === "" &&
      errors?.phase === "" &&
      values?.companyName !== "" &&
      values?.phase !== "" &&
      values?.contactNumber !== "" &&
      values?.email !== "" &&
      values?.industry !== "" &&
      values?.askPrice !== "" &&
      values?.companyInfo !== "" &&
      values?.equityOffer !== "" &&
      values?.founded !== "" &&
      values?.location !== ""
    ) {
   
      setNext(true);
    }
  };


  const formValidation = (values: any) => {
    var passwordValidation =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    if (
      values.companyName === "" ||
      !values.companyName.match(/^[a-zA-Z\s]*$/)
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        companyName: "Please enter a Valid name.",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, companyName: "" }));
    }
    if (values.email === "" || !values.email.match(/^(.+)@(.+)$/)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid Email",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
    }
    if (values.password === "" || !values.password.match(passwordValidation)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "Invalid Password (Password should contrain one special character, one uppercase letter and one number) ",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, password: "" }));
    }
    if (
      values.confirmPassword === "" ||
      !values.confirmPassword.match(passwordValidation) ||
      values.confirmPassword !== values.password
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "Invalid Password (Password should contrain one special character, one uppercase letter and one number)",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, confirmPassword: "" }));
    }
    if (values.location === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        location: "Please enter a valid location",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, location: "" }));
    }
    if (values.industry === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        industry: "Please enter a valid industry",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, industry: "" }));
    }
    if (values.founded === "" || !values.founded.match(/^[0-9]*$/)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        founded: "Invalid value",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, founded: "" }));
    }
    if (values.equityOffer === "" || !values.equityOffer.match(/^[0-9]*$/)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        equityOffer: "Invalid value",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, equityOffer: "" }));
    }
    if (values.askPrice === "" || !values.askPrice.match(/^[0-9]*$/)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        askPrice: "Please enter a value",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, askPrice: "" }));
    }
    if (values.companyInfo === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        companyInfo: "Please enter the company's information",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, companyInfo: "" }));
    }
    if (values.contactNumber.length < 10) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        contactNumber: "Please enter a valid contact number",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, contactNumber: "" }));
    }
    if (values.phase === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phase: "Please select a phase",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, phase: "" }));
    }
  };

  return (
    <>
      {!next ? (
        <Box
          className="main-startup-reg-container"
          sx={{ mx: { lg: "4rem", md: "2rem", sm: "2rem" } }}
        >
          <Box display="flex" alignItems="center">
            <Typography>Home</Typography>
            <ArrowForwardIosIcon sx={{ color: "#707070", fontSize: "14px" }} />
          </Box>
          <Typography className="header-startup-reg" variant="h2">
            Register
          </Typography>

          <Grid container spacing={2}>
            <Grid item lg={6} xs={12} md={6}>
              <TextField
                className="input-field"
                label="Company Name"
                name="companyName"
                onChange={handleChange("companyName")}
                fullWidth
                sx={{
                  mt: 3,
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleNextSubmit(e);
                  }
                }}
              />
              {errors["companyName"] && isFocused && (
                <FormHelperText sx={{ color: "red" }}>
                  {errors?.companyName.toUpperCase()}
                </FormHelperText>
              )}
              <TextField
                className="input-field"
                label="Email Id"
                name="email"
                onChange={handleChange("email")}
                fullWidth
                sx={{
                  mt: 3,
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleNextSubmit(e);
                  }
                }}
              />
              {errors["email"] && isFocused && (
                <FormHelperText sx={{ color: "red" }}>
                  {errors?.email.toUpperCase()}
                </FormHelperText>
              )}
              <TextField
                className="input-field"
                label="Industry"
                name="industry"
                onChange={handleChange("industry")}
                fullWidth
                sx={{
                  mt: 3,
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleNextSubmit(e);
                  }
                }}
              />
              {errors["industry"] && isFocused && (
                <FormHelperText sx={{ color: "red" }}>
                  {errors?.industry.toUpperCase()}
                </FormHelperText>
              )}
              <TextField
                className="input-field"
                label="Location"
                name="location"
                onChange={handleChange("location")}
                fullWidth
                sx={{
                  mt: 3,
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleNextSubmit(e);
                  }
                }}
              />
              {errors["location"] && isFocused && (
                <FormHelperText sx={{ color: "red" }}>
                  {errors?.location.toUpperCase()}
                </FormHelperText>
              )}
              <TextField
                className="input-field"
                label="Founded"
                placeholder="YYYY"
                name="founded"
                onChange={handleChange("founded")}
                fullWidth
                sx={{
                  mt: 3,
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleNextSubmit(e);
                  }
                }}
              />
              {errors["founded"] && isFocused && (
                <FormHelperText sx={{ color: "red" }}>
                  {errors?.founded.toUpperCase()}
                </FormHelperText>
              )}
              <TextField
                className="input-field"
                label="Conatct Number"
                placeholder="Contact Number"
                name="contactNumber"
                onChange={handleChange("contactNumber")}
                fullWidth
                sx={{
                  mt: 3,
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleNextSubmit(e);
                  }
                }}
              />
              {errors["contactNumber"] && isFocused && (
                <FormHelperText sx={{ color: "red" }}>
                  {errors?.contactNumber.toUpperCase()}
                </FormHelperText>
              )}
            </Grid>
            <Grid item lg={6} xs={12} md={6}>
              <TextField
                className="input-field"
                label="Equity Offer"
                name="equityOffer"
                onChange={handleChange("equityOffer")}
                fullWidth
                sx={{
                  mt: 3,
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleNextSubmit(e);
                  }
                }}
              />
              {errors["equityOffer"] && isFocused && (
                <FormHelperText sx={{ color: "red" }}>
                  {errors?.equityOffer.toUpperCase()}
                </FormHelperText>
              )}
              <TextField
                className="input-field"
                label="Ask Price"
                name="askPrice"
                onChange={handleChange("askPrice")}
                fullWidth
                sx={{
                  mt: 3,
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleNextSubmit(e);
                  }
                }}
              />
              {errors["askPrice"] && isFocused && (
                <FormHelperText sx={{ color: "red" }}>
                  {errors?.askPrice.toUpperCase()}
                </FormHelperText>
              )}
              <TextField
                className="input-field"
                label="Company Info"
                name="companyInfo"
                onChange={handleChange("companyInfo")}
                multiline
                rows={4.5}
                fullWidth
                sx={{
                  mt: 3,
                  mb: 2.5,
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleNextSubmit(e);
                  }
                }}
              />
              {errors["companyInfo"] && isFocused && (
                <FormHelperText sx={{ color: "red" }}>
                  {errors?.companyInfo.toUpperCase()}
                </FormHelperText>
              )}
              {/* phase */}
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel
                    // variant="outlined"
                    id="demo-simple-select-required-label"
                  >
                    Phase
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={phase}
                    label="Phase"
                    sx={{ backgroundColor: "#FFF" }}
                    // onChange={handleChange("phase")}
                    onChange={handleChangePhase}
                  >
                    <MenuItem value={"Ideation"}>Ideation</MenuItem>
                    <MenuItem value={"Validation"}>Validation</MenuItem>
                    <MenuItem value={"Expansion"}>Expansion</MenuItem>
                  </Select>
                </FormControl>
                {errors["phase"] && isFocused && (
                  <FormHelperText sx={{ color: "red" }}>
                    {errors?.phase.toUpperCase()}
                  </FormHelperText>
                )}
              </Box>

              <Button
                variant="contained"
                color="success"
                className="next-btn"
                onClick={handleNextSubmit}
                sx={{
                  marginTop: "1.8rem",
                }}
                endIcon={
                  <ArrowRightAltIcon className="arrow" fontSize="large" />
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleNextSubmit(e);
                  }
                }}
              >
                Next
              </Button>
            </Grid>
          </Grid>

          <Box
            display="flex"
            justifyContent="center"
            className="footer-startup-reg"
          >
            <Typography variant="subtitle1">
              Already have an account?
            </Typography>
            <Typography
              className="footer-login"
              variant="subtitle1"
              component={Link}
              to="/startup-login"
            >
              Login
            </Typography>
          </Box>
        </Box>
      ) : (
        <InvestorSetPassword registerData={values} />
      )}
    </>
  );
};

export default StartupRegister;
