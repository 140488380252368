import axios from "axios";
import { put } from "redux-saga/effects";

const url = "https://makenewindia.com/api";
export const apiClient = "https://makenewindia.com/api";

export const getUserProfile = (value: any) => {
  return axios
    .get(`https://makenewindia.com/api/investor/details/${value.payload}`)
    .then((res: any) => {
      return res;
    })
    .catch((err: any) => {
      return err;
    });

  // return (
  //     fetch(`https://makenewindia.com/api/getStartupProfile?id=${value.payload}`, {
  //       method: "get"
  //     //   headers: {
  //     //     "Content-Type": "application/json",
  //     //   }
  //     })
  //       // .then(handleApiErrors)
  //       .then((response) => response.json())
  //       .then((json) => json)
  //       .catch((error) => {
  //         throw error;
  //       })
  //   );
};
export const getStartupProfile = (value: any) => {
  return axios
    .get(`https://makenewindia.com/api/investor/details/${value.payload}`)
    .then((res: any) => {
      return res;
    })
    .catch((err: any) => {
      return err;
    });
};
export const sendRequestToInvestorApi = (value: any) => {
  return axios
    .post(
      `https://makenewindia.com/api/sendRequest/${value.payload.investorId}/${value.payload.startupId}?key=startup`,
      {
        note: value.payload.note,
      }
    )
    .then((res: any) => {
      return res;
    })
    .catch((err: any) => {
      return err;
    });
};
export const acceptRequestOfInvestorApi = (value: any) => {
  return axios
    .put(
      `https://makenewindia.com/api/requestStatus/${value.payload.startupId}/${value.payload.investorId}?key=startup&state=${value.payload.status}`
    )
    .then((res: any) => {
      return res;
    })
    .catch((err: any) => {
      return err;
    });
};
export const acceptRequestOfStartupApi = (value: any) => {
  return axios
    .put(
      `https://makenewindia.com/api/requestStatus/${value.payload.investorId}/${value.payload.startupId}?key=investor&state=${value.payload.status}`
    )
    .then((res: any) => {
      return res;
    })
    .catch((err: any) => {
      return err;
    });
};
export const fetchStartupDetailsAPI = (value: any) => {
  return axios
    .get(`https://makenewindia.com/api/getStartupProfile?id=${value.payload}`)
    .then((res: any) => {
      return res;
    })
    .catch((err: any) => {
      return err;
    });
};
export const fetchRecentChatsAPI = (value: any) => {
  return axios
    .get(`https://makenewindia.com/api/recentChats/${value.payload}`)
    .then((res: any) => {
      return res;
    })
    .catch((err: any) => {
      return err;
    });
};
export const getInvProfile = (value: any) => {
  return axios
    .get(`https://makenewindia.com/api/investor/profile/${value?.payload}`)
    .then((res: any) => {
      return res;
    })
    .catch((err: any) => {
      return err;
    });
};
export const getRisingStartup = () => {
  return fetch("https://makenewindia.com/api/topStartups?key=topStartups")
    .then((response) => response.json())
    .then((json) => json.data)
    .catch((error) => {
      throw error;
    });
};
export const getRisingStartupS = () => {
  return fetch("https://makenewindia.com/api/topStartups?key=investors")
    .then((response) => response.json())
    .then((json) => json.data)
    .catch((error) => {
      throw error;
    });
};

export const getBlog = () => {
  return axios
    .get("https://makenewindia.com/api/trendingStories")
    .then((res) => {
      return res.data;
    });
};

export const getRecomendedStartup = () => {
  return fetch("https://makenewindia.com/api/topStartups?key=topStartups")
    .then((response) => response.json())
    .then((json) => json.data)
    .catch((error) => {
      throw error;
    });
};
export const getAllStartup = () => {
  return fetch("https://makenewindia.com/api/getStartup")
    .then((response) => response.json())
    .then((json) => json.data)
    .catch((error) => {
      throw error;
    });
};
export const getGoogleHomepageApi = () => {
  return fetch("https://makenewindia.com/api/topStartups?key=topStartups")
    .then((response) => response.json())
    .then((json) => json.data)
    .catch((error) => {
      throw error;
    });
};
export const getInvestorProfileApi = () => {
  return axios
    .get("https://makenewindia.com/api/investor/list")
    .then((res: any) => {
      return res;
    })
    .catch((err: any) => {
      return err;
    });

  //     fetch(`https://makenewindia.com/api/investor/list`, {
  //       method: "get"
  //     })
  //       // .then(handleApiErrors)
  //       .then((response) => response.json())
  //       .then((json) => json)
  //       .catch((error) => {
  //         throw error;
  //       })
  //   );
};

// export const fix = (data:any,id:any) => {
//   return axios
//     .put(`${url}/investor/update/${id}`,{...data})
//     .then((res: any) => {
//       return res;
//     })
//     .catch((err: any) => {
//       return err;
//     });
// };

// export const InvestorUpdate = (value: any) => {
//   return axios
//     .put(`${url}/investor/update/${value.payload.id}` )
//     .then((res) => {
//       return res.data;
//     })
//     .catch((err: any) => {
//       return err;
//     });
// };
export const InvestorUpdate = (value: any) => {
  const formData = new FormData();
  let key: keyof typeof value;
  for (key in value) {
    formData.append(key, value.payload.data[key]);
  }
  formData.append("profileImg", value.profilePicture);

  return axios
    .put(`${url}/investor/update/${value.payload.id}`, formData)
    .then((res) => {
      return res.data;
    })
    .catch((err: any) => {
      return err;
    });
};
//strtupEditProfile
export const startupUpdate = (value: any) => {
  return axios
    .put(`${url}/editStartupProfile/${value.payload.id}`, {
      ...value.payload.data,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err: any) => {
      return err;
    });
};
export const startupEmployee = (value: any) => {
  return axios
    .post(`${url}/addEmployee`, {
      ...value.payload.data,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err: any) => {
      return err;
    });
};
export const addEmployee = (value: any) => {
  let formData = new FormData();
  formData.append("name", value.name);
  formData.append("position", value.position);
  formData.append("companyId", value.companyId);
  formData.append("userId", value.userId);
  return axios
    .post(`${url}/addEmployee`, formData)
    .then((res) => {
      return res.data;
    })
    .catch((err: any) => {
      return err;
    });
};
export const removeEmployee = (value: any) => {
  return axios
    .delete(`${url}/deleteEmployee?id=${value}`, {
      ...value,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err: any) => {
      return err;
    });
};
export const changePasswordInvestorAPI = (value: any) => {
  return axios
    .put(`${url}/changePassword/${value.payload.id}?key=investor`, {
      ...value.payload.data,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err: any) => {
      return err;
    });
};
export const changePasswordStartupAPI = (value: any) => {
  return axios
    .put(`${url}/changePassword/${value.payload.id}?key=startup`, {
      ...value.payload.data,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err: any) => {
      return err;
    });
};
export const forgotPasswordStartupAPI = (value: any) => {
  return axios
    .put(`${url}/forgotPassword?key=startup`, {
      ...value.payload,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err: any) => {
      return err;
    });
};
export const forgotPasswordInvestorAPI = (value: any) => {
  return axios
    .put(`${url}/forgotPassword?key=investor`, {
      ...value.payload,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err: any) => {
      return err;
    });
};

// const searchName = "apple";
// const searchLocation = "delhi";

export const getInvestorSearchData = (value: any) => {
  return fetch(
    `${url}/investor/searchStartup?name=${value.payload.name}&location=${value.payload.location}`,
    {
      method: "GET",
    }
  )
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
};
export const getInvestorSearchDataNav = (value: any) => {
  return fetch(`${url}/search?val=${value.payload.name}&key=investor`, {
    method: "GET",
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
};
export const getStartupSearchDataNav = (value: any) => {
  return fetch(`${url}/search?val=${value.payload.name}&key=startup`, {
    method: "GET",
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
};

export const getInvestorSearchDataS = (value: any) => {
  return fetch(`${url}/investor/search?name=${value.payload.name}`, {
    method: "GET",
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
};
export const getInvestorSearchDataI = (value: any) => {
  return fetch(`${url}/investor/searchStartup?name=${value.payload.name}`, {
    method: "GET",
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
};

export const updateEmployeeStartup = (val: any) => {
  const value2 = val.payload;
  const value = { name: value2.name, position: value2.position };
  let formData = new FormData();
  formData.append("name", value.name);
  formData.append("position", value.position);

  return axios
    .put(`${url}/updateEmployee?id=${value2.userId}`, formData)
    .then((res) => {
      return res.data;
    })
    .catch((err: any) => {
      return err;
    });
};

export const getInvestorNotification = (value: any) => {
  return axios
    .get(`${url}/getNotification/${value.payload}?key=investor`)
    .then((res: any) => {
      return res.data;
    })
    .catch((err: any) => {
      return err;
    });
};

export const getStartupNotification = (value: any) => {
  return axios
    .get(`${url}/getNotification/${value.payload}?key=startup`)
    .then((res: any) => {
      return res.data;
    })
    .catch((err: any) => {
      return err;
    });
};

export const readNotificationStartup = async (readerId: any, msgId: any) => {
  const result = await axios
    .put(`${url}/notificationUpdate/${readerId}/${msgId}?key=startup`)
    .then((res: any) => {
      return res.data;
    })
    .catch((err: any) => {
      return err;
    });
  return result;
};
export const readNotificationInvestor = async (readerId: any, msgId: any) => {
  const result = await axios
    .put(`${url}/notificationUpdate/${readerId}/${msgId}?key=investor`)
    .then((res: any) => {
      return res.data;
    })
    .catch((err: any) => {
      return err;
    });

  return result;
};
