import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { investorBasic, investorPremium } from "./pricingArr";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { apiClient } from "../../Api/api";
import { toast } from "react-hot-toast";
import { Modal } from "@mui/material";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function PricingCard(props: any) {
  const details = useSelector((state: any) => {
    return state.loginReducerHandler?.login[0];
  });

  const token = localStorage.getItem("id");
  const userType = localStorage.getItem("type");
  const navigate = useNavigate();
  const [premiumPrice, setPremiumPrice] = useState();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleTransfer = () => {
    if (token) {
      window.open("https://rzp.io/l/CI63OwBj");
    } else {
      navigate("/login");
    }
  };

  const handleDowngradePlanInvestor = async () => {
   
    axios
      .put(`${apiClient}/downgrade/${props?.email}?key=investor`)
      .then((res: any) => {
        toast.success("Downgraded successful");
        navigate("/dashboard");
        return res;
      })
      .catch((error: any) => {
       
        return error;
      });
  };

  const handleTransferPremium = () => {
    // if (userType === "investor" && props.btnName === "Downgrade") {
    // }
    if (token && props.planName === details?.subscription) {
      toast("This is your current plan");
    } else if (token && props.planName === "premium") {
      window.open("https://rzp.io/l/fJ58ufr");
    } else {
      navigate("/login");
    }
  };

  const getPrice = async () => {
    try {
      const result = await axios.get(`${apiClient}/investorplan`);
  
      setPremiumPrice(result?.data);
    } catch (error) {
      return error
    }
  };

  useEffect(() => {
    getPrice();
  }, []);
  // 

  return (
    <>
      <Card
        sx={{
          maxWidth: 400,
          height: 700,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            pb: "0",
          }}
        >
          <Typography sx={{ fontSize: 18 }} color="text.primary" gutterBottom>
            Investor {props.planName}
          </Typography>

          <Typography variant="h4" component="div">
            {props.price ? props.price : "INR 1499/month"}
            <Typography>
              {" "}
              {props.planName === "premium" ? "Free 7 days trial" : null}
            </Typography>
          </Typography>
        </CardContent>
        <CardContent sx={{ pl: "1.3rem" }}>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            {props.headline}
          </Typography>
          {props.planName === "basic"
            ? investorBasic.map((item, index) => (
                <Box display={"flex"} alignItems="center" pb={"1rem"}>
                  <Box pr={item.active === "1" ? "1rem" : "1.6rem"}>
                    {item.image}
                  </Box>
                  <Box>{item.name}</Box>
                </Box>
              ))
            : investorPremium.map((item, index) => (
                <Box display={"flex"} alignItems="center" pb={"1rem"}>
                  <Box pr={item.active === "1" ? "1rem" : "1.6rem"}>
                    {item.image}
                  </Box>
                  <Box>{item.name}</Box>
                </Box>
              ))}
        </CardContent>
        <CardActions>
          <Button
            // size="small"
            sx={{
              backgroundColor: "green",
              color: "white",
              width: "100%",
              display: "flex",
              bottom: 0,
              borderRadius:'50px',
              '&:hover': {
                backgroundColor: 'green',
              },
              
            }}
            onClick={
              userType === "investor" && props.btnName === "Downgrade"
                ? handleOpen
                : handleTransferPremium
            }
            // disabled= {props.planName === "Basic"}
          >
            {token ? props.btnName : "Get Now"}
          </Button>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Box>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Are you sure want to downgrade?
                </Typography>
              </Box>
              <Box pt={"2rem"}>
                <Button onClick={handleDowngradePlanInvestor}>Yes</Button>
                <Button onClick={handleClose}>No</Button>
              </Box>
            </Box>
          </Modal>
        </CardActions>
      </Card>
    </>
  );
}
