import { Button, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./btn.css";
import { submitSupportForm } from "../../../store/login/actions";
import { ThankyouCard } from "../../../elements/Pricing-Homepage/ThankyouCard/ThankyouCard";
import Spinner from "../../../Extras/Spinner";
import toast, { Toaster } from "react-hot-toast";

type myProps = {
  label: string;
  className?: string;
  startIcon?: any;
  component?: any;
  to?: string;
  value?: any;
};

const FormButton = ({
  label,
  className,
  startIcon,
  component,
  to,
  value,
}: myProps) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isLoading, setIsLoading] = useState(false);

  const profileUpdated = (toastHandler = toast) => {
    toastHandler.success("Query Submitted Successfully!", {
      style: {
        background: "#04111d",
        color: "#fff",
      },
    });
  };
  const handleSubmit = () => {
    // setIsLoading(true);
    if (
      to === "/request-list" ||
      to === "/request-list-startup" ||
      to === "/dashboard"
    )
      return;

    setOpen(true); ///////
    dispatch(submitSupportForm(value));
    if (
      // sessionStorage.getItem("type") === "investor" ||
      localStorage.getItem("type") === "investor"
    ) {
      setTimeout(() => {
        profileUpdated();
        navigate("/dashboard", { replace: true });
      }, 2000);
      // setIsLoading(false);
    }

    if (
      // sessionStorage.getItem("type") === "startup" ||
      localStorage.getItem("type") === "startup"
    ) {
      setTimeout(() => {
        profileUpdated();
        navigate("/startup-dashboard", { replace: true });
      }, 2000);
    }

    if (localStorage.getItem("type") === null){
      setTimeout(() => {
        profileUpdated();
        navigate("/", { replace: true });
      }, 2000);
    }
  };

  // if (isLoading) {
  //   return (
  //     <div style={{ height: "70vh" }}>
  //       <Spinner message="Fetching the Latest & Updated data for you, please wait..." />
  //     </div>
  //   );
  // }

  return (
    <>
      {/* <ThankyouCard /> */}
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      > */}
      {/* <ThankyouCard /> */}
      {/* {isLoading ? (
          <div style={{ height: "70vh" }}>
            <Spinner message="Thank you! Your Query is listed" />
          </div>
        ) : (
          <div style={{ height: "70vh" }}>
            <Spinner message="Thank you! Your Query is listed" />
          </div>
        )} */}
      {/* </Modal> */}

      <Button
        variant="contained"
        className={`common-btn success  ${className}`}
        startIcon={startIcon}
        component={component}
        to={to}
        onClick={handleSubmit}
      >
        {label}
      </Button>
    </>
  );
};

export default FormButton;
