import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./btn.css";
type myProps = {
  label: string;
  className?: string;
  component?: any;
  to?: string;
};

const CancelBtn = ({ label, className, component, to }: myProps) => {
  let navigate = useNavigate();
  let toWhere = "";
  if (
    // sessionStorage.getItem("type") === "investor" ||
    localStorage.getItem("type") === "investor"
  )
    toWhere = "/dashboard";
  if (
    // sessionStorage.getItem("type") === "startup" ||
    localStorage.getItem("type") === "startup"
  )
    toWhere = "/startup-dashboard";

  return (
    <Button
      className={`common-btn cancel  ${className}`}
      component={component}
      to={toWhere}
    >
      {label}
    </Button>
  );
};

export default CancelBtn;
