import {
  Avatar,
  Box,
  Divider,
  Typography,
  OutlinedInput,
  FormControl,
  List,
  Paper,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import chatGooglrIcon from "../../Assets/image/chatGooglrIcon.png";
import orangeDownArrow from "../../Assets/image/orangeDownArrow.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import closeIcon from "../../Assets/image/closeIcon.png";
import SendIcon from "@mui/icons-material/Send";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMessages,
  getStartupDetailsFromInvestor,
  sendMessages,
} from "../../store/login/actions";
import { getProfileInv } from "../../store/startup/actions";
import profileLogo from "./img/profile.jpeg";
import companyLogo from "./img/company.webp";
import companyBydefault from "./img/profile.jpeg";

interface State {
  to: any;
  from: any;
  msg: any;
}

const MessageModal = (props: any) => {
  let senderId = props.senderId;
  const dispatch = useDispatch();
  const [state, setState] = useState<any>(senderId);
  useEffect(() => {
    setState(senderId);
  }, [senderId]);
  const [messages, setMessages] = useState<any>([]);
  let id: any = "";
  if (localStorage.getItem("id")) id = localStorage.getItem("id");
  if (sessionStorage.getItem("id")) id = sessionStorage.getItem("id");

  const [values, setValues] = useState<State>({
    from: id,
    to: senderId,
    msg: "",
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setValues((prevState) => ({ ...prevState, to: senderId }));
      dispatch(fetchMessages(values));
    }, 1000);
    if (
      localStorage.getItem("type") === "investor" ||
      sessionStorage.getItem("type") === "investor"
    )
      dispatch(getStartupDetailsFromInvestor(senderId));
    if (
      localStorage.getItem("type") === "startup" ||
      sessionStorage.getItem("type") === "startup"
    )
      dispatch(getProfileInv(senderId));

    return () => clearInterval(interval);
  }, [senderId, values]);

  const startupInformation: any = useSelector<any>(
    (state) => state.loginReducerHandler.startupDetails
  );

  const investorProfile: any = useSelector<any>(
    (state) => state.startupReducer.investorData
  );

  let name = "";
  let companyName = "company";
  let location = "location";
  let pictureLogo = "";
  let placeholderLogo = "";
  pictureLogo = startupInformation[0]?.profilePicture;
  placeholderLogo = companyLogo;

  if (
    localStorage.getItem("type") === "investor" ||
    sessionStorage.getItem("type") === "investor"
  ) {
    name = startupInformation[0]?.companyName;
    companyName = startupInformation[0]?.companyName;
    location = startupInformation[0]?.location;
    pictureLogo = investorProfile[0]?.profilePicture;
    placeholderLogo = profileLogo;
  }
  if (
    localStorage.getItem("type") === "startup" ||
    sessionStorage.getItem("type") === "startup"
  ) {
    if (investorProfile) {
      name = investorProfile[0]?.name;
      companyName = investorProfile[0]?.name;
      location = investorProfile[0]?.location;
      pictureLogo = investorProfile[0]?.profilePicture;
      placeholderLogo = profileLogo;
    }
  }

  const messagesArray: any = useSelector<any>(
    (state) => state.loginReducerHandler.messages
  );

  useEffect(() => {
    setMessages(messagesArray[0]);
  }, [messagesArray]);

  const scrollRef: any = useRef<null | HTMLDivElement>(null);
  const inputRef: any = useRef<null | HTMLDivElement>(null);
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);
  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleImgError = (e: any) => {
    e.target.src = companyBydefault;
  };

  let instantArray = [
    {
      content: "",
      date: "xxxx",
      recieverInfo: senderId,
      senderInfo: id,
    },
  ];
  const handleSubmit = (e: any) => {
    inputRef.current.focus();
    if (values?.msg !== "") {
      instantArray[0].content = values?.msg;
      dispatch(sendMessages(values));
      setValues({ ...values, msg: "" });
      if (messages.length !== 0)
        setMessages((prevState: any) => [...prevState, instantArray[0]]);
    }
  };
  const [visible, setVisible] = React.useState(true);
  const handleVisible = () => {
    setVisible(!visible);
  };

  const senderChatStyle = {
    margin: 10,
    padding: 10,
    textAlign: "right",
    marginRight: 0,
    paddingRight: 0,
  };
  const receiverChatStyle: any = {
    padding: 10,
    margin: 10,
    marginLeft: 0,
    paddingLeft: 0,
  };
  const senderBorderStyle = {
    borderRadius: 20,
    padding: 10,
    backgroundColor: "white",
    border: "2px solid white",
    color: "black",
  };
  const receiverBorderStyle = {
    borderRadius: 20,
    padding: 10,
    border: "2px solid #46515D",
    backgroundColor: "#46515D",
    color: "white",
  };

  return (
    <>
      <Box
        className=""
        sx={{
          position: "fixed",
          top: "auto",
          right: "350px",
          left: "auto",
          bottom: "0",
          backgroundColor: "#162634",
          width: "320px",
          height: "500px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            position: "relative",
            p: 1,
          }}
        >
          <img
            onError={handleImgError}
            src={pictureLogo ? pictureLogo : placeholderLogo}
            alt="img"
            style={{
              marginRight: "10px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
          <Typography sx={{ color: "#ffffff" }}>{name}</Typography>
          <img
            src={closeIcon}
            alt="img"
            style={{
              position: "absolute",
              right: "0px",
              bottom: "-15px",
              width: "70px",
              height: "70px",
              cursor: "pointer",
            }}
            onClick={() => props.flagFromChild(false)}
          />
        </Box>

        <Divider color="#97989C" sx={{ borderBottomWidth: "0.5px" }} />
        <Paper
          elevation={0}
          style={{
            maxHeight: 420,
            overflow: "auto",
            padding: 20,
            paddingBottom: 50,
            backgroundColor: "#162634",
          }}
        >
          <List>
            <Box className="scrollingContainer">
              {messages?.slice(1)?.map((msg: any) => (
                <Box
                  ref={scrollRef}
                  style={
                    msg.senderInfo === id ? senderChatStyle : receiverChatStyle
                  }
                >
                  <span
                    style={
                      msg.senderInfo === id
                        ? senderBorderStyle
                        : receiverBorderStyle
                    }
                  >
                    {msg.content}
                  </span>
                </Box>
              ))}
            </Box>
          </List>
        </Paper>
        <Box
          sx={{
            display: "flex",
            position: "absolute",
            bottom: "0px",
            alignItems: "center",
            backgroundColor: "#233748",
            width: "100%",
          }}
        >
          <FormControl sx={{ width: "100%" }}>
            <OutlinedInput
              autoFocus
              inputRef={inputRef}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit(e);
                }
              }}
              sx={{
                border: "1px solid #97989C",
                margin: "15px 10px",
                color: "#ffffff",
                height: "2.7rem",
              }}
              onChange={handleChange("msg")}
              value={values?.msg}
              placeholder="Type Something..."
            />
          </FormControl>

          <SendIcon
            onClick={handleSubmit}
            className="btn"
            sx={{ ml: 1, mr: 1 }}
            fontSize="medium"
            color="warning"
          />
        </Box>
      </Box>
    </>
  );
};

export default MessageModal;
