import { Avatar, Divider, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import "./financial.css";
import LockIcon from "@mui/icons-material/Lock";
import { Link } from "react-router-dom";

const Financial = (props: any) => {
  const details = useSelector((state: any) => {
    return state.loginReducerHandler.choosenData;
  });

  return (
    <>
      {props.userSubscription === "basic" ? (
        <>
          <Box
            display="flex"
            gap={2}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{p:"4rem 0"}}
          >
            <LockIcon fontSize="large" />
            <Link to={"/pricing-investor"} style={{ textDecoration: "none" }}>
              <Typography variant="h4" color={"black"}>
                Unlock Premium To See
              </Typography>
            </Link>
          </Box>
        </>
      ) : (
        <Box className="financial-container ">
          <Grid container mt={6} className="border">
            {[
              {
                title: "Yearly Gross Revenue",
                amt:
                  details?.yearlyGrossRevenue == null || "undefined"
                    ? "Not Available"
                    : details?.yearlyGrossRevenue >= 10000000
                    ? (details?.yearlyGrossRevenue / 10000000).toFixed(2) +
                      " Cr"
                    : details?.yearlyGrossRevenue >= 100000
                    ? +(details?.yearlyGrossRevenue / 100000).toFixed(2) + " L"
                    : details?.yearlyGrossRevenue,
              },
              {
                title: "Yearly Net profit",
                amt:
                  details?.yearlyNetProfit == null || "undefined"
                    ? "Not Available"
                    : details?.yearlyNetProfit >= 10000000
                    ? (details?.yearlyNetProfit / 10000000).toFixed(2) + " Cr"
                    : details?.yearlyNetProfit >= 100000
                    ? +(details?.yearlyNetProfit / 100000).toFixed(2) + " L"
                    : details?.yearlyNetProfit,
              },
              {
                title: "Last Quarter Gross Revenue",
                amt:
                  details?.lastQuarterGrossRevenue == null || "undefined"
                    ? "Not Available"
                    : details?.lastQuarterGrossRevenue >= 10000000
                    ? (details?.lastQuarterGrossRevenue / 10000000).toFixed(2) +
                      " Cr"
                    : details?.lastQuarterGrossRevenue >= 100000
                    ? +(details?.lastQuarterGrossRevenue / 100000).toFixed(2) +
                      " L"
                    : details?.lastQuarterGrossRevenue,
              },
              {
                title: "Last Quarter net profits",
                amt:
                  details?.lastQuarterNetProfit == null || "undefined"
                    ? "Not Available"
                    : details?.lastQuarterNetProfit >= 10000000
                    ? (details?.lastQuarterNetProfit / 10000000).toFixed(2) +
                      " Cr"
                    : details?.lastQuarterNetProfit >= 100000
                    ? +(details?.lastQuarterNetProfit / 100000).toFixed(2) +
                      " L"
                    : details?.lastQuarterNetProfit,
              },
              {
                title: "Last Month Gross Revenue",
                amt:
                  details?.lastMonthGrossRevenue == null || "undefined"
                    ? "Not Available"
                    : details?.lastMonthGrossRevenue >= 10000000
                    ? (details?.lastMonthGrossRevenue / 10000000).toFixed(2) +
                      " Cr"
                    : details?.lastMonthGrossRevenue >= 100000
                    ? +(details?.lastMonthGrossRevenue / 100000).toFixed(2) +
                      " L"
                    : details?.lastMonthGrossRevenue,
              },
              // { title: "Last Month Net Profit", amt: "Not Available" },
              {
                title: "Assets Value",
                amt:
                  details?.assetsValue == null || "undefined"
                    ? "Not Available"
                    : details?.assetsValue >= 10000000
                    ? (details?.assetsValue / 10000000).toFixed(2) + " Cr"
                    : details?.assetsValue >= 100000
                    ? +(details?.assetsValue / 100000).toFixed(2) + " L"
                    : details?.assetsValue,
              },
              {
                title: "Liabilities Value",
                amt:
                  details?.liabilitiesValue == null || "undefined"
                    ? "Not Available"
                    : details?.liabilitiesValue >= 10000000
                    ? (details?.liabilitiesValue / 10000000).toFixed(2) + " Cr"
                    : details?.liabilitiesValue >= 100000
                    ? +(details?.liabilitiesValue / 100000).toFixed(2) + " L"
                    : details?.liabilitiesValue,
              },
              {
                title: "Loans",
                amt:
                  details?.loans == null || "undefined"
                    ? "Not Available"
                    : details?.loans >= 10000000
                    ? (details?.loans / 10000000).toFixed(2) + " Cr"
                    : details?.loans >= 100000
                    ? +(details?.loans / 100000).toFixed(2) + " L"
                    : details?.loans,
              },
            ].map((item, index, array) => (
              <>
                <Grid item xs={12} className="details-items" key={index}>
                  <Typography variant="body1" className="bold grey">
                    {item?.title}
                  </Typography>
                  <Typography
                    variant="h3"
                    className="bold details-text-size"
                    gutterBottom
                  >
                    {item?.amt === "Not Available"
                      ? item?.amt
                      : "₹ " + item?.amt}
                    {/* {startUpData.equityOffer} */}
                  </Typography>

                  {index !== array.length - 1 ? (
                    <Divider className="divider mt" />
                  ) : null}
                </Grid>
              </>
            ))}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default Financial;
