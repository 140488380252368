import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import modalbg from "../../Assets/image/modalbg.png";
import closebtn from "../../Assets/image/closebtn.svg";
import mniLogo from "../../Assets/image/mniLogo.svg";
import btnArrow from "../../Assets/image/btnArrow.png";
import "./PrimiumPopup.css";
import { Link } from "react-router-dom";
import { apiClient } from "../../Api/api";
import axios from "axios";
import madalimg from "./img/madalimg.svg";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  border: '0px solid #000',
  outline: 0,
};

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  price?: any;
  subtitle?: any;
}

const PrimiumPop: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  price,
  subtitle,
}) => {
  const [investorPrice, setInvestorPrice] = React.useState<any>();
  const [startupPrice, setStartupPrice] = React.useState<any>();
  const type = localStorage.getItem("type");

  React.useEffect(() => {
    if (type === "investor") {
      axios.get(`${apiClient}/investorplan`).then((res: any) => {
        // setData(res?.data.data);

        setInvestorPrice(res?.data?.data[0].price);
      });
    } else if (type === "startup") {
      axios.get(`${apiClient}/startupplan`).then((res: any) => {
        // setStartupData(res?.data.data);

        setStartupPrice(res?.data?.data[0].price);
      });
    }
  }, []);

  const handleClick = () => {
    if (type === "investor") {
      window.open("https://rzp.io/l/fJ58ufr");
    } else if (type === "startup") {
      window.open("https://rzp.io/l/yUfBIRU");
    }
  };

  return (
    <div>
      <Modal
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="primiummodal" sx={style}>
          <Box onClick={onClose} sx={{ float: "right", cursor: "pointer" }}>
            <img src={closebtn} alt="closebtn" />
          </Box>
          <Box>
            <img src={mniLogo} alt="logo" />
          </Box>
          <Box>
            <Typography className="modal-heading2 underline-text-modal">
              Upgrade to Premium{" "}
            </Typography>
            <Box>
              {" "}
              <Typography className="para2" variant="body1" component="p">
                {type === "investor"
                  ? "Explore detailed Startup Business Profile with Make New India Premium for only"
                  : "Increase your chances of being funded with Make New India Premium for only"}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Box className="paymentBox">
              <Typography className="boxText">
                {type === "investor"
                  ? `INR ${
                      investorPrice === undefined ? "Loading.." : investorPrice
                    }/month`
                  : `INR ${
                      startupPrice === undefined ? "Loading.." : startupPrice
                    }/month`}
              </Typography>
              <Typography variant="body1" component="p">
                Limited time Offer
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Button
              onClick={handleClick}
              className="goPrimiumBtn"
              variant="contained"
              endIcon={<ArrowForwardIcon />}
            >
              GO PREMIUM
            </Button>
          </Box>

          <Typography
            onClick={onClose}
            sx={{ marginTop: "10px", cursor: "pointer" }}
            variant="body1"
            component="p"
          >
            No thanks!
          </Typography>
          <Box style={{position:"absolute", width:"212px",height:"143px"}} className="modalimg">
            <img src={madalimg} alt="modalimg" />
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default PrimiumPop;
