import React from 'react';
import DiscoverBanner from '../DiscoverBanner';
import Footer from '../Footer';
import GoogleSlider from '../GoogleSlider/GoogleSlider';
import MainBanner from '../MainBanner';
import { Pricing } from '../Pricing-Homepage/Pricing';
import RegisterBanner from '../RegisterBanner';
import StartupCard from '../TopStartup/StartupCard';
import {
  // TrendingCard,
  TrendingStories,
} from '../TrendingStories/TrendingStories';
import FeatureBanner from '../FeaturesBanner';
import UnlockSection from '../UnlockSection/UnlockSection';
import GetStarted from '../GetStarted/GetStarted';

const Home = () => {
  return (
    <>
      <GoogleSlider />
      <MainBanner />
      <UnlockSection />
      <GetStarted
        bgColor={'#FF9A33'}
        text="“Your business story has the potential to change the lives of
              millions of people.”"
        textColor={'#010101'}
        iconColor="#FF9A33"
        linkNav ="/investor-register"
      />
      <DiscoverBanner />
      <StartupCard />
      <FeatureBanner />
      <GetStarted
        bgColor={'#138707'}
        text="“You are one step away from changing the world, Let's change the world together”"
        textColor={'#ffffff'}
        iconColor="#138707"
        linkNav ="/startup-register"
      />
      <Pricing />
      <TrendingStories />
      <Footer />
    </>
  );
};

export default Home;
