import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Divider,
  Grid,
  Link,
  Modal,
  Popover,
  Stack,
  TextareaAutosize,
  Typography,
  OutlinedInput,
} from "@mui/material";
import "../../../utils/styles.css";
import "./user.css";
import user from "../img/user.png";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { Link as Links, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { Data } from "./Data";
import { sendRequestToInvestor } from "../../../../store/startup/actions";
import MessageIcon from "@mui/icons-material/Message";
import { toast } from "react-hot-toast";
import { startupPremiumFeatures } from "../../../../FeaturesJSON/features";
import LockIcon from "@mui/icons-material/Lock";

interface State {
  note: string;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  lineHeight: 2,
};
const User = ({ isAlreadyOpen, userSubscription }: any) => {
  // let { userSubscription } = props;
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = React.useState(false);
  const [connectText, setConnectText] = React.useState("Connect");
  const handleOpen = () => setOpenModal(true);
  const handleClosee = () => setOpenModal(false);
  const [anchorEl, setAnchorEl] = React.useState<any | null>(isAlreadyOpen);
  const [values, setValues] = React.useState<State>({
    note: "",
  });
  const startupData: any = useSelector<any>(
    (state) => state.startupReducer?.login[0]
  );
  const userNetworkList = useSelector((state: any) => {
    return state.startupReducer.login[0]?.connections;
  });


  useEffect(() => {
    setTimeout(() => {
      const body = document.querySelector<any>("#root");

      body.scrollIntoView(
        {
          behavior: "smooth",
        },
        500
      );
    }, 0);
  }, []);

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };
  const [anchorElShare, setAnchorElShare] = useState<HTMLButtonElement | null>(
    null
  );

  const startupId: any = useSelector<any>(
    (state) => state.startupReducer?.login[0]?.userId
  );

  const startupConnectionArray: any = useSelector<any>(
    (state) => state.startupReducer?.login[0]?.connections
  );

  const investorId: any = useSelector<any>(
    (state) => state.startupReducer?.getProfileData?.uId
  );
  // const [isAlreadyConnection, setIsAlreadyConnection] = useState(false);
  useEffect(() => {
    if (startupConnectionArray?.includes(investorId)) {
      setConnectText("Connected");
    }
  }, [investorId]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickShare = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElShare(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseShare = () => {
    setAnchorElShare(null);
  };

  const handleCloseBoth = () => {
    setOpenModal(false);
    setAnchorEl(null);
  };
  const copyLink = (index: any) => {
    if (index == 4) {
      navigator.clipboard.writeText("makenewindia.com");
    }
  };

  const sendRequestHandler = () => {
    setOpenModal(false);
    setAnchorEl(null);

    let obj = {
      startupId,
      investorId,
      note: values.note,
    };
    dispatch(sendRequestToInvestor(obj));
    setAnchorEl(null);
    setConnectText("Request sent");
  };

  // const open = Boolean(anchorEl);
  let open = Boolean(anchorEl);
  const openShare = Boolean(anchorElShare);

  const id = open ? "simple-popover" : undefined;
  const idShare = openShare ? "simple-popover-Share" : undefined;

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="#010101"
      fontSize="14px"
      href="/startup-dashboard"
      sx={{ opacity: "66%" }}
    >
      HOME
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="#010101"
      fontSize="14px"
      href="/"
      sx={{ opacity: "66% " }}
    >
      {" "}
    </Link>,
  ];
  // const startupInvestorProfile: any = useSelector<any>(
  //   (state) => state.startupReducerRegistration?.login[0]
  // );
  const startupInvestorProfile: any = useSelector<any>(
    (state) => state.startupReducer?.getProfileData
  );

  const navigate = useNavigate();
  const handleRedirect = (e: any) => {
    e.preventDefault();
    navigate("/chat", { state: startupInvestorProfile.uId });
  };
  const handleBuyPremium = () => {
    navigate("/pricing-startup");
    toast("Please buy premium to connect with more investors");
  };

  return (
    <Box className="main-container bg-grey" sx={{ width: "100%" }}>
      <Stack spacing={2}>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
      {/* <Button className="edit-btn" component={Links} to="/edit-profile">
        <EditIcon className="edit-icon" />
      </Button> */}

      <Grid container spacing={2} className="user-sub-container">
        <Grid
          item
          xs={4}
          sm={3}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Avatar
            alt="user"
            src={
              startupInvestorProfile?.profilePicture
                ? startupInvestorProfile?.profilePicture
                : user
            }
            sx={{ width: { sm: 112, xs: 100 }, height: { sm: 112, xs: 100 } }}
          />
        </Grid>
        <Grid item xs={8} sm={9} sx={{ position: "relative" }}>
          <Grid>
            <Grid sx={{ pt: 2 }}>
              <Typography
                sx={{ fontSize: { sm: "34px", xs: "21px" } }}
                className="bold"
              >
                {startupInvestorProfile?.name
                  ? startupInvestorProfile.name
                  : "..."}
              </Typography>
              <Typography
                sx={{ fontSize: { sm: "18px", xs: "13px" } }}
                variant="caption"
                className="bold "
                gutterBottom
              >
                {startupInvestorProfile?.companyName
                  ? startupInvestorProfile?.companyName
                  : ""}{" "}
                •{" "}
                {startupInvestorProfile?.occupation
                  ? startupInvestorProfile.occupation
                  : "CEO"}
              </Typography>
            </Grid>

            <Box
              className="bg-lightgreen industry-box"
              sx={{
                position: "absolute",
                right: 0,
                top: { sm: "40%", xs: "90%" },
              }}
            >
              <Typography variant="subtitle1" component="div" className="book">
                {startupInvestorProfile?.industry
                  ? startupInvestorProfile.industry
                  : "Entrepreneur"}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid sx={{ ml: { md: "25%", xs: 0 } }}>
        <Grid style={{ marginTop: "-30px" }}>
          <Typography variant="body2" color="text.secondary" className="book">
            {startupInvestorProfile?.description
              ? startupInvestorProfile.description
              : "..."}
          </Typography>
        </Grid>
        <Grid sx={{ mt: 4, mb: 4 }}>
          <Box className="location-btn align">
            <LocationOnOutlinedIcon className="green" />
            <Typography className="book">
              {startupInvestorProfile?.location
                ? startupInvestorProfile.location
                : "..."}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid container mb={4} lg={8} display="flex" alignItems="center">
        <Grid item xs={6} lg={5} md={4}>
          <Button
            disabled={connectText !== "Connect"}
            onClick={
              startupData?.subscription === "basic" &&
              userNetworkList.length == startupPremiumFeatures.connectLimit
                ? handleBuyPremium
                : handleClick
            }
            className={
              connectText !== "Connect"
                ? "connect-disabled bg-green btnProfile"
                : "connect-Btn bg-green btnProfile"
            }
            sx={{ color: "white", textTransform: "none" }}
          >
            {connectText}
          </Button>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "center",
              horizontal: "left",
            }}
          >
            <Box
              textAlign="center"
              sx={{ px: 1, py: 2, lineHeight: "normal", m: 1 }}
              className="popover-connect"
            >
              <Box display="flex" alignItems="center">
                <CloseIcon
                  onClick={handleClose}
                  sx={{ color: "orange", mr: 2 }}
                />
                <Typography
                  sx={{
                    fontSize: { xs: "18px", xl: "20px" },
                    fontFamily: "bold",
                  }}
                >
                  Want to add a note to your invitation?
                </Typography>
              </Box>
              <Typography
                sx={{
                  p: 2,
                  fontStyle: "italic",
                  className: "poppins-italic",
                  fontSize: { xs: "13px", xl: "16px" },
                }}
              >
                Send connection with a note
              </Typography>
              <Button
                onClick={
                  startupData?.subscription === "basic" &&
                  userNetworkList.length == startupPremiumFeatures.connectLimit
                    ? handleBuyPremium
                    : sendRequestHandler
                }
                sx={{ mr: 2 }}
                className="btn-connect bg-green"
              >
                No, Send Connect
              </Button>
              <Button onClick={handleOpen} className="btn-connect bg-green">
                Yes
              </Button>
            </Box>
          </Popover>
          <Modal
            open={openModal}
            // onClose={handleClosee}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Box display="flex" alignItems="center">
                <CloseIcon
                  onClick={handleClosee}
                  sx={{ color: "orange", mr: 2 }}
                />
                <Typography fontFamily="bold" fontSize="20px">
                  Enter your Note
                </Typography>
              </Box>
              <OutlinedInput
                value={values.note}
                onChange={handleChange("note")}
                aria-label="minimum height"
                minRows={5}
                placeholder="Type Something..."
                style={{ width: 640, padding: 20 }}
              />
              <Button
                onClick={
                  startupData?.subscription === "basic" &&
                  userNetworkList.length == startupPremiumFeatures.connectLimit
                    ? handleBuyPremium
                    : sendRequestHandler
                }
                sx={{ display: "flex", float: "right", width: "23%" }}
                className="btn-connect bg-green"
              >
                Send
              </Button>
            </Box>
          </Modal>
        </Grid>

        {/* <Grid item xs={4} lg={4} md={4}>
          <Button
            onClick={handleClickShare}
            className="shareBtn bg-white btnProfile"
            sx={{ color: "green", textTransform: "none" }}
          >
            Share
          </Button>
          <Popover
            id={idShare}
            open={openShare}
            anchorEl={anchorElShare}
            onClose={handleCloseShare}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <Box
              textAlign="center"
              sx={{ px: 1, py: 2, lineHeight: "normal", m: 1 }}
              className="popover-connect"
            >
              {Data.map((ele, index) => {
                return (
                  <img
                    onClick={() => {
                      copyLink(index);
                    }}
                    style={{ cursor: index === 4 ? "pointer" : "" }}
                    src={ele?.image}
                    alt=""
                  />
                );
              })}
              {userSubscription === "basic" ? (
                <Box
                  display="flex"
                  gap={1}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <LockIcon fontSize="medium" />
                  <h4>Unlock Premium To See</h4>
                </Box>
              ) : (
                <>
                  <Box
                    display="flex"
                    alignItems={"center"}
                    justifyContent="center"
                    gap={2}
                  >
                    <h4> Email : </h4>
                    <a href={`mailto:/${startupInvestorProfile?.email}`}>
                      {startupInvestorProfile?.email}
                    </a>
                  </Box>
                </>
              )}
            </Box>
          </Popover>
        </Grid> */}

        {connectText === "Connected" && (
          <Grid item xs={4} lg={3} md={3}>
            <Button
              onClick={handleRedirect}
              className="chat-btn-startup bg-green btnProfile"
              sx={{ color: "white", textTransform: "none", marginLeft: -5 }}
            >
              Chat
            </Button>
          </Grid>
        )}
      </Grid>

      {[" Area of Expertise"].map((item, index) => (
        <>
          {index === 0 ? <Divider className="divider" /> : null}

          <Box sx={{ mb: 4 }}>
            <Typography
              className="bold user-company-title"
              variant="h4"
              sx={{ m: "20px 0px" }}
            >
              {item}
            </Typography>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 6, sm: 12, md: 12 }}
            >
              {startupInvestorProfile?.areaOfExpertise[0] === "" ||
              startupInvestorProfile?.areaOfExpertise.length === 0 ? (
                <Typography sx={{ ml: 5, mt: 1, color: "#ccc" }}>
                  No Expertise has been Added.
                </Typography>
              ) : (
                Array.from(
                  Array(startupInvestorProfile?.areaOfExpertise.length)
                ).map((_, index) => (
                  <Grid item xs={2} sm={3} md={3} key={index}>
                    <Box className="company-box align">
                      <Typography className="book">
                        {startupInvestorProfile?.areaOfExpertise[index]}
                      </Typography>
                    </Box>
                  </Grid>
                ))
              )}
            </Grid>
          </Box>
          <Divider className="divider" />
        </>
      ))}
      {["Companies Backed"].map((item, index) => (
        <>
          {index === 0 ? <Divider className="divider" /> : null}

          <Box sx={{ mb: 4 }}>
            <Typography
              className="bold user-company-title"
              variant="h4"
              sx={{ m: "20px 0px" }}
            >
              {item}
            </Typography>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 6, sm: 12, md: 12 }}
            >
              {startupInvestorProfile?.companiesBacked[0] === "" ||
              startupInvestorProfile?.companiesBacked.length === 0 ? (
                <Typography sx={{ ml: 5, mt: 1, color: "#ccc" }}>
                  No Backed Companies have been Added.
                </Typography>
              ) : (
                Array.from(
                  Array(startupInvestorProfile?.companiesBacked.length)
                ).map((_, index) => (
                  <Grid item xs={2} sm={3} md={3} key={index}>
                    <Box className="company-box align">
                      <Typography className="book">
                        {startupInvestorProfile?.companiesBacked[index]}
                      </Typography>
                    </Box>
                  </Grid>
                ))
              )}
            </Grid>
          </Box>
          <Divider className="divider" />
        </>
      ))}
    </Box>
  );
};

export default User;
