import React from "react";
import "./TermsOfUse.css";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const TermsOfUse = () => {
  document.title = "Make New India | Terms of Use";
  return (
    <Box
      className="max-width"
      pt={3}
      sx={{
        mx: { xs: 2, sm: 0, md: 10 },
      }}
    >
      <Box mb={2} className="td-media-query">
        <Typography
          variant="subtitle2"
          color="#01010159"
          component="span"
          pr={1}
        >
          HOME
        </Typography>
        <Typography variant="subtitle2" color="#01010159" component="span">
          &gt;
        </Typography>
      </Box>
      <Typography
        variant="h4"
        sx={{
          fontFamily: "light",
          color: "black",
          fontSize: "42px",
          fontWeight: "600",
          mx: { xs: 0, sm: 5, md: 0 },
        }}
      >
        Terms of Use / UseAgreement
      </Typography>

      <Box
        mt={2}
        sx={{
          mx: { xs: 0, sm: 5, md: 0 },
        }}
        className="termsAndCondition"
      >
        <Typography mb={2.8} className="termsSubHead">
          1) Introduction
        </Typography>
        <Typography className="typography-style-text" mb={2.8}>
          Makenewindia.com (the “Site”) is owned and operated by Hello Web
          Private Limited (“Company”). The Site is hereinafter collectively
          referred to as “Platform”. For the purposes of the Terms of Use, “You"
          or “Your” shall mean the person who is accessing the Platform and “we”
          or “us” or “our” means makenewindia.com (The “Platform”).{" "}
        </Typography>
        <Typography className="typography-style-text" mb={2.8}>
          The Company makes available its services and products to you, through
          the Platform on the condition that you abide by these Terms of Use
          (hereinafter referred to as “Terms of Use”), as updated from time to
          time. The membership and rights of admissions are reserved. If you do
          not accept the Terms of Use or you do not meet or comply with their
          provisions, you should not use or access the Platform and/or avail of
          the Company’s services or products via the Platform.
        </Typography>
        {/* ------------------------------------------- */}
        <Typography mb={2.8} className="termsSubHead">
          1.1 Purpose
        </Typography>
        <Typography className="typography-style-text" mb={6}>
          Our core mission is to connect Indian companies/business collectively
          referred to as “Startups” with world’s high net worth individuals
          referred to as “Investors” to promote economic opportunities and
          allowthem to be more productive and successful. Startups and Investors
          as hereinafter collectively referred to as “Users”. Our services are
          designed to promote business opportunity for our users by enabling you
          and millions of other business minds to meet, exchange ideas, learn,
          and find investment or expansion opportunities, and make decisions in
          a network of trusted relationships.
        </Typography>
        <Typography className="typography-style-text" mb={6}>
          The Platform is intended to serve only as a preliminary medium of
          contact and exchange of information between the Users, who have a bona
          fide intention to contact and/or be contacted for the purposes related
          to genuine existing business/economic opportunities (“Purpose”)
        </Typography>
        {/* ---------------------------------- */}
        <Typography mb={2.8} className="termsSubHead">
          1.2 Users and Visitors
        </Typography>
        <Typography className="typography-style-text" mb={6}>
          When you register and join the makenewindia.com Services, you become a
          User. If you have chosen not to register for our Services, you may
          access certain features as a “Visitor.”
        </Typography>
        {/* ------------------------------ */}
        <Typography mb={2.8} className="termsSubHead">
          2) Binding Agreement
        </Typography>
        <Typography className="typography-style-text" mb={6}>
          The Terms of Use constitute a binding legal agreement (“Agreement”)
          between you and the Company. You agree that by clicking “Register”,
          “Sign Up” or similar, registering, accessing or using our services
          (described below), you are agreeing to enter into a legally binding
          contract with makenewindia.com (even if you are using our Services on
          behalf of a company/business). To the extent that you are accessing
          this Platform in your capacity as a representative of or as an
          employee of, a company/business, you represent and warrant that you
          are agreeing to these Terms of Use on your own behalf as well as on
          behalf of the company/business and have the authority to bind such
          company/business to this Agreement. If you do not agree to this
          contract (“Contract” or “User Agreement”), do not click “Sign Up” (or
          similar) and do not access or otherwise use any of our Services. If
          you wish to terminate this contract, at any time you can do so by
          closing your account by sending an email from your registered email ID
          to <Link to={"#"}>support@makenewindia.com</Link> and no longer
          accessing or using our Services.
        </Typography>
        <Typography className="typography-style-text" mb={6}>
          This Agreement will be effective, valid and subsisting as long as you
          use our Site.
        </Typography>
        {/* ---------------------------------- */}
        <Typography mb={2.8} className="termsSubHead">
          3) Obligations
        </Typography>
        {/* --------------------------------------- */}
        <Typography mb={2.8} className="termsSubHead">
          3.1 Service Eligibility
        </Typography>
        <Typography className="typography-style-text" mb={2}>
          The Services are not for use by anyone under the age of 18. Here are
          some promises that you make to us in this Contract:
        </Typography>
        <ul>
          <li>
            <Typography className="typography-style-text" mb={2}>
              By registering, using and accessing the platform, you agree that:
              a) you must be the "Minimum Age" (age of 18); b) you are competent
              to enter into a valid binding contract as per Indian law; and c)
              you are eligible to conduct the Purpose lawfully; d) you are not
              already restricted by makenewindia.com from using the Services
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              By using this Site, you represent and warrant that you have the
              right, consent, authority, and legal capacity to enter into this
              Agreement; submit relevant information to us; and that you are not
              prohibited or prevented by any applicable law for the time being
              in force or any order or decree or injunction from any court,
              tribunal or any such competent authority restraining you from
              entering into any kind of business.
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              You further confirm that you, intent to seek the business
              opportunity and that you shall not use this site for casual chats.
              You also agree to abide by all of the terms and conditions of this
              Agreement. If at any time makenewindia.com is of the opinion (in
              its sole discretion) or has any reason to believe that you are not
              eligible to become a member or that you have made any
              misrepresentation, makenewindia.com reserves the right to
              forthwith terminate your membership and / or your right to use the
              Service without any refund to you of any of your unutilized
              subscription fee.
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              By using this Site, you acknowledge that search results shown to
              you by the system are based on the business connection preference
              details submitted by you in your profile.
            </Typography>
          </li>
        </ul>
        {/* ------------------------------------- */}
        <Typography mb={2.8} className="termsSubHead">
          3.2 Account Security
        </Typography>
        <Typography className="typography-style-text" mb={2}>
          As a Useryou agree touse a strong password and keep it confidential.
          You are responsible for maintaining the confidentiality of your login
          credentials you designate during the registration process, and you are
          solely responsible for all activities that occur under your account.
          The Company, however, undertakes to take all reasonable precautions at
          its end to ensure that there is no compromise of login credentials
          provided to you. You agree to immediately notify us of any disclosure
          or unauthorized use of your account or any other breach of security
          and ensure that you log out from your account at the end of each
          session.
        </Typography>
        {/* ------------------------------------- */}
        <Typography mb={2.8} className="termsSubHead">
          3.3 Accuracy and Correctness of User Information
        </Typography>
        <Typography className="typography-style-text" mb={2}>
          While using, registering on and/or creating a profile on the Platform
          you undertake to provide only true and correct information and further
          undertake to, at all times, keep the information up to date. Users are
          prohibited from impersonating or masquerading as any other person,
          whether living or dead.
        </Typography>
        <Typography className="typography-style-text" mb={2}>
          Makenewindia.co, reserves the right to remove/suspend any content or
          profile which is violative of these Terms of Use including any profile
          providing false, incorrect, incomplete or outdated information. The
          Company also reserves the right to terminate any User’s access to the
          Platform, if he or she fails to furnish accurate or updated contact
          details.
        </Typography>
        {/* ------------------------------------ */}
        <Typography mb={2.8} className="termsSubHead">
          4) Terms for use of the platform (“Dos and Don’ts”)
        </Typography>
        <Typography className="typography-style-text" mb={2}>
          The Platform and the services and products offered via the Platform
          (which includes both paid and free services/ products) are meant for
          only for legitimate and lawful uses which fall within the scope of the
          Purpose. The Company has the sole and absolute right to determine
          whether a specific type of action or use falls within the scope of the
          Purpose or not. Illegal and/or unauthorized uses of the Site,
          including unauthorized framing of or linking to the Site will be
          investigated, and appropriate legal action will be taken, including
          without limitation, civil, criminal, and injunctive redress.
        </Typography>
        <Typography className="typography-style-text" mb={2}>
          makenewindia.com reserves the right in its sole discretion to review
          the activity and status of each account and block access to the member
          based on such review.
        </Typography>
        <Typography className="typography-style-text" mb={2}>
          Makenwindia.com reserve the right at its sole discretion to
          restrict/suspend your free access to its website based on review of
          the member's activity, status or any such criteria makenewindia.com
          may deems fit and proper with due intimation to you.
        </Typography>
        <Typography className="typography-style-text" mb={2}>
          You hereby confirm that as on date of this registration, you do not
          have any objection to receive emails, SMS/WhatsApp messages and calls
          from makenewindia.com and members of makenewindia.com as long as you
          are a registered member of makenewindia.com including SMS permission
          for authenticating mobile verification wand transactions via OTP, sent
          by the Payment Gateway. This consent shall supersede any preferences
          set by you with or registration done with the Do Not Disturb (DND
          Register)/ National Customer Preference Register (NCPR). This consent
          extends to emails, messages or calls relating but not limited to phone
          number verification.
        </Typography>
        {/* ============================================= */}
        <Typography mb={2.8} className="termsSubHead">
          4.1 Dos
        </Typography>
        <Typography className="typography-style-text" mb={2}>
          You agree that you will:
        </Typography>
        <ul>
          <li>
            <Typography className="typography-style-text" mb={2}>
              Comply with all applicable laws, including, without limitation,
              privacy laws, intellectual property laws, anti-spam laws, export
              control laws, tax laws, and regulatory requirements;
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              The Content, information including the personal information
              provided by you to us is correct and accurateand keep it
              updated;If you are creating the profile on behalf of your
              friend/sibling/company/business, you hereby agree that you have
              the required permission from the particular person or from a
              particular company/business whose profile is created on
              makenewindia.com by you. Impersonation is crime and punishable
              under various laws.
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              Use your real name on your profile; and
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              Use the Services in a professional manner.
            </Typography>
          </li>
        </ul>
        {/* ----------------------------------------------- */}
        <Typography mb={2.8} className="termsSubHead">
          4.2 Don’ts
        </Typography>
        <Typography className="typography-style-text" mb={2}>
          You agree that you will not:
        </Typography>
        <ul>
          <li>
            <Typography className="typography-style-text" mb={2}>
              Create a false identity on makenewindia.com, misrepresent your
              identity, create a Member profile for anyone other than yourself
              (a real person), or use or attempt to use another’s account;
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              Multiple profiles of the same person or a company/business are not
              allowed on our Site. makenewindia.com reserves the right to
              deactivate all multiple profiles without any refund of
              subscription fees;
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              You hereby agree that you shall not use the Services and the
              platforms to harass any makenewindia.com member;
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              Develop, support or use software, devices, scripts, robots or any
              other means or processes (including crawlers, browser plugins and
              add-ons or any other technology) to scrape the Services or
              otherwise copy profiles and other data from the Services;
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              Override any security feature or bypass or circumvent any access
              controls or use limits of the Service (such as caps on keyword
              searches or profile views);
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              Copy, use, disclose or distribute any information obtained from
              the Services, whether directly or through third parties (such as
              search engines), without the consent of makenewindia.com;
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              Disclose information that you do not have the consent to disclose
              (such as confidential information of others (including your
              employer));
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              Violate the intellectual property rights of others, including
              copyrights, patents, trademarks, trade secrets or other
              proprietary rights. For example, do not copy or distribute (except
              through the available sharing functionality) the posts or other
              content of others without their permission, which they may give by
              posting under a Creative Commons license;
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              Violate the intellectual property or other rights of
              makenewindia.com, including, without limitation, (i) copying or
              distributing our learning videos or other materials or (ii)
              copying or distributing our technology, unless it is released
              under open-source licenses; (iii) using the word “makenewindia” or
              our logos in any business name, email, or URL except as provided
              in the Brand Guidelines;
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              Post anything that contains software viruses, worms, or any other
              harmful code;
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              Reverse engineer, decompile, disassemble, decipher or otherwise
              attempt to derive the source code for the Services or any related
              technology that is not open source;
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              Imply or state that you are affiliated with or endorsed by
              Makenewindia.com without our express consent (e.g., representing
              yourself as an accredited Makenewindia.com trainer);
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              Rent, lease, loan, trade, sell/re-sell or otherwise monetize the
              Services or related data or access to the same, without
              makenewindia’sconsent;
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              Deep-link to our Services for any purpose other than to promote
              your profile or a Group on our Services, without makenewindia’s
              consent;
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              Use bots or other automated methods to access the Services, add or
              download contacts, send or redirect messages;
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              Monitor the Services’ availability, performance or functionality
              for any competitive purpose;
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              Engage in “framing,” “mirroring,” or otherwise simulating the
              appearance or function of the Services;
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              Overlay or otherwise modify the Services or their appearance (such
              as by inserting elements into the Services or removing, covering,
              or obscuring an advertisement included on the Services);
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              Interfere with the operation of, or place an unreasonable load on,
              the Services (e.g., spam, denial of service attack, viruses,
              gaming algorithms); and/or
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              Violate the Professional Community Policies or any additional
              terms concerning a specific Service that are provided when you
              sign up for or start using such Service, and the Bing Maps terms
              where applicable.
            </Typography>
          </li>
        </ul>
        <Typography className="typography-style-text" mb={2}>
          makenewindia.com members who subscribe to the makenewindia.com Select
          Service hereby, unconditionally and irrevocably confirm that you have
          read terms and conditions and agree to abide by them.
        </Typography>
        {/* ==================================================== */}
        <Typography mb={2.8} className="termsSubHead">
          5) User Limitations
        </Typography>
        <Typography className="typography-style-text" mb={2}>
          You agree to strictly abide by any limitations imposed on usage of the
          Platform by the Company, Makenewindia.com reserves the right to limit
          your use of the Services, including the number of your connections and
          your ability to contact other users. Makenewindia.com reserves the
          right to restrict, suspend, stop your access or service or terminate
          your account if you breach this Contract or the law or are misusing
          the Services and initiate action as it may deem fit.
        </Typography>
        <Typography mb={2.8} className="termsSubHead">
          6) Intellectual Property Rights
        </Typography>
        <Typography className="typography-style-text" mb={2}>
          YOU ARE REQUESTED TO RESPECT OUR PROPRIETARY RIGHTS AND BRANDS
          <br />
          <br />
          Makenewindia.com reserves all of its intellectual property rights in
          the site and in the Services. The Site contains the copyrighted
          material, trademarks, and other intellectual information of
          makenewindia.com, and its licensors. Trademarks and logos used in
          connection with the Services are the trademarks of their respective
          owners. “makenewindia” logos and other makenewindia.com trademarks,
          service marks, graphics and logos used for our Services are trademarks
          or registered trademarks of makenewindia.com. Except for that
          information which is in the public domain such as user profile or for
          which permission has been obtained from the user, you cannot copy,
          modify, publish, transmit, distribute, perform, display, or sell any
          such proprietary information. Any such act or an attempted act on your
          part shall constitute a violation of this Agreement and your
          membership is liable to be terminated forthwith by makenewindia.com
          without refund of any of your unused Subscription fees.
          makenewindia.com reserves right to take legal action (civil and/or
          criminal) wherever applicable for any violations.
        </Typography>
        {/* ========================================= */}
        <Typography mb={2.8} className="termsSubHead">
          7) Disclaimers
        </Typography>
        <ul>
          <li>
            <Typography className="typography-style-text" mb={2}>
              The company is an intermediary as defined under section 2(1)(w) of
              the information technology act, 2000.
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              makenewindia.com is not responsible for any incorrect or
              inaccurate Content/listing posted on the Site or in connection
              with the makenewindia.com Service, whether caused by Users,
              Members, third parties or by any of the equipment or programming
              associated with or utilized in the Service, nor for the conduct of
              any User and/or Member of the makenewindia.com Service whether
              online or offline. The Platform primarily serves as a portal for
              online distribution and publication of information submitted by
              Users. Although, the Company is not obligated to screen any
              communications or information posted by Users. Views expressed by
              the Users are their own and the Company does not endorse the same.
            </Typography>
          </li>
        </ul>
        <Typography className="typography-style-text" mb={2}>
          In case any inaccurate or otherwise improper content which does not
          conform to these Terms of Use is sighted on the Platform, Users are
          requested to notify the Company of the same using the contract details
          provided below. If the Company finds such content to be in violation
          of the Terms of Use, the Company may in its sole discretion take down
          or request the removal of such content, in accordance with applicable
          laws.
        </Typography>
        <ul>
          <li>
            <Typography className="typography-style-text" mb={2}>
              Makenewindia.com assumes no responsibility for any error,
              omission, interruption, deletion, defect, delay in operation or
              transmission, communications line failure, theft or destruction or
              unauthorized access to, or alteration of, User and/or Member
              communications or any communications by Makenewindia.com to its
              Members.
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              Makenewindia.com is not responsible for any problems or technical
              malfunction of any telephone network or lines, computer
              on-line-systems, servers or providers, computer equipment,
              software, failure of email or players on account of technical
              problems or traffic congestion on the Internet or at any web site
              or combination thereof, including injury or damage to Users and/or
              Members or to any other person's computer related to or resulting
              and/or in connection with the Makenewindia.com Service.
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              The Site and the Service are provided "AS-IS" and Makenewindia.com
              expressly disclaims any warranty of fitness for a particular
              purpose or non-infringement. Makenewindia.com cannot guarantee and
              does not promise any specific results from use of the Site and/or
              the Makenewindia.com Service. You understand that makenewindia.com
              makes no guarantees, either express or implied, regarding
              business/ investment opportunity you find through the Service.
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              Any mention of non-Company products or services is provided on the
              Platform is for informational purposes only. Nothing on the
              Platform shall be considered an endorsement, representation or
              warranty to any User or third party, whether in regards to its web
              site, products, services, investment, employment or recruiting
              practices, or otherwise.
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              The company does not warrant that the platform will operate
              error-free or that the site, application and their server(s) are
              free of computer viruses or other harmful mechanisms. If your use
              of platform results directly or indirectly in the need for
              servicing or replacing equipment or data, the company is not
              responsible for those costs.
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              The company makes no representations regarding the truthfulness,
              accuracy, completeness, timeliness or reliability of any content
              posted by users, or of any other form of communication engaged in
              by users
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              The company makes no representations regarding the effectiveness
              of the services or timeliness of the products or services offered
              through the platform. The company does not guarantee that the
              services or products offered through the platform will result in
              business/companies to raise the funds, and is not responsible or
              liable for any business, investment, employment, hiring and/or
              salary decisions, for whatever reason made, made by you.
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              Makenewindia.com expressly disclaims any liability or
              responsibility whatsoever and howsoever arising as a result of any
              Content/listing posted on the Site / made available to
              Makenewindia.com by any Members or the users of the Site or any
              third party.
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              Makenewindia.com does not assume any responsibility or liability
              for any illegal Content posted on the Site by any Members, users
              or any third party.
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              All liability, whether civil or criminal arising out of any
              Content that is Posted on the Site will be of that Member / user /
              third party who has Posted such Content and Makenewindia.com
              reserves its right to claim damages from such Member/ user / third
              party that it may suffer as a result of such Content Posted on the
              Site. Makenewindia.com does not claim ownership of Content you
              submit or make available for inclusion on the Service
            </Typography>
          </li>
        </ul>
        {/* ==================================================== */}
        <Typography mb={2.8} className="termsSubHead">
          8) Prohibited Content
        </Typography>
        <Typography className="typography-style-text" mb={2}>
          You understand and agree that Makenewindia.com may delete any listing,
          content, communication, photos or profiles (collectively, "Content")
          that in the sole judgment of Makenewindia.com violate this Agreement
          or which might be offensive, illegal, or that might violate the
          rights, harm, or threaten the safety of either makenewindia.com and/or
          its Members.
        </Typography>
        <Typography className="typography-style-text" mb={2}>
          makenewindia.com reserves the right to verify the authenticity of
          Content posted on the Site. In exercising this right, Makenewindia.com
          may ask you to provide any documentary or other form of evidence
          supporting the Content you post on the Site. If you fail to produce
          such evidence to the satisfaction of makenewindia.com,
          makenewindia.com may, in its sole discretion, terminate your
          Membership without a refund.
        </Typography>
        <Typography className="typography-style-text" mb={2}>
          The following is an indicative list of the kind of Content that is
          illegal or prohibited on the Site. Makenewindia.com will investigate
          and take appropriate legal action in its sole discretion against
          anyone who violates this Agreement, including without limitation,
          removing the offending communication/Content from the Service and
          terminating the Membership of such violators without a refund. Illegal
          and prohibitive Content includes Content which:
        </Typography>
        <ul>
          <li>
            <Typography className="typography-style-text" mb={2}>
              is blatantly offensive to the community, such as Content that
              promotes racism, bigotry, hatred or physical harm of any kind
              against any group or individual;
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              harasses or advocates harassment of another person;
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              involves the transmission of "junk mail", "chain letters," or
              unsolicited mass mailing or "spamming";
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              promotes information that you know is false, misleading or
              promotes illegal activities or conduct that is abusive,
              threatening, obscene, defamatory or libelous;
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              promotes an illegal or unauthorized copy of another person's
              copyrighted work, such as providing pirated computer programs or
              links to them, providing information to circumvent
              manufacture-installed copy-protect devices, or providing pirated
              music or links to pirated music files;
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              contains restricted or password only access pages, or hidden pages
              or images (those not linked to or from another accessible page);
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              displays pornographic or sexually explicit material of any kind;
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              provides material that exploits people under the age of 18 in a
              sexual or violent manner, or solicits personal information from
              anyone under 18;
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              provides instructional information about illegal activities such
              as making or buying illegal weapons, violating someone's privacy,
              or providing or creating computer viruses;
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              solicits passwords or personal identifying information for
              commercial or unlawful purposes from other users; and
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              engages in commercial activities and/or sales without our prior
              written consent such as contests, sweepstakes, barter,
              advertising, and pyramid schemes
            </Typography>
          </li>
        </ul>
        {/* ========================================= */}
        <Typography mb={2.8} className="termsSubHead">
          9) Payment Terms& Refund Policy
        </Typography>
        <Typography className="typography-style-text" mb={2}>
          YOU ARE REQUESTED TO HONOUR YOUR PAYMENT OBLIGATIONS TO THE
          COMPANY.ALL YOUR PURCHASE OF SERVICES ARE SUBJECT TO MAKEWNEWINDIA.COM
          REFUND POLICY <br />
          <br />
          If you buy any of our paid Services (“Basic” or “Premium Services”),
          you agree to pay us the applicable fees and taxes and to additional
          terms specific to the paid Services. Failure to pay these fees will
          result in the termination of your paid Services. Also, you agree that:
        </Typography>
        <ul>
          <li>
            <Typography className="typography-style-text" mb={2}>
              Payments for the services offered by the Platform shall be on a
              100% advance basis. The payment for service once subscribed to by
              you is not refundable and any amount paid shall stand
              appropriated. Refund, if any, will be at the sole discretion of
              the Company.
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              Your purchase may be subject to foreign exchange fees or
              differences in prices based on location (e.g. exchange rates).
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              Term for the paid Membership would be in accordance with the type
              of membership undertaken and Services once subscribed are not
              transferable.
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              In the event of business discontinuity, the Company shall refund
              50% of the unused membership fees paid by you
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              We may store and continue billing your payment method (e.g. credit
              card) even after it has expired, to avoid interruptions in your
              Services and to use to pay other Services you may buy.
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              If you purchase a subscription, your payment method automatically
              will be charged at the start of each subscription period for the
              fees and taxes applicable to that period. To avoid future charges,
              cancel before the renewal date by sending an email from your
              registered email ID to support@makenewindia.com
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              We may calculate taxes payable by you based on the billing
              information that you provide us at the time of purchase.
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              Users, buying the products online are redirected to third party
              gateways for completing payment transactions. These transactions
              happen on third party network and hence not controlled by Company.
            </Typography>
          </li>
        </ul>
        <Typography className="typography-style-text" mb={2}>
          A User through his user identification and shall be solely responsible
          for carrying out any online or off-line transaction involving credit
          cards / debit cards or such other forms of instruments or documents
          for making such transactions and Company assumes no responsibility or
          liability for their improper use of information relating to such usage
          of credit cards / debit cards used by the subscriber online /
          off-line.
        </Typography>
        <Typography className="typography-style-text" mb={2}>
          The Company shall not be liable for any loss or damage sustained by
          reason of any disclosure (inadvertent or otherwise) of any information
          concerning the user's account and / or information relating to or
          regarding online transactions using credit cards / debit cards and /
          or their verification process and particulars nor for any error,
          omission or inaccuracy with respect to any information so disclosed
          and used whether or not in pursuance of a legal process or otherwise.
        </Typography>
        <Typography className="typography-style-text" mb={2}>
          Notwithstanding anything contained in any other agreement or
          arrangement, by whatever name called, the performance obligation of
          the Company (service provider) is to provide access of its on-line
          portal to the customer for the duration of the subscription period &
          reference to any usage, by whatever name called or any other
          performance obligation, if any, is to provide the upper limit for
          consumption, which by itself, does not create any additional
          performance obligation upon the Company.
        </Typography>
        <Typography className="typography-style-text" mb={2}>
          The Company offers no guarantees whatsoever for the accuracy or
          timeliness of the refunds reaching the Customers card/bank accounts.
        </Typography>
        <Typography className="typography-style-text" mb={2}>
          User hereby irrevocably accepts to receive the tax invoice as soft
          copy through emails.
        </Typography>
        {/* ============================================== */}
        <Typography mb={2.8} className="termsSubHead">
          10) Termination
        </Typography>
        <Typography className="typography-style-text" mb={2}>
          Both you and Makenewindia.com may terminate this Contract at any time
          with notice to the other. On termination, you lose the right to access
          or use the Services. In the event you terminate your membership, you
          will not be entitled to a refund of any unutilized subscription fees,
          if any, paid by you, except where otherwise guaranteed in writing.
        </Typography>
        <Typography className="typography-style-text" mb={2}>
          Makenewindia.com may terminate, without notice in its sole discretion,
          your access to the Site and/or your membership, for any reason
          including but not limited to breach of the terms of use, using the
          service for commercial purpose, engaging in prohibited or
          inappropriate communication or activity, by sending notice to you at
          the email address as provided by you in your application for
          membership or such other email address as you may later provide to
          makenewindia.com. If makenewindia.com terminates your membership for
          breach of terms of this Agreement, you will not be entitled to any
          refund of any Subscription fees, if any, paid by you.
        </Typography>
        {/* =============================================== */}
        <Typography mb={2.8} className="termsSubHead">
          11) Privacy
        </Typography>
        <Typography className="typography-style-text" mb={2}>
          The Privacy Policy of the Platform explains how we may use your
          personal data, we will at all times respect and ensure adherence to
          the privacy policy on a best-efforts basis. Use of the
          makenewindia.com Site and/or the makenewindia.com Service is governed
          by our Privacy Policy and follow guidelines to protect your privacy.
          12) Understand your Risk
        </Typography>
        {/* ========================================== */}
        <Typography mb={2.8} className="termsSubHead">
          12) Understand your Risk
        </Typography>
        {/* ------------------------ */}
        <Typography mb={2.8} className="termsSubHead">
          12.1 Interaction with other Users
        </Typography>
        <Typography className="typography-style-text" mb={2}>
          Youagree that you are solely responsible for your interaction or any
          form of communication with other makenewindia.com users will be at
          your own risk. Makenewindia.com reserves the right, but has no
          obligation, to monitor disputes between you and other Members.
          Makenewindia.com also reserves the right to take appropriate action
          against errant members. However, makenewindia.com is not obliged to
          share such action with other members, including complainants. Users
          are requested to conduct independent checks before proceeding with any
          transaction based on the data available on the platform.
          makenewindia.com expressly disclaims any responsibility or liability
          for any transactions or interactions between the users.
        </Typography>
        <Typography mb={2.8} className="termsSubHead">
          12.2 Links to Third-party Sites
        </Typography>
        <Typography className="typography-style-text" mb={2}>
          Please be aware that the Platform sometimes contains links to
          third-party websites that are not governed by this contract. Users may
          be directed to third-party sites for more information, such as
          advertisers, blogs, content sponsorships, vendor services, social
          networks, etc.These links are provided solely as convenience to you
          and the presence of these links shall not under any circumstances be
          considered as an endorsement of the contents of the same by the
          Company. The Company is not responsible for the content on such linked
          third-party websites and does not make any representations relating to
          the content on such websites. If you chose to access these websites,
          you do so at your own risk.
        </Typography>
        {/* ========================================= */}
        <Typography mb={2.8} className="termsSubHead">
          13) Limitation of Liability
        </Typography>
        <Typography className="typography-style-text" mb={2}>
          You expressly understand and agree that, to the maximum extent
          permissible under applicable law, in no event will makenewindia.com
          shall be liable to you or any third personfor any indirect,
          incidental, special, consequential or exemplary damages, including but
          not limited to, damages for loss of profits, revenue, goodwill, use,
          data or other intangible losses (even if you have been advised of the
          possibility of such damages). Makenewindia.com and its affiliates make
          no representation or warranty about the services, including any
          representation that the services will be uninterrupted or error-free,
          and provide the services (including content and information) on an “as
          is” and “as available” basis. To the fullest extent permitted under
          applicable law, makenewindia.com and its affiliates disclaim any
          implied or statutory warranty, including any implied warranty of
          title, accuracy of data, non-infringement, merchantability or fitness
          for a particular purpose.
        </Typography>

        {/* ====================================================== */}
        <Typography mb={2.8} className="termsSubHead">
          14) Amendment to the Terms of Use
        </Typography>
        <Typography className="typography-style-text" mb={2}>
          Maknewindia.com reserves the right to update, change or modify this
          contract at any time. If we make a material change to it, we will send
          an email communication about the update, to provide you the
          opportunity to review the changes before they become effective. We
          agree that changes cannot be retroactive. If you object to any
          changes, you may choose to close your account. Your continued use of
          our Services after we publish or send a notice about our changes to
          these terms means that you are consenting to the updated terms as of
          their effective date. The updated terms shall come to effect from the
          date of publication of such update, change or modification.
        </Typography>

        {/* ================================= */}
        <Typography mb={2.8} className="termsSubHead">
          15) Taxes
        </Typography>
        <Typography className="typography-style-text" mb={2}>
          Any reference to duties and taxes etc in these terms of use shall
          include goods and services tax (“gst) or value added tax (vat) as per
          local jurisdiction or any other similar tax or duty, by whatever name
          called (herein referred as gst) and shall be charged in addition to
          the basic amount, in accordance with respective laws & regulations.
        </Typography>

        {/* ============================================ */}
        <Typography mb={2.8} className="termsSubHead">
          16) Governing Law and Dispute Resolution
        </Typography>
        <Typography className="typography-style-text" mb={2}>
          These Terms of Use shall be governed by the laws of Republic of India.
          If there is any dispute about or involving the Site and/or the
          Service, by using the Site, you unconditionally agree that all such
          disputes and/or differences will be governed by the laws of India and
          shall be subject to the exclusive jurisdiction of the Competent Courts
          in Delhi, India only.
        </Typography>

        {/* ========================================= */}
        <Typography mb={2.8} className="termsSubHead">
          17) Users Dispute Settlement
        </Typography>
        <Typography className="typography-style-text" mb={2}>
          If any dispute arises between a User/Users and Company/business
          arising out of use of the Platform or thereafter, in connection with
          the validity, interpretation, implementation or alleged breach of any
          provision of these Terms of Use, the dispute shall be referred to a
          sole arbitrator who shall be an independent and neutral third party
          identified by the Company. The decision of the arbitrator shall be
          final and binding on both the parties to the dispute. The venue and
          seat of arbitration shall be New Delhi, India. The Arbitration &
          Conciliation Act, 1996 as amended or by any successive legislations,
          shall govern the arbitration proceedings.
          <br />
          <br />
          The Company will not be party to any legal proceedings between a User
          (e.g. a subscriber) and a party contacted through the Platform site.
          In case Company is made a party in any legal proceedings, costs will
          be recovered from the party on whose behest the Company is involved in
          the matter, to which it otherwise has no control. Company however will
          abide with any court order served on it through due process.
        </Typography>

        {/* =============================== */}
        <Typography mb={2.8} className="termsSubHead">
          18) Local Laws
        </Typography>
        <Typography className="typography-style-text" mb={2}>
          You are responsible for compliance with applicable local laws
          including but not limited to the export and import regulations of
          other countries, while consuming the information and the services as
          available through the Platform.
        </Typography>

        {/* =============================================== */}

        <Typography mb={2.8} className="termsSubHead">
          19) Indemnity
        </Typography>
        <Typography className="typography-style-text" mb={2}>
          By accepting these terms of use, you agree to indemnify and otherwise
          hold makenewindia.com, its directors, officers, employers, agents,
          subsidiaries, affiliates and other partners fully indemnified and
          harmless from any loss, liability, claim, or demand, including
          reasonable attorney's fees, made by any third party due to or arising
          out of your use of the platform or the Service in violation of this
          Agreement and/or arising from a breach of these Terms of Use and/or
          any breach of your representations and warranties set forth above
          and/or any fraudulent act on your part.
        </Typography>

        {/* =============================================== */}

        <Typography mb={2.8} className="termsSubHead">
          20) Other
        </Typography>
        <ul>
          <li>
            <Typography className="typography-style-text" mb={2}>
              By becoming a member of the makenewindia.com service, you agree to
              receive certain specific email from makenewindia.com. You can
              unsubscribe at any time for any reason quickly and easily by
              clicking on the unsubscribe link enclosed in the mail.
            </Typography>
          </li>
          <li>
            <Typography className="typography-style-text" mb={2}>
              This Agreement, accepted upon use of the Site and further affirmed
              by becoming a Member of the makenewindia.com service, contains the
              entire agreement between you and makenewindia.com regarding the
              use of the Site and/or the Service. If any provision of this
              Agreement is held invalid, the remainder of this Agreement shall
              continue in full force and effect.
            </Typography>
          </li>
        </ul>
        <Typography className="typography-style-text" mb={2}>
          By becoming a member of makenewindia.com and/or using the Services of
          the Site, you unconditionally and irrevocably confirm that you have
          read and understood the above provisions and agree to abide by them.
          If you are not agreeing to any of the above terms and conditions,
          please refrain yourself from registering on the Site.
          <br />
          <br />
          makenewindia.com is a trademark of Hello Web Pvt. Ltd.
          <br />
          <br />
          This Agreement is an electronic document in terms of the Information
          Technology Act, 2000 and Rules there under pertaining to electronic
          records as applicable and amended. This electronic record is generated
          by a computer system and does not require any physical or digital
          signatures.
        </Typography>

        {/* ============================================== */}

        <Typography mb={2.8} className="termsSubHead">
          21) Contact information
        </Typography>
        <Typography className="typography-style-text" mb={2}>
          For any general query/ complaint related to content/ fraudulent
          listings on the Site/ legal notices or service of process, you may
          contact us by sending an email to{" "}
          <Link to={"#"}>support@makenewindia.com</Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default TermsOfUse;
