import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { apiClient } from "../../Api/api";
import PricingCard from "./PricingCard";
import PricingCardStartup from "./PricingCardStartup";

const PricingStartupUpdated = () => {
  const [startupPrice, setStartupPrice] = useState<any>();
  const details = useSelector((state: any) => {
    return state.startupReducer?.login[0];
  });

  useEffect(() => {
    axios.get(`${apiClient}/startupplan`).then((res: any) => {
      // setStartupData(res?.data.data);

      setStartupPrice(res?.data?.data[0].price);
    });
  }, []);
  return (
    <Box display={"flex"} justifyContent={"center"} gap={10} flexDirection={{xs:"column", sm:"row"}}>
      <PricingCardStartup
        planName={"basic"}
        price={"FREE"}
        btnName = { details?.subscription === "basic" ? "Current Plan" : "Downgrade"}
        headline={"All Basic Features"}
        email = {details?.email}
      />
      <PricingCardStartup
        planName={"premium"}
        btnName = { details?.subscription === "premium" ? "Current Plan" : "Upgrade"}
        price={`INR ${startupPrice === undefined ? "Loading.." : startupPrice}/month`}
        // price={`INR 1222/month`}
        headline={"All Premium Features"}
        email = {details?.email}
      />
    </Box>
  );
};

export default PricingStartupUpdated;
