import { Box, Grid, Paper, Typography } from "@mui/material";
import React, { Component, useEffect, useState } from "react";
import Slider from "react-slick";
import "./UI/Menu/menu.css";
import { settings } from "./utils/settings";
import {
  ProcessImg,
  Details,
  CompanyDescription,
  Financial,
  Swot,
  People,
} from "./UI";
import SideBar from "../Profile/UI/SideBar";
import { useSelector } from "react-redux";
import Spinner from "../../Extras/Spinner";

const CompanyProfile = () => {
  document.title = "Make New India | Company Profile";
  const [active, setActive] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const details = useSelector((state: any) => {
    return state.loginReducerHandler.choosenData;
  });
  const subscription = useSelector((state: any) => {
    return state.loginReducerHandler?.login[0];
  });
  const userSubscription = subscription?.subscription


  useEffect(() => {
    const body = document.querySelector<any>("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
  }, []);

  useEffect(() => {
    if (details === undefined) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [details]);

  if (isLoading) {
    return (
      <div style={{ height: "70vh" }}>
        <Spinner message="Fetching the Latest & Updated data for you, please wait..." />
      </div>
    );
  }

  return (
    <>
      <CompanyDescription userSubscription={userSubscription} data={details} />

      <Box className="border-light menu hoverCursor">
        <Slider {...settings}>
          {["Pitch", "Details", "Financial", "SWOT", "People"].map(
            (item, index) => (
              <Box
                className="menu-inner bold"
                key={index}
                onClick={() => setActive(index)}
                sx={{
                  backgroundColor: active === index ? "#FF9A33" : null,
                  color: active === index ? "#FFFFFF" : null,
                }}
              >
                <Typography className="bold size-xs menu-text">
                  {item}
                </Typography>
              </Box>
            )
          )}
        </Slider>
      </Box>
      {active === 0 ? (
        <ProcessImg />
      ) : active === 1 ? (
        <Details userSubscription={userSubscription} />
      ) : active === 2 ? (
        <Financial userSubscription={userSubscription} />
      ) : active === 3 ? (
        <Swot userSubscription={userSubscription}  />
      ) : active === 4 ? (
        <People userSubscription={userSubscription}  />
      ) : (
        <></>
      )}
      <Box className="align">
        <SideBar className="side-bar-display" />
      </Box>
    </>
  );
};

export default CompanyProfile;
