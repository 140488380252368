import React from 'react';
import img1 from './img/vr.png';

export const storyData = [
    {
      id: 1,
      img: img1,
      newsHeading: "Heading Name1",
      newsContent:"You’re an investor looking to invest in new start up, growing business or in an You’re innovative business idea. Or You’re a startup or growing business looking to raise...",
      newspaperName:"Times Of India",
           
    },
    {
      id: 2,
      img: img1,
      newsHeading: "Heading Name2",
      newsContent:"You’re an investor looking to invest in new start up, growing business or in an You’re innovative business idea. Or You’re a startup or growing business looking to raise...",
      newspaperName:"Times Of India",
           
    },
    {
      id: 3,
      img: img1,
      newsHeading: "Heading Name3",
      newsContent:"You’re an investor looking to invest in new start up, growing business or in an You’re innovative business idea. Or You’re a startup or growing business looking to raise...",
      newspaperName:"Times Of India",
           
    },
    {
      id: 4,
      img: img1,
      newsHeading: "Heading Name4",
      newsContent:"You’re an investor looking to invest in new start up, growing business or in an You’re innovative business idea. Or You’re a startup or growing business looking to raise...",
      newspaperName:"Times Of India",    
    },
  ];