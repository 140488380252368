import React, { useEffect, useState } from "react";
import { Box, Grid, Toolbar, Typography } from "@mui/material";
import ProfileCard from "../ProfileCard/ProfileCard";
import { cardDataRecomended } from "../ProfileCard/ProfileCardArr";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getInvestorProfile } from "../../../store/startup/actions";
import { Spinner3Dots } from "../../../Extras/Spinner";

export default function ViewSearchResult() {
  document.title = "Make New India | Search Result";
  const [isLoading, setIsLoading] = useState(false);
  const [noData, setNoData] = useState(false);

  const data = useSelector((state: any) => {
    return state.startupReducer?.search;
  });



  // useEffect(() => {
  //   if (data?.length === 0) {
  //     setIsLoading(true);
  //   } else {
  //     setIsLoading(false);
  //   }
  // }, [data]);

  if (isLoading) {
    return (
      <div>
        <Spinner3Dots message="" />
      </div>
    );
  }

  return (
    <div>
      <Link
        to={"/startup-dashboard"}
        style={{ textDecoration: "none", color: "#010101", opacity: "0.8" }}
      >
        <Toolbar className="book opacity">
          BACK <ArrowForwardIosIcon sx={{ fontSize: "14px" }} />
        </Toolbar>
      </Link>

      <Box sx={{ display: "flex", pl: 3 }} className="btnBox">
        <Typography
          sx={{ fontSize: { sm: "42px", xs: "28px" }, fontWeight: "600" }}
        >
          Search Result
        </Typography>
      </Box>
      {data?.message === "404 Not Found" || data?.length === 0? (
        <Box sx={{ p: 4 }}>
          <Typography variant="h5" style={{ textAlign: "center" }}>
            {" "}
            Sorry! No result found :({" "}
          </Typography>
        </Box>
      ) : (
        <Grid container spacing={3}>
          {data.map((item: any) => (
            <Grid item lg={4} md={6} xs={12}>
              <ProfileCard element={item} key={item.id} />
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  );
}
