import { Box, Button, Grid, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// import { Link } from 'react-router-dom';
import { Link as RouterLink } from "react-router-dom";

import React from "react";

interface getStartedProps {
  bgColor?: string;
  text?: string;
  textColor?: string;
  iconColor?: string;
  linkNav: string;
}

const GetStarted: React.FC<getStartedProps> = ({
  bgColor,
  text,
  textColor,
  iconColor,
  linkNav,
}) => {
  return (
    <>
      <Grid
        my={7}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundColor: bgColor ? bgColor : "#000000",
          py: 4,
        }}
      >
        <Grid item md={8} lg={8} sm={10} xs={9}>
          <Typography
            sx={{
              fontSize: { lg: "22px", md: "18px", sm: "18px", xs: "16px" },
              mb: { sm: "15px", xs: "10px", md: "0px", lg: "0px" },
              fontWeight: "bold",
              color: textColor ? textColor : "#000000",
            }}
          >
            {text ? text : "abc"}
          </Typography>
        </Grid>
        <Grid item md={2} lg={2} sm={10} xs={9}>
          <Button
            component={RouterLink}
            to={linkNav}
            endIcon={
              <ArrowForwardIcon
                sx={{ color: iconColor ? iconColor : "#000000" }}
              />
            }
            className="get-started"
            sx={{
              borderRadius: "50px",
              px: { md: 1, lg: 3 },
              py: { md: 2 },
              mr: { md: 3 },
              backgroundColor: "#ffffff",
              "&:hover": {
                backgroundColor: "#ffffff",
              },
              color: "#162634",
              fontWeight: "bold",
            }}
          >
            <Box
              sx={{
                fontWeight: "bolder",
                letterSpacing: "0.32px",
                fontSize: { lg: "14px" ,md: "14px", xs: "12px" },
              }}
            >
              Get Started
            </Box>
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default GetStarted;
