import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import face from "./img/face.png";
import { useNavigate } from "react-router-dom";
import "./networkcard.css";
import { getProfile } from "../../../../../store/login/actions";
import { Spinner3Dots } from "../../../../../Extras/Spinner";

const NetWorkCard = ({ userId }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProfile(userId));
  }, []);

  const connectionArray = useSelector((state: any) => {
    return state.startupReducer?.connectionData;
  });

  const connectionSet = new Set();
  const uniqueConnections = connectionArray.filter((data: { uId: any }) => {
    if (connectionSet.has(data?.uId)) {
      return false;
    }
    connectionSet.add(data.uId);
    return true;
  });

  const investorData = uniqueConnections.find(
    (element: any) => element.uId === userId
  );

  const handleRedirect = (e: any) => {
    e.preventDefault();
    navigate("/chat", { state: investorData.uId });
  };

  useEffect(() => {
    if (investorData === undefined) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [investorData]);

  if (isLoading) {
    return (
      <div>
        <Spinner3Dots message="" />
      </div>
    );
  }

  return (

    <Grid
      container
      mt={2}
      p={2}
      className="bg-white"
      sx={{ boxShadow: 3, borderRadius: 5 }}
    >
      <Grid item xs={2} sm={2} md={2} xl={2} lg={2}>
        <Avatar
          src={
            investorData?.profilePicture ? investorData?.profilePicture : face
          }
          sx={{ height: "80px", width: "80px", objectFit: "cover" }}
          className="max-width img-box"
        />
      </Grid>
      <Grid
        item
        xs={7}
        sm={8}
        md={8}
        xl={8}
        lg={8}
        className="company-desc-container"
      >
        <Box sx={{ p: "10px" }}>
          <Typography className="size-s bold network-company-text">
            {investorData?.name}
          </Typography>
          <Typography
            variant="body1"
            className="size-xs book network-industry-text"
          >
            {investorData?.location}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={3} sm={2}>
        <Box
          sx={{
            display: "block",
            position: "relative",
            top: 9,
          }}
        >
          <Button
            onClick={handleRedirect}
            className="btn white bg-orange"
            fullWidth
          >
            Chat
          </Button>
          <Button className=".rmv-btn btn orange" fullWidth>
            Remove
          </Button>
        </Box>
      </Grid>
    </Grid>
      
  );
};

export default NetWorkCard;
