import { es } from "date-fns/locale";
import {
  GET_LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  GET_LOGIN_FAIL,
  FORGET_PASSWORD_INVESTOR,
  STORE_MESSAGES,
  GET_STARTUP_DETAILS_SUCCESS_FROM_INVESTOR,
  GET_ALL_STARTUPS,
} from "./actions";

const initialState: any = {
  login: [],
  error: null,
  type: "",
  isLoggedIn: false,
  token: localStorage.getItem("token"), // not in use
  topRisingData: [],
  blogs: [],
  recomendedStartups: [],
  allStartups: [],
  profile: {},
  choosenData: {},
  choosenStartupData: {},
  recentlyViewed: [],
  search: [],
  searchInv: [],
  errorEmail: {},
  messages: [],
  startupDetails: [],
  connectionData: [],
  requests: [],
  recentChatProfile: [],
  peoples: [],
};

const loginReducerHandler = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_LOGIN_SUCCESS:
      return {
        ...state,
        login: [action.payload],
        isLoggedIn: true,
        error: null,
        type: "investor",
      };
    case STORE_MESSAGES:
      return {
        ...state,
        messages: [action.payload],
      };
    case GET_STARTUP_DETAILS_SUCCESS_FROM_INVESTOR:
      return {
        ...state,
        startupDetails: [action.payload],
      };
    case GET_LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        error: action.payload?.message.toUpperCase(),
        type: "",
      };
    case LOGOUT_SUCCESS:
      localStorage.removeItem("profile");
      localStorage.removeItem("type");
      localStorage.removeItem("id");
      sessionStorage.removeItem("profile");
      sessionStorage.removeItem("type");
      sessionStorage.removeItem("id");
      return {
        ...state,
        login: [],
        isLoggedIn: false,
        type: "",
      };

    case "GOOGLE_HOMEPAGE": {
      return { ...state, topRisingData: action?.data };
    }
    case "GETBLOGS": {
      return { ...state, blogs: action?.data?.data };
    }
    case "GET_REC_STARTUP": {
      return { ...state, recomendedStartups: action?.data };
    }
    case GET_ALL_STARTUPS: {
      return { ...state, allStartups: action?.data.results };
    }

    case "GOTUSERPROFILE": {
      return { ...state, profile: action?.data[0] };
    }
    case "GOTSTARTUPPROFILE": {
      return { ...state, profile: action?.data[0] };
    }

    // Investor dashboard search --------
    case "GET_SEARCH": {
      return { ...state, search: action?.data?.data };
    }
    case "GET_SEARCH_I": {
      return { ...state, search: action?.data };
    }
    // ---------------------------------------
    case "TOP_RISING": {
      return { ...state, topRisingData: action?.data };
    }
    case "SAVE_CHOOSEN_DATA": {
      return { ...state, choosenData: action?.payload };
    }
    case "STORE_PEOPLE": {
      return { ...state, peoples: [...action?.payload] };
    }
    case "SAVE_CHOOSENSTARTUP_DATA": {
      return { ...state, choosenStartupData: action?.payload };
    }
    case FORGET_PASSWORD_INVESTOR: {
      return { ...state, errorEmail: action?.payload };
    }
    case "ALL_INVESTOR_CONNECTION": {
      return {
        ...state,
        connectionData: [...state.connectionData, { ...action?.payload }],
      };
    }
    case "RECENT_CHAT_INFO": {
      return {
        ...state,
        recentChatProfile: [...state.recentChatProfile, { ...action?.data[0] }],
      };
    }

    case "ALL_INVESTOR_REQUESTS": {
      return {
        ...state,
        requests: [...state.requests, { ...action?.payload }],
      };
    }
    case "ALL_RECENT_CHATS": {
      return {
        ...state,
        recentChats: [...action?.payload],
      };
    }
    case "RECENTLY_VIEWED": {
      let flag: any = false;
      if (state.recentlyViewed.length === 0) {
        return {
          ...state,
          recentlyViewed: [action.payload, ...state.recentlyViewed],
        };
      } else {
        for (let i: any = 0; i < state.recentlyViewed.length; i++) {
          if (state.recentlyViewed[i]._id === action.payload._id) {
            break;
          } else if (
            state.recentlyViewed[i]._id !== action.payload._id &&
            i === state.recentlyViewed.length - 1
          ) {
            flag = true;
          }
        }
        if (flag) {
          return {
            ...state,
            recentlyViewed: [action.payload, ...state.recentlyViewed],
          };
        } else {
          return { ...state, recentlyViewed: state.recentlyViewed };
        }
      }
    }
    case "UPDATED_INVESTOR_PROFILE": {
      return { ...state, login: [...state.login, ...action?.data] };
    }
    default:
      return state;
  }
};

export default loginReducerHandler;
