import {
  Avatar,
  Box,
  Divider,
  Typography,
  OutlinedInput,
  FormControl,
  List,
  Paper,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import chatGooglrIcon from "../../Assets/image/chatGooglrIcon.png";
import orangeDownArrow from "../../Assets/image/orangeDownArrow.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import closeIcon from "../../Assets/image/closeIcon.png";
import SendIcon from "@mui/icons-material/Send";
import { useDispatch, useSelector } from "react-redux";

import "./ChatComponent.css";
import {
  fetchMessages,
  sendMessages,
  getStartupDetailsFromInvestor,
  fetchRecentChats,
} from "../../store/login/actions";
import { getProfileInv } from "../../store/startup/actions";
import { ChatProfileModal } from "../Chat/ChatProfileModal";
import MessageModal from "../Chat/MessageModal";
import MessageIcon from "@mui/icons-material/Message";

interface State {
  to: any;
  from: any;
  msg: any;
}

export default function ChatButton() {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [showMessages, setShowMessages] = useState(false);
  const [childData, setChildData] = useState("");
  const [messageId, setMessageId] = useState(
    "70c64436-fa9e-4b13-9ca1-e56f42e874d0"
  );

  useEffect(() => {
    setMessageId(childData);
  }, [childData, messageId]);
  let id: any = "";
  if (localStorage.getItem("id")) id = localStorage.getItem("id");

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(fetchRecentChats(id));
    }, 1000);
    if (localStorage.getItem("type") === "investor")
      dispatch(getStartupDetailsFromInvestor(id));
    if (localStorage.getItem("type") === "startup") dispatch(getProfileInv(id));

    return () => clearInterval(interval);
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const recentChatsList: any = useSelector<any>(
    (state) => state.loginReducerHandler.recentChats
  );

  let chatsId: any = [];

  recentChatsList?.map((chats: any) => {
    if (chats?.from === id) {
      chatsId.push({ senderId: chats?.to, updatedTime: chats?.updatedAt });
    }
    if (chats.to === id) {
      chatsId?.push({ senderId: chats?.from, updatedTime: chats?.updatedAt });
    }
  });
  chatsId.reverse();

  return (
    <>
      <Box
        style={{ zIndex: "999999999999" }}
        sx={{
          position: "fixed",
          right: "24px",
          top: "50px",
          bottom: "0",
          // left: "auto",
          display: open ? "block" : "none",
        }}
        className="popOverStyling"
      >
        <Box
          className="chatList"
          sx={{ position: "fixed", right: "24px", top: "auto", bottom: "0" }}
        >
          <Box
            sx={{ display: "flex", alignItems: "center", position: "relative" }}
            className="chatBoxOpened"
          >
            <Box
              // src={chatGooglrIcon}
              style={{ marginRight: "10px", width: "30px", height: "30px" }}
              // alt="img"
            >
              <MessageIcon color="success" />
            </Box>
            <Typography sx={{ color: "#ffffff" }}>Messages</Typography>
            <img
              src={orangeDownArrow}
              style={{
                position: "absolute",
                right: "-10px",
                bottom: "0px",
                width: "60px",
                height: "60px",
                transform: "rotateX(180deg)",
              }}
              alt="img"
              onClick={handleClose}
            />
          </Box>
          <Divider color="#97989C" sx={{ borderBottomWidth: "0.5px" }} />
          <Paper
            elevation={0}
            style={{
              backgroundColor: "#152634",
              height: "auto",
              maxHeight: "74vh",
              overflow: "auto",
            }}
          >
            {chatsId.map((chats: any, index: any) => (
              <ChatProfileModal
                senderId={chats.senderId}
                time={chats.updatedTime}
                passChildData={setChildData}
                passChildFlag={setShowMessages}
              />
            ))}
            {showMessages && childData !== "" && (
              <MessageModal
                senderId={childData}
                flagFromChild={setShowMessages}
              />
            )}
          </Paper>
        </Box>
      </Box>
      <Box
        sx={{ position: "fixed", bottom: "24px", right: "24px" }}
        onClick={handleOpen}
        className={open === false ? "boxClosed" : "boxOpened"}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", padding: 1.5 }}
          className="chatBoxOpen"
        >
          <Box
            // src={chatGooglrIcon}

            style={{ marginRight: "10px" }}
            // alt="img"
          >
            <MessageIcon color="success" />{" "}
          </Box>
          <Box>
            <Typography sx={{ color: "#ffffff" }}>Messages</Typography>
            <img
              src={orangeDownArrow}
              alt="img"
              style={{
                position: "absolute",
                right: "0px",
                bottom: "-10px",
                width: "70px",
                height: "70px",
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export const InvestorNameChat = () => {
  const dispatch = useDispatch();
  const [messages, setMessages] = useState<any>([]);
  let id: any = "";
  if (localStorage.getItem("id")) id = localStorage.getItem("id");
  if (sessionStorage.getItem("id")) id = sessionStorage.getItem("id");

  let senderId: any = "";
  if (
    localStorage.getItem("type") === "investor" ||
    sessionStorage.getItem("type") === "investor"
  )
    senderId = "fdeb097e-1497-4166-be71-db2f75bfe981";
  if (
    localStorage.getItem("type") === "startup" ||
    sessionStorage.getItem("type") === "startup"
  )
    senderId = "884b7448-5789-47ae-996b-cfaa237ae333";

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(fetchMessages(values));
    }, 1000);
    if (
      localStorage.getItem("type") === "investor" ||
      sessionStorage.getItem("type") === "investor"
    )
      dispatch(getStartupDetailsFromInvestor(senderId));
    if (
      localStorage.getItem("type") === "startup" ||
      sessionStorage.getItem("type") === "startup"
    )
      dispatch(getProfileInv(senderId));

    return () => clearInterval(interval);
  }, []);

  const startupInformation: any = useSelector<any>(
    (state) => state.loginReducerHandler.startupDetails
  );

  const investorProfile: any = useSelector<any>(
    (state) => state.startupReducer.investorData
  );

  let name = "user";
  let companyName = "company";
  let location = "location";

  if (
    localStorage.getItem("type") === "investor" ||
    sessionStorage.getItem("type") === "investor"
  ) {
    name = startupInformation[0]?.companyName;
    companyName = startupInformation[0]?.companyName;
    location = startupInformation[0]?.location;
  }
  if (
    localStorage.getItem("type") === "startup" ||
    sessionStorage.getItem("type") === "startup"
  ) {
    if (investorProfile) {
      name = investorProfile[0]?.name;
      companyName = investorProfile[0]?.name;
      location = investorProfile[0]?.location;
    }
  }

  const messagesArray: any = useSelector<any>(
    (state) => state.loginReducerHandler.messages
  );

  const [values, setValues] = useState<State>({
    from: id,
    to: senderId,
    msg: "",
  });

  useEffect(() => {
    setMessages(messagesArray[0]);
  }, [messagesArray]);

  const scrollRef: any = useRef<null | HTMLDivElement>(null);
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);
  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  let instantArray = [
    {
      content: "",
      date: "xxxx",
      recieverInfo: senderId,
      senderInfo: id,
    },
  ];
  const handleSubmit = () => {
    if (values.msg !== "") {
      instantArray[0].content = values.msg;
      dispatch(sendMessages(values));
      setMessages((prevState: any) => [...prevState, instantArray[0]]);
      setValues({ ...values, msg: "" });
    }
  };
  const [visible, setVisible] = React.useState(true);
  const handleVisible = () => {
    setVisible(!visible);
  };

  const senderChatStyle = {
    margin: 10,
    padding: 10,
    textAlign: "right",
    marginRight: 0,
    paddingRight: 0,
  };
  const receiverChatStyle: any = {
    padding: 10,
    margin: 10,
    marginLeft: 0,
    paddingLeft: 0,
  };
  const senderBorderStyle = {
    borderRadius: 20,
    padding: 10,
    backgroundColor: "white",
    border: "2px solid white",
    color: "black",
  };
  const receiverBorderStyle = {
    borderRadius: 20,
    padding: 10,
    border: "2px solid #46515D",
    backgroundColor: "#46515D",
    color: "white",
  };

  return (
    <>
      <Box sx={{ pl: 1, pr: 1 }}>
        <Box
          sx={{ display: "flex", alignItems: "center" }}
          className="investor1"
          onClick={handleVisible}
        >
          <img
            src={chatGooglrIcon}
            alt="img"
            style={{ marginRight: "10px", width: "35px", height: "35px" }}
          />
          <Typography sx={{ color: "#ffffff", fontSize: "14px" }}>
            {name}
          </Typography>
          <ArrowForwardIosIcon
            style={{
              position: "absolute",
              right: "10px",
              color: "#97989C",
              fontSize: "20",
            }}
          />
        </Box>
        <Divider color="#97989C" sx={{ borderBottomWidth: "0.5px" }} />
      </Box>

      {visible && (
        <Box
          className="ChatListUser12"
          sx={{ position: "fixed", top: "100px !important" }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              position: "relative",
              p: 1,
            }}
          >
            <img
              src={chatGooglrIcon}
              alt="img"
              style={{ marginRight: "10px", width: "30px", height: "30px" }}
            />

            <Typography sx={{ color: "#ffffff" }}>{name}</Typography>
            <img
              src={closeIcon}
              alt="img"
              style={{
                position: "absolute",
                right: "0px",
                bottom: "-15px",
                width: "70px",
                height: "70px",
              }}
              onClick={() => setVisible(!visible)}
            />
          </Box>

          <Divider color="#97989C" sx={{ borderBottomWidth: "0.5px" }} />
          <Paper
            style={{
              maxHeight: 420,
              overflow: "auto",
              padding: 20,
              paddingBottom: 50,
              backgroundColor: "#162634",
            }}
          >
            <List>
              <Box className="scrollingContainer">
                {messages?.slice(1)?.map((msg: any) => (
                  <Box
                    ref={scrollRef}
                    style={
                      msg.senderInfo === id
                        ? senderChatStyle
                        : receiverChatStyle
                    }
                  >
                    <span
                      style={
                        msg.senderInfo === id
                          ? senderBorderStyle
                          : receiverBorderStyle
                      }
                    >
                      {msg?.content}
                    </span>
                  </Box>
                ))}
              </Box>
            </List>
          </Paper>
          <Box
            sx={{
              display: "flex",
              position: "absolute",
              bottom: "0px",
              alignItems: "center",
              backgroundColor: "#233748",
              width: "100%",
            }}
          >
            <FormControl sx={{ width: "100%" }}>
              <OutlinedInput
                sx={{
                  border: "1px solid #97989C",
                  margin: "15px 10px",
                  color: "#ffffff",
                  height: "2.7rem",
                }}
                onChange={handleChange("msg")}
                value={values?.msg}
                placeholder="Type Something..."
              />
            </FormControl>

            <SendIcon
              onClick={handleSubmit}
              className="btn"
              sx={{ ml: 1, mr: 1 }}
              fontSize="medium"
              color="warning"
            />
          </Box>
        </Box>
      )}
    </>
  );
};
