import { Button, Container } from "@mui/material";
// import { Box } from "@mui/system";
// import React, { useState } from "react";
import "./Pricing.css";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import correct from "./img/correct.png";
import wrong from "./img/wrong.png";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PricingStartupUpdated from "./PricingStartupUpdated";
import { Box } from "@mui/system";
import PricingInvUpdated from "./PricingInvUpdated";
import { useEffect } from "react";
import { toast, useToasterStore } from "react-hot-toast";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#EFF3F3",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(name: string, basic: any, premium: any) {
  return { name, basic, premium };
}

const rows = [
  createData(
    "Unlimited Search Results",
    <img src={correct} alt="test" />,
    <img src={correct} alt="test" />
  ),
  createData(
    "Connect to Unlimited Start ups",
    <img src={correct} alt="test" />,
    <img src={correct} alt="test" />
  ),
  createData(
    "Zero Commission on deal",
    <img src={correct} alt="test" />,
    <img src={correct} alt="test" />
  ),
  createData(
    "Chat with Unlimited Start ups",
    <img src={correct} alt="test" />,
    <img src={correct} alt="test" />
  ),
  createData(
    "Tagged as Premium",
    <img src={wrong} alt="test" />,
    <img src={correct} alt="test" />
  ),
];

export const PricingInvestor = () => {
  // document.title = "Make New India | Pricing";
  const isLoggedIn = useSelector<any>(
    (state) => state.loginReducerHandler.isLoggedIn
  );

  const TOAST_LIMIT = 1;
  const { toasts } = useToasterStore();
  useEffect(() => {
    toasts
      .filter((t: any) => t.visible) // Only consider visible toasts
      .filter((_: any, i: any) => i >= TOAST_LIMIT) // Is toast index over limit?
      .forEach((t: any) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) for no exit animation
  }, [toasts]);

  const navigate = useNavigate();
  // const handleTransfer = () =>{
  //   if(isLoggedIn == true){
  //     window.open("https://rzp.io/l/CI63OwBj")
  //   }else{
  //     navigate("/login")
  //   }
  // }
  const token = localStorage.getItem("id");

  const handleTransfer = () => {
    if (token) {
      window.open("https://rzp.io/l/CI63OwBj");
    } else {
      navigate("/login");
    }
  };
  const handleTransferPremium = () => {
    if (token) {
      window.open("https://rzp.io/l/fJ58ufr");
    } else {
      navigate("/login");
    }
  };
  return (
    <>
      {/* <Container sx={{ width: { md: "80%", sm: "94%", xs: "100%" } }}>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell
                  align="center"
                  sx={{
                    letterSpacing: "4.8px",
                    width: "40%",
                    fontSize: "24px",
                  }}
                  className="tableHeading"
                >
                  INVESTOR
                </StyledTableCell>
                <StyledTableCell className="tableHeading" align="center">
                  Basic
                </StyledTableCell>
                <StyledTableCell className="tableHeading" align="center">
                  Premium
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <StyledTableRow key={row.name}>
                  <StyledTableCell
                    className="tablecontent"
                    component="th"
                    scope="row"
                    sx={{
                      backgroundColor: index === 9 ? "#EFF3F3" : null,
                      borderBottom: index === 9 || 8 ? "none" : null,
                    }}
                  >
                    {row?.name}
                  </StyledTableCell>
                  <StyledTableCell align="center" width="15%">
                    {row?.basic}
                  </StyledTableCell>
                  <StyledTableCell align="center" width="15%">
                    {row?.premium}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              <StyledTableRow
                className="pricingInvestor-inner"
                sx={{ backgroundColor: "#EFF3F3", textAlign: "bottom" }}
              >
                <StyledTableCell align="center" width="15%">
                  <b>Price</b>
                </StyledTableCell>
                <StyledTableCell align="center" width="15%">
                  <p>
                    <b>7,999 INR / 1 mo</b>
                    <br />
                    (INR 6,999/- when billed Annually)
                    <br />
                    <br />
                    <Button
                      onClick={handleTransfer}
                      className="arrayBtn"
                    >
                      Buy Now
                    </Button>
                  </p>
                </StyledTableCell>
                <StyledTableCell align="center" width="15%">
                  <p>
                    <b>11,999 INR/1 mo</b>
                    <br />
                    (INR 9,999/- when billed Annually)
                    <br />
                    <br />
                    <Button
                      onClick={handleTransferPremium}
                      className="arrayBtn"
                    >
                      Buy Now
                    </Button>
                  </p>
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Container> */}
      <Box pt={"2rem"} pb={"2rem"}>
        <PricingInvUpdated />
      </Box>
    </>
  );
};
