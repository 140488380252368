import React, { useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Link,
  Tab,
  createTheme,
  colors,
  ThemeProvider,
  Button,
  Typography,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CompanyDetail from "./EditComponent/CompanyDetails/CompanyDetail";
import FinancialDetails from "./EditComponent/FinancialDetails/FinancialDetails";
import UploadFileComponent from "./EditComponent/UploadFileComponent/UploadFileComponent";
import EditProfileSWOT from "./EditComponent/EditProfileSWOT/EditProfileSWOT";
import EditProfilePeople from "./EditComponent/EditProfilePeople/EditProfilePeople";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateStartupProfile } from "../../store/startup/actions";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

interface State {
  companyName: any;
  askPrice: any;
  equityOffer: any;
  industry: any;
  founded: any;
  location: any;
  companyInfo: any;
}

const theme = createTheme({
  palette: {
    secondary: {
      main: colors.lightGreen[900],
    },
  },
});
const EditStartupProfile = () => {
  document.title = "Make New India | Edit Profile";
  const [value, setValue] = useState("1");
  const [selectedImage, setSelectedImage] = useState();
  const [selectedIcon, setSelectedIcon] = useState();
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const imageChange = (e: any) => {
    // if (e.target.files && e.target.files.length > 0) {
    //   setValues({ ...values, thumbnailPicture: e.target.files[0] });

    setSelectedImage(e.target.files[0]);
  };
  const iconChange = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedIcon(e.target.files[0]);
    }
  };

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const startUpData: any = useSelector<any>(
    (state) => state.startupReducer?.login[0]
  );



  const id = useSelector((state: any) => {
    return state.startupReducer.login[0]?.userId;
  });




  let values: any = {};
  // let phase: any = {};

  const dataHandler = (data: any) => {
    values = data;

  };

  const pitchHandler = (selectedImage: any) => {
    setSelectedImage(selectedImage);
  };
  // let values = {};
  const financeHandler = (data: any) => {
    values = data;
  };
  const SWOTHandler = (data: any) => {
    values = data;
  };
  const peopleHandler = (data: any) => {
    values = data;
  };

  // const handleSubmit = () => {
  //   let obj: any = {};
  //   for (let [key, value] of Object.entries(values)) {
  //     if (value === "") {
  //       continue;
  //     } else {
  //       obj[key] = value;
  //     }
  //   }
  //   dispatch(updateStartupProfile(obj, id));
  //   // window.location.reload();
  //   navigate("/startup-dashboard");
  // };

  const url = "https://makenewindia.com/api";
  

  const handleSubmit = () => {

    const formData: any = new FormData();
    let key: keyof typeof values;
    // let phase: keyof typeof phase;

    for (key in values) {
      formData.append(key, values[key]);
    }
    formData.append("pitch", selectedImage);
    // formData.append("phase", phase);

    axios
      .put(`${url}/editStartupProfile/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          // "Content-Length": "<calculated when request is sent>",
        },
      })
      .then((res) => {
        setTimeout(() => {
          profileUpdated();
          navigate("/startup-dashboard");
        }, 1000);
       
        return res.data;
      })
      .catch((err: any) => {
     
        toast.error(err?.response?.data?.message)
        return err;
      });
    // setTimeout(() => {
    //   profileUpdated();
    //   navigate("/startup-dashboard");
    // }, 1000);
  };

  const profileUpdated = (toastHandler = toast) => {
    toastHandler.success("Profile Updated Successfully!", {
      style: {
        background: "#04111d",
        color: "#fff",
      },
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Toaster position="top-center" reverseOrder={false} />
      <Box sx={{ px: { xs: 0, sm: 4 } }} mt={4}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link href="/home" color="black" variant="subtitle2" underline="none">
            HOME
          </Link>
          <Link href="/home" color="black" variant="subtitle2" underline="none">
            LOGIN
          </Link>
          <Link href="/home" color="black" variant="subtitle2" underline="none">
            STARTUP
          </Link>
        </Breadcrumbs>
        <TabContext value={value}>
          <TabList
            aria-label="tab"
            onChange={handleChange}
            TabIndicatorProps={{ style: { background: "#138707" } }}
            textColor="secondary"
            centered
          >
            <Typography
              sx={{
                fontSize: "42px",
                fontFamily: "light",
                marginRight: "8%",
                paddingTop: "10px",
              }}
            >
              Edit Profile
            </Typography>

            <Tab
              label="Pitch"
              value="1"
              sx={{ marginRight: "4%", marginTop: "-10px" }}
            />
            <Tab
              label="Company Details"
              value="2"
              sx={{ marginRight: "4%", marginTop: "-10px" }}
            />
            <Tab
              label="Financial"
              value="3"
              sx={{ marginRight: "4%", marginTop: "-10px" }}
            />
            <Tab
              label="SWOT"
              value="4"
              sx={{ marginRight: "4%", marginTop: "-10px" }}
            />
            <Tab
              label="People"
              value="5"
              sx={{ marginRight: "4%", marginTop: "-10px" }}
            />
            <Button
              variant="contained"
              color="secondary"
              sx={{
                minWidth: "212px",
                backgroundColor: "#138707",
                fontSize: "16px",
                fontFamily: "bold",
                height: "61px",
                borderRadius: "0px",
              }}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </TabList>

          <Box
            bgcolor="#EFF3F3"
            height="100%"
            sx={{
              mx: { md: 7 },
            }}
            mt={5}
          >
            <TabPanel value="1">
              <UploadFileComponent
                startUpData={startUpData}
                id={id}
                receiveData={pitchHandler}
              />
            </TabPanel>
            <TabPanel value="2">
              <CompanyDetail
                startUpData={startUpData}
                id={id}
                receiveData={dataHandler}
                // receivePhase={getPhase}
              />
            </TabPanel>
            <TabPanel value="3">
              <FinancialDetails
                startUpData={startUpData}
                id={id}
                receiveData={financeHandler}
              />
            </TabPanel>
            <TabPanel value="4">
              <EditProfileSWOT
                startUpData={startUpData}
                id={id}
                receiveData={SWOTHandler}
              />
            </TabPanel>
            <TabPanel value="5">
              <EditProfilePeople
                startUpData={startUpData}
                id={id}
                receiveData={peopleHandler}
              />
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </ThemeProvider>
  );
};

export default EditStartupProfile;
