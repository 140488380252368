import {
  Typography,
  InputBase,
  Button,
  InputAdornment,
  IconButton,
  FormHelperText,
} from "@mui/material";
import React, { useState } from "react";
import "./InvestorForgotPassword.css";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import bg from "../InvestorLogin/img/welcomeInvestor.png";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link, useNavigate } from "react-router-dom";
import { forgetPasswordInvestor } from "../../store/login/actions";
import { useDispatch, useSelector } from "react-redux";
import Body from "../StartupLogin/StartUpForgotPassword/Body";
import axios from "../../utils/axios";
import { toast } from "react-hot-toast";

interface State {
  email: string;
}
const InvestorForgotPassword = () => {
  const [next, setNext] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [mailSent, setMailSent] = useState(false);
  const [mailSentMessage, setMailSentMessage] = useState(false);
  const [values, setValues] = React.useState<State>({
    email: "",
  });
  const [errors, setErrors] = useState({
    email: "",
  });
  const errorMsg: any = useSelector<any>(
    (state) => state.loginReducerHandler.errorEmail?.response?.data
  );

  

  const emailValidation = (values: any) => {
    if (
      values.email === "" ||
      !values.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid Email",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
    }
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isFocused, setIsFocused] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    emailValidation(values);
    setIsFocused(true);
    // if (errors.email === "") {
    //   dispatch(forgetPasswordInvestor(values));
    // }
    const data = {
      email : values
    }
    axios
    .post(`https://makenewindia.com/api/forgot-password`, 
      values
    )
    .then((res) => {
      setMailSent(true);
      setMailSentMessage(res.data);
      return res.data;
    })
    .catch((err: any) => {
      toast.error(err?.response?.data?.msg)
      return err;
    });
    
  };

  const content = !next ? (
    <>
      <Typography variant="h2" className="light orange forgot-pw-heading">
        Forgot Password
      </Typography>
      <Typography
        variant="subtitle1"
        gutterBottom
        component="div"
        className="book start-up-forgot-pw-text"
      >
        We'll send you a link to reset your password
      </Typography>
      <InputBase
        onChange={handleChange("email")}
        placeholder="Email Address"
        className="start-up-forgot-pw-input bg-white"
        fullWidth
      />
      {mailSent && (
        <FormHelperText sx={{ color: "red" }}>
          {mailSentMessage}
        </FormHelperText>
      )}
      {errors["email"] && isFocused && (
        <FormHelperText sx={{ color: "red" }}>
          {errors?.email.toUpperCase()}
        </FormHelperText>
      )}
      {errors.email === "" && (
        <FormHelperText sx={{ color: "red" }}>
          {errorMsg?.message.toUpperCase()}
        </FormHelperText>
      )}
      <Button
        className="btn bg-green white start-up-forgot-pw-btn"
        fullWidth
        endIcon={
          <ArrowRightAltIcon
            className="start-up-forgot-pw-arrow-icon"
            fontSize="large"
          />
        }
        // onClick={() => {
        // dispatch(forgetPasswordInvestor(values))
        // navigate("/login")
        // }}
        onClick={handleSubmit}
      >
        Send Link
      </Button>{" "}
    </>
  ) : (
    <>
      <Typography
        variant="h2"
        className="light orange start-up-forgot-pw-heading"
      >
        New Password
      </Typography>
      <Typography variant="subtitle1" className="book new-pw-text">
        Enter your new password
      </Typography>

      <InputBase
        placeholder="New Password"
        className=" bg-white new-pw-input"
        fullWidth
        type={showPassword ? "text" : "password"}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
      <InputBase
        placeholder="Confirm Password"
        className="new-pw-input bg-white "
        fullWidth
        type={showPassword ? "text" : "password"}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
      <Button
        className="btn bg-green white start-up-forgot-pw-btn"
        fullWidth
        endIcon={
          <CheckOutlinedIcon
            className="start-up-forgot-pw-arrow-icon"
            fontSize="small"
          />
        }
        component={Link}
        to="/startup-login"
      >
        Confirm
      </Button>
    </>
  );
  return <Body image={bg} content={content} />;
};

export default InvestorForgotPassword;
