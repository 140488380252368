import { Box, Breadcrumbs, Grid, Link, Stack, Typography } from "@mui/material";
import "./networklist.css";
import NetWorkCard from "../NetWorkCard";
import FormButton from "../../../../utils/buttons/SuccessButton";
import PeopleIcon from "@mui/icons-material/People";
import { Link as Links } from "react-router-dom";
import { useSelector } from "react-redux";

const NetWorkList = () => {
  const userNetworkList = useSelector((state: any) => {
    return state.startupReducer.login[0]?.connections;
  });



  return (
    <>
      <Box className="main-container bg-grey box2">
        {/* <Grid item xs={12} sm={3} md={6} lg={6}>
          <FormButton
            className="req-btn1 bold"
            label="Request List"
            startIcon={<PeopleIcon className="opacity" fontSize="large" />}
            component={Links}
            to="/request-list-startup"
          />
        </Grid> */}
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 4, md: 12 }}
          mt={3}
        >
          {userNetworkList.map((userId: any, index: any) => (
            <Grid item xs={6} key={index}>
              <NetWorkCard userId={userId} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default NetWorkList;
