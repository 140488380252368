import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import NotificationList from "../NotificationList";
import data from "../NotificationListArray";
import { useSelector } from "react-redux";
import "./NotifictionCard.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

export default function NotificationCard({ stateChange }: any) {
  const [isShown, setIsShown] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const InvestorNotification = useSelector((state: any) => {
    return state?.startupReducer.investorNotification;
  });

  const StartUpNotification = useSelector((state: any) => {
    return state?.startupReducer.startupNotification;
  });

  // const handleRedirect:any = () => {
  //   dispatch(updateNetworkRedirect("1"))
  // }

  return (
    <div
      style={{ position: "absolute", zIndex: "1200" }}
      className="notificationRoot"
      onMouseLeave={() => stateChange(false)}
    >
      <Box
        sx={{
          padding: "20px 5px",
          width: "350px",
          border: "1px solid #dddddd",
          backgroundColor: "#ffffff",
          boxShadow: "0px 1px 4px #dddddd",
          borderRadius: "6px",
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "bold",
            color: "#dddddd",
            marginLeft: 2,
          }}
        >
          New Notifications {""}
          {InvestorNotification?.length > 0 ? InvestorNotification?.length : ""}
          {StartUpNotification?.length > 0 ? StartUpNotification?.length : ""}
        </Typography>
        {InvestorNotification?.length > 0 && (
          <>
            {InvestorNotification?.map((item: any, index: any) => (
              <Link to={"/request-list"} style={{ textDecoration: "none" }}>
                <NotificationList TopInvestorsListprops={item} key={index} />
              </Link>
            ))}
          </>
        )}
        {StartUpNotification?.length > 0 && (
          <>
            {StartUpNotification?.map((item: any, index: any) => (
              <NotificationList TopInvestorsListprops={item} key={index} />
            ))}
          </>
        )}
      </Box>
    </div>
  );
}
