import { useState } from "react";
import { Avatar, Box, Divider, Grid, Link, Typography } from "@mui/material";
import "./sidebar.css";
import profileJim from "../../../../Assets/image/googleLogo.jpg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useDispatch, useSelector } from "react-redux";
import {
  getInvestorProfile,
  getProfileInv,
  saveRecentlyViewed,
} from "../../../../store/startup/actions";
import { useNavigate } from "react-router-dom";

type myProps = {
  className?: string;
};

const SideBar = ({ className }: myProps) => {
  let data = useSelector((state: any) => {
    return state.startupReducer?.investorProfile;
  });

  const topRising = useSelector((state: any) => {
    return state.startupReducer?.topRisingDataS;
  });

  const compdata2 = useSelector((state: any) => {
    return state.startupReducer?.recentlyViewed;
  });

  const [recommendedLength, setRecommendedLength] = useState(4);
  const [topStartupLength, setTopStartupLength] = useState(4);
  const [recentlyLength, setRecentlyLength] = useState(4);
  const [showTextRecommended, setShowTextRecommended] = useState("Show More");
  const [showTextTopStartup, setShowTextTopStartup] = useState("Show More");
  const [showTextRecently, setShowTextRecently] = useState("Show More");

  // const handleShowRecommended = () => {
  //   if (recommendedLength === 4) {
  //     setRecommendedLength(8);
  //     setShowTextRecommended("Show Less");
  //   } else {
  //     setRecommendedLength(4);
  //     setShowTextRecommended("Show More");
  //   }
  // };
  // const handleShowTopStartup = () => {
  //   if (topStartupLength === 4) {
  //     setTopStartupLength(8);
  //     setShowTextTopStartup("Show Less");
  //   } else {
  //     setTopStartupLength(4);
  //     setShowTextTopStartup("Show More");
  //   }
  // };
  // const handleShowRecently = () => {
  //   if (recentlyLength === 4) {
  //     setRecentlyLength(8);
  //     setShowTextRecently("Show Less");
  //   } else {
  //     setRecentlyLength(4);
  //     setShowTextRecently("Show More");
  //   }
  // };
  // const handelRoute = (e: any) => {
  //   e.preventDefault();
  //   dispatch(saveRecentlyViewed(element));
  //   dispatch(getProfileInv(element));
  //   navigate("/user-profile");
  // };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <Box className={`sidebar-main-container  ${className}`}>
      <Box mt={1}>
        <Typography variant="h4" className="bold size-m">
          Recommended Investor
        </Typography>
        {data.slice(0, recommendedLength).map((element: any) => (
          <>
            <Grid
              container
              mt={2}
              sx={{ cursor: "pointer" }}
              onClick={() => {
                dispatch(getProfileInv(element));
                dispatch(saveRecentlyViewed(element));
                navigate("/user-profile");
              }}
            >
              <Grid item xs={3} sm={2} md={3} lg={2}>
                <Avatar
                  // component="img"
                  src={
                    element?.profilePicture
                      ? element?.profilePicture
                      : profileJim
                  }
                  className="google-img"
                />
              </Grid>
              <Grid item xs={9} sm={9} md={9} lg={10}>
                <Typography className="size-s bold">{element.name}</Typography>
                <Typography variant="body1" className="size-xs book ">
                  {/* Location */}
                  {/* {element.?location ? element.location=""} */}
                  {element?.location}
                </Typography>
              </Grid>
            </Grid>
            <Divider />{" "}
          </>
        ))}
        <Box
          onClick={() => navigate("/view-recommendation")}
          style={{ cursor: "pointer" }}
          display="flex"
          justifyContent="center"
          mt={2}
        >
          <Link
            underline="hover"
            // href="/company-profile"
            color="green"
            className="bold size-s"
          >
            {showTextRecommended}
          </Link>
          {recommendedLength === 4 ? (
            <KeyboardArrowDownIcon className="green" fontSize="medium" />
          ) : (
            <KeyboardArrowUpIcon className="green" fontSize="medium" />
          )}
        </Box>
      </Box>

      <Box mt={1}>
        <Typography variant="h4" className="bold size-m">
          Top Investor
        </Typography>
        {topRising?.slice(0, topStartupLength).map((element: any) => (
          <>
            <Grid
              container
              mt={2}
              sx={{ cursor: "pointer" }}
              onClick={() => {
                dispatch(getProfileInv(element));
                dispatch(saveRecentlyViewed(element));
                navigate("/user-profile");
              }}
            >
              <Grid item xs={3} sm={2} md={3} lg={2}>
                <Avatar
                  // component="img"
                  src={
                    element?.profilePicture
                      ? element?.profilePicture
                      : profileJim
                  }
                  className="google-img"
                />
              </Grid>
              <Grid item xs={9} sm={9} md={9} lg={10}>
                <Typography className="size-s bold">{element.name}</Typography>
                <Typography variant="body1" className="size-xs book ">
                  {/* Location */}
                  {/* {element.?location ? element.location=""} */}
                  {element?.location}
                </Typography>
              </Grid>
            </Grid>
            <Divider />{" "}
          </>
        ))}
        <Box
          onClick={() => navigate("/view-top-rising")}
          style={{ cursor: "pointer" }}
          display="flex"
          justifyContent="center"
          mt={2}
        >
          <Link
            underline="hover"
            // href="/company-profile"
            color="green"
            className="bold size-s"
          >
            {showTextTopStartup}
          </Link>
          {topStartupLength === 4 ? (
            <KeyboardArrowDownIcon className="green" fontSize="medium" />
          ) : (
            <KeyboardArrowUpIcon className="green" fontSize="medium" />
          )}
        </Box>
      </Box>

      {compdata2?.length !== 0 && (
        <Box mt={1}>
          <Typography variant="h4" className="bold size-m">
            Recently View Investor
          </Typography>
          {compdata2?.slice(0, recentlyLength).map((element: any) => (
            <>
              <Grid
                container
                mt={2}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  dispatch(getProfileInv(element));
                  dispatch(saveRecentlyViewed(element));
                  navigate("/user-profile");
                }}
              >
                <Grid item xs={3} sm={2} md={3} lg={2}>
                  <Avatar
                    // component="img"
                    src={
                      element?.profilePicture
                        ? element?.profilePicture
                        : profileJim
                    }
                    className="google-img"
                  />
                </Grid>
                <Grid item xs={9} sm={9} md={9} lg={10}>
                  <Typography className="size-s bold">
                    {element?.name}
                  </Typography>
                  <Typography variant="body1" className="size-xs book ">
                    {/* Location */}
                    {/* {element.?location ? element.location=""} */}
                    {element?.location}
                  </Typography>
                </Grid>
              </Grid>
              <Divider />{" "}
            </>
          ))}
          <Box
            onClick={() => navigate("/view-recently-viewed")}
            style={{ cursor: "pointer" }}
            display="flex"
            justifyContent="center"
            mt={2}
          >
            <Link
              underline="hover"
              // href="/company-profile"
              color="green"
              className="bold size-s"
            >
              {showTextRecently}
            </Link>
            {recentlyLength === 4 ? (
              <KeyboardArrowDownIcon className="green" fontSize="medium" />
            ) : (
              <KeyboardArrowUpIcon className="green" fontSize="medium" />
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SideBar;
