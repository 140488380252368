import { useEffect, useState } from "react";
import { Avatar, Box, Divider, Grid, Link, Typography } from "@mui/material";
import "./sidebar.css";
// import google from "./img/google.png";
import google from "../../../../Assets/image/googleLogo.jpg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useDispatch, useSelector } from "react-redux";
import {
  topRisingStartup,
  getRecomendedStartups,
  saveRecentlyViewed,
  saveChoosenData,
  getPeopleOfStartup,
} from "../../../../store/login/actions";
import { useNavigate } from "react-router-dom";

type myProps = {
  className?: string;
 };

const SideBar = ({ className }: myProps) => {
  const dispatch = useDispatch();

  // const details = useSelector((state: any) => {
  //   return state.loginReducerHandler.choosenData;
  // });
  
  

  const items = [
    {
      heading: "Recommended Start Up",
      fields: [
        {
          title: "Google",
          desc: "Equity Offer <b> 20.66%</b> Ask price <b>10 L</b>",
        },
      ],
    },
    {
      heading: "Top Start Up",
      fields: [
        {
          title: "Google",
          desc: "Equity Offer <b> 20.66%</b> Ask price <b>10 L</b>",
        },
      ],
    },
    {
      heading: "Recently Viewed Start Up",
      fields: [
        {
          title: "Google",
          desc: "Equity Offer <b> 20.66%</b> Ask price <b>10 L</b>",
        },
      ],
    },
  ];

  let data = useSelector((state: any) => {
    return state.loginReducerHandler;
  });

  let data2 = useSelector((state: any) => {
    return state.loginReducerHandler.recomendedStartups;
  });

  const compdata2 = useSelector((state: any) => {
    return state.startupReducer.recentlyViewed;
  });

  const compdata = useSelector((state: any) => {
    return state.loginReducerHandler.topRisingData;
  });

  if (data.recomendedStartups.length === 0 || data.topRisingData.length === 0) {
    dispatch(topRisingStartup());
    dispatch(getRecomendedStartups());
  }

  const [recommendedLength, setRecommendedLength] = useState(4);
  const [topStartupLength, setTopStartupLength] = useState(4);
  const [recentlyLength, setRecentlyLength] = useState(4);
  const [showTextRecommended, setShowTextRecommended] = useState("Show More");
  const [showTextTopStartup, setShowTextTopStartup] = useState("Show More");
  const [showTextRecently, setShowTextRecently] = useState("Show More");

  // const handleShowRecommended = () => {
  //   if (recommendedLength === 4) {
  //     setRecommendedLength(8);
  //     setShowTextRecommended("Show Less");
  //   } else {
  //     setRecommendedLength(4);
  //     setShowTextRecommended("Show More");
  //   }
  // };
  // const handleShowTopStartup = () => {
  //   if (topStartupLength === 4) {
  //     setTopStartupLength(8);
  //     setShowTextTopStartup("Show Less");
  //   } else {
  //     setTopStartupLength(4);
  //     setShowTextTopStartup("Show More");
  //   }
  // };
  // const handleShowRecently = () => {
  //   if (recentlyLength === 4) {
  //     setRecentlyLength(8);
  //     setShowTextRecently("Show Less");
  //   } else {
  //     setRecentlyLength(4);
  //     setShowTextRecently("Show More");
  //   }
  // };
  const navigate = useNavigate();

  return (
    <Box className={`sidebar-main-container  ${className}`}>
      {/* {items.map((item, index) => {
        
      })} */}
      {items.map((element: any) => {
        // Recomended start up
        if (element.heading === "Recommended Start Up") {
          return (
            <Box>
              <Typography variant="h4" className="bold size-m">
                {element.heading}
              </Typography>
              {data.recomendedStartups
                .slice(0, recommendedLength)
                .map((ele: any) => {
                  return (
                    <Box
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        dispatch(saveRecentlyViewed(ele));
                        dispatch(saveChoosenData(ele));
                        dispatch(getPeopleOfStartup(ele?.userId));
                        navigate("/company-profile");
                      }}
                      mt={1}
                    >
                      <>
                        <Grid container mt={2}>
                          <Grid
                            item
                            display="flex"
                            alignItems="center"
                            xs={3}
                            sm={2}
                            md={3}
                            lg={2}
                          >
                            <Avatar
                              // component="img"
                              src={
                                ele?.profilePicture
                                  ? ele?.profilePicture
                                  : google
                              }
                              className="google-img"
                            />
                          </Grid>
                          <Grid item xs={9} sm={9} md={9} lg={10}>
                            <Typography className="size-s bold">
                              {ele.companyName}
                            </Typography>

                            <Grid item>
                              <Typography
                                variant="body1"
                                className="size-xs book "
                                // variant="caption"
                              >
                                Equity Offer{" "}
                                <b>
                                  {" "}
                                  {ele?.equityOffer ? ele.equityOffer : "..."}%
                                </b>
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                variant="body1"
                                className="size-xs book "
                                // variant="caption"
                              >
                                Ask price{" "}
                                <b>
                                  ₹
                                  {ele?.askPrice >= 10000000
                                    ? (ele?.askPrice / 10000000).toFixed(2) +
                                      " Cr"
                                    : ele?.askPrice >= 100000
                                    ? (ele?.askPrice / 100000).toFixed(2) + " L"
                                    : ele?.askPrice}
                                </b>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Divider />{" "}
                      </>
                    </Box>
                  );
                })}
              <Box
                onClick={() => navigate("/view-recommedned-investor")}
                style={{ cursor: "pointer" }}
                display="flex"
                justifyContent="center"
                mt={2}
              >
                <Link
                  underline="hover"
                  // href="/company-profile"
                  color="green"
                  className="bold size-s"
                >
                  {showTextRecommended}
                </Link>
                {recommendedLength === 4 ? (
                  <KeyboardArrowDownIcon className="green" fontSize="medium" />
                ) : (
                  <KeyboardArrowUpIcon className="green" fontSize="medium" />
                )}
              </Box>
            </Box>
          );
        }
        // top start ups
        else if (element.heading === "Top Start Up") {
          return (
            <>
              <Typography variant="h4" className="bold size-m">
                {element.heading}
              </Typography>
              {data.topRisingData.slice(0, topStartupLength).map((ele: any) => {
                return (
                  <Box
                    sx={{ cursor: "pointer" }}
                    mt={1}
                    onClick={() => {
                      dispatch(saveRecentlyViewed(ele));
                      dispatch(saveChoosenData(ele));
                      dispatch(getPeopleOfStartup(ele?.userId));
                      navigate("/company-profile");
                    }}
                  >
                    <>
                      <Grid container mt={2}>
                        <Grid
                          item
                          display="flex"
                          alignItems="center"
                          xs={3}
                          sm={2}
                          md={3}
                          lg={2}
                        >
                          <Avatar
                            // component="img"
                            src={
                              ele?.profilePicture ? ele?.profilePicture : google
                            }
                            className="google-img"
                          />
                        </Grid>
                        <Grid item xs={9} sm={9} md={9} lg={10}>
                          <Typography className="size-s bold">
                            {ele.companyName}
                          </Typography>
                          <Grid item>
                            <Typography
                              variant="body1"
                              className="size-xs book "
                            >
                              Equity Offer{" "}
                              <b>
                                {" "}
                                {ele?.equityOffer ? ele.equityOffer : "..."}%
                              </b>{" "}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="body1"
                              className="size-xs book "
                            >
                              Ask price{" "}
                              <b>
                                ₹
                                {ele?.askPrice >= 10000000
                                  ? (ele?.askPrice / 10000000).toFixed(2) +
                                    " Cr"
                                  : ele?.askPrice >= 100000
                                  ? (ele?.askPrice / 100000).toFixed(2) + " L"
                                  : ele?.askPrice}
                              </b>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Divider />{" "}
                    </>
                  </Box>
                );
              })}
              <Box
                onClick={() => navigate("/view-toprising-investor")}
                display="flex"
                justifyContent="center"
                mt={2}
                style={{ cursor: "pointer" }}
              >
                <Link
                  underline="hover"
                  // href="/company-profile"
                  color="green"
                  className="bold size-s"
                >
                  {showTextTopStartup}
                </Link>
                {topStartupLength === 4 ? (
                  <KeyboardArrowDownIcon className="green" fontSize="medium" />
                ) : (
                  <KeyboardArrowUpIcon className="green" fontSize="medium" />
                )}
              </Box>
            </>
          );
        } else if (
          element.heading === "Recently Viewed Start Up" &&
          compdata2.length !== 0
        ) {
          return (
            <>
              <Typography variant="h4" className="bold size-m">
                {element.heading}
              </Typography>
              {compdata2.slice(0, recentlyLength).map((element: any) => (
                <>
                  <Grid
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      dispatch(saveRecentlyViewed(element));
                      dispatch(saveChoosenData(element));
                      dispatch(getPeopleOfStartup(element?.userId));
                      navigate("/company-profile");
                    }}
                    container
                    mt={2}
                  >
                    <Grid
                      item
                      display="flex"
                      alignItems="center"
                      xs={3}
                      sm={2}
                      md={3}
                      lg={2}
                    >
                      <Avatar
                        // component="img"
                        src={
                          element?.profilePicture
                            ? element?.profilePicture
                            : google
                        }
                        className="google-img"
                      />
                    </Grid>
                    <Grid item xs={9} sm={9} md={9} lg={10}>
                      <Typography className="size-s bold">
                        {element.companyName}
                      </Typography>
                      <Grid item>
                        <Typography variant="body1" className="size-xs book ">
                          Equity Offer{" "}
                          <b>
                            {" "}
                            {element?.equityOffer ? element.equityOffer : "..."}
                            %
                          </b>{" "}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1" className="size-xs book ">
                          Ask price{" "}
                          <b>
                            ₹
                            {element?.askPrice >= 10000000
                              ? (element?.askPrice / 10000000).toFixed(2) +
                                " Cr"
                              : element?.askPrice >= 100000
                              ? (element?.askPrice / 100000).toFixed(2) + " L"
                              : element?.askPrice}
                          </b>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />{" "}
                </>
              ))}
              <Box
                onClick={() => navigate("/view-recently-investor")}
                display="flex"
                justifyContent="center"
                mt={2}
                style={{ cursor: "pointer" }}
              >
                <Link underline="hover" color="green" className="bold size-s">
                  {showTextRecently}
                </Link>
                {recentlyLength === 4 ? (
                  <KeyboardArrowDownIcon className="green" fontSize="medium" />
                ) : (
                  <KeyboardArrowUpIcon className="green" fontSize="medium" />
                )}
              </Box>
            </>
          );
        }
        // have to implement recomended startup
      })}
    </Box>
  );
};

export default SideBar;
