import { Box, Breadcrumbs, Grid, Link, Stack, Typography } from "@mui/material";
import FormButton from "../../../utils/buttons/SuccessButton";

import PeopleIcon from "@mui/icons-material/People";
import NetWorkCard from "../NetWorkCard";
import RequestCard from "../RequestCard";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const RequestList = () => {
  document.title = "Make New India | Request List";
  const navigate = useNavigate();
  const requestListArray: any = useSelector<any>(
    (state) => state.loginReducerHandler.login[0]?.connectionRequest
  );

  const requestSet = new Set();
  const uniqueRequests = requestListArray?.filter(
    (data: { requestComingFrom: any }) => {
      if (requestSet.has(data?.requestComingFrom)) {
        return false;
      }
      requestSet.add(data?.requestComingFrom);
      return true;
    }
  );

  const pendingRequests: any = [];
  uniqueRequests?.map((request: any) => {
    if (request.status === "pending") {
      pendingRequests.push(request);
    }
  });

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="#010101"
      fontSize="14px"
      href="/dashboard"
      sx={{ opacity: "66%" }}
    >
      HOME
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="#010101"
      fontSize="14px"
      href="/login"
      sx={{ opacity: "66%" }}
    >
      LOGIN
    </Link>,
  ];

  const refreshHandler = () => {
    navigate("/dashboard");
  };
  const subscription = useSelector((state: any) => {
    return state.loginReducerHandler?.login[0];
  });
  const investorSubscription = subscription?.subscription;

  const userNetworkList = useSelector((state: any) => {
    return state.loginReducerHandler.login[0]?.connections;
  });
  return (
    <>
      <Box className="main-container bg-grey box2">
        <span style={{ float: "right" }}>
          <button onClick={refreshHandler}>
            <Typography>
              Refresh
              <span
                style={{
                  position: "relative",
                  top: "3px",
                  marginLeft: 10,
                }}
              >
                <img
                  width="20px"
                  src="https://upload.wikimedia.org/wikipedia/commons/b/bc/Refresh_icon.png"
                  alt="refresh icon"
                />
              </span>
            </Typography>
          </button>
        </span>
        <Stack spacing={2} className="top-bar bg-grey">
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </Stack>

        <Grid container>
          <Grid item xs={12} sm={8} md={6} lg={6}>
            <Typography className="size-sm light my-network-text weight">
              Request List
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 4, md: 12 }}
          mt={3}
        >
          {pendingRequests?.map((request: any, index: any) => (
            <Grid item xs={6} key={index}>
              <RequestCard
                startupId={request?.requestComingFrom}
                note={request?.note}
                userNetworkList = {userNetworkList}
                investorSubscription = {investorSubscription}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default RequestList;
